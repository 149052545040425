import React, { useEffect, useState } from 'react';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import { useNavigate, useLocation } from "react-router-dom";
import Globals from '../../config/globals';
import QueryBuilderJSS from './QueryBuilderJSS';
import QueryBuilderJST from './QueryBuilderJST';

export default function QueryBuilder(props) {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const [state, setState] = useState({
        
    });

    useEffect(() => {

    }, [props]);

    return (
        <div className="row">
            <div className="col-lg-12">
                {userInfo.jobShopDivision === 'JSS' ? <QueryBuilderJSS /> : <QueryBuilderJST />}

            </div>
        </div>
    );
}