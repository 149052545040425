import { atom } from 'recoil';

export const persistentNotes = atom({
    key: 'persistentNotes', // unique ID (with respect to other atoms/selectors)
    default: [], // default value (aka initial value)
});

export const persistentFilteredNotes = atom({
    key: 'persistentFilteredNotes', // unique ID (with respect to other atoms/selectors)
    default: [], // default value (aka initial value)
});

export const notesFilterType = atom({
    key: "notesFilterType",
    default: "allDivisionNotesn",
});