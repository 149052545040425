import React, { useEffect, useState } from 'react';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import { useNavigate, useLocation } from "react-router-dom";
import { Empty, Spin, Alert, Select, Table } from 'antd';
import { useForm, Controller } from "react-hook-form";

export default function WidgetMailCodeCounts(props) {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const [state, setState] = useState({
        showList: [],
        shows: [],
        mailcds: [],
        priorshowcode: "",
        priorshowmailcodecounts: "",
        kvp_priorshowmailcodecounts: {},
        totpriorshowmailcodes: 0,
        selectedShowCode: "",
        selectedshowname: "",
        priorshowname: "",
        hidepriorshowcolumns: true,
        dataLoaded: false
    });

    const { register, getValues, setValue, control, watch } = useForm();

    const { Option } = Select;

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);

    useEffect(() => {

    }, []);

    useEffect(() => {
        Axios.get(`/api/GetCurrentSeasonShowList/`
        ).then(response => {
            console.log(response.data);

            setValue('showList', response.data);

            //this selects the next upcoming show
            var dCurrentDate = new Date();
            for (let i = 0; i < getValues().showList.length; i++) {
                var dShowDate = new Date(getValues().showList[i].startdate);

                if (dShowDate.getTime() < dCurrentDate.getTime()) {

                    setValue('selectedShowCode', getValues().showList[i].showcode);
                    setValue('selectedShowName', getValues().showList[i].show_abbrev);
                    setValue('registrationClosed', getValues().showList[i].registrationclosed);
                    setValue('priorShowCode', getValues().showList[i].priorshowcode);
                    setValue('priorShowName', getValues().showList[i].priorshowabbrev);
                    setValue('priorshowregistrationClosed', getValues().showList[i].priorshowregistrationclosed);
                    if (getValues().showList[i].priorshowcode === null) {
                        setValue('hidepriorshowcolumns', true);
                    }
                    else {
                        setValue('hidepriorshowcolumns', false);
                    }

                    setState({ ...state });
                    break;
                }
            }

            setState({
                ...state
            });

            getMailCodeCountsByShow(getValues().selectedShowCode, getValues().priorShowCode, getValues().registrationClosed, getValues().priorshowregistrationClosed);

        }).catch(error => {
            console.log(error);
        });
    }, [props]);

    function getMailCodeCountsByShow(showcode, priorshowcode, registrationclosed, priorshowregistrationclosed) {
        let postdata = {};

        postdata.showcode = showcode;
        postdata.priorshowcode = priorshowcode;
        postdata.registrationclosed = registrationclosed;
        postdata.priorshowregistrationclosed = priorshowregistrationclosed;

        console.log(postdata);

        Axios.post(`/api/GetMailCodeCountsByShow`, postdata
        ).then(response => {
            const mailcds = response.data;

            console.log(mailcds);

            setState({
                ...state,
                mailcds,
                dataLoaded: true
            });

        }).catch(error => {
            console.log(error);
        });
    }

    function onShowChange(value) {
        for (let i = 0; i < getValues().showList.length; i++) {
            if (getValues().showList[i].showcode === value) {
                setValue('selectedShowCode', getValues().showList[i].showcode);
                setValue('selectedShowName', getValues().showList[i].show_abbrev);
                setValue('registrationClosed', getValues().showList[i].registrationclosed);
                setValue('priorShowCode', getValues().showList[i].priorshowcode);
                setValue('priorShowName', getValues().showList[i].priorshowabbrev);
                setValue('priorshowregistrationClosed', getValues().showList[i].priorshowregistrationclosed);
                
                if (getValues().showList[i].priorshowcode === null) {
                    setValue('hidepriorshowcolumns', true);
                }
                else {
                    setValue('hidepriorshowcolumns', false);
                }

                setState({ ...state });
                break;
            }
        }

        getMailCodeCountsByShow(getValues().selectedShowCode, getValues().priorShowCode, getValues().registrationClosed, getValues().priorshowregistrationClosed);
    }

    function renderPercentage(ShowCount, ShowTotal) {
        if (ShowCount !== null && ShowTotal !== null) {
            return round((ShowCount / ShowTotal) * 100, 1).toString() + '%';
        }
        else {
            return '';
        }
    }

    function round(value, precision) {
        var multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }

    function renderDescription(record) {
        if (record.Description !== null) {
            return record.Description;
        }
        else if (record.Mailcd === null) {
            return 'No Selection';
        }
        else {
            return 'No Description Found';
        }
    }

    const columns =
        [
            { dataIndex: 'Mailcd', title: 'Mail Code', align: 'center' },
            {
                title: 'Description',
                render: (record) => (
                    renderDescription(record)
                ),
            },
            { dataIndex: 'CurrentShowCount', title: getValues().selectedShowName + ' Count', align: 'center' },
            //{ dataIndex: 'CurrentShowTotal', title: getValues().selectedShowName + ' Total', align: 'center' },
            {
                title: getValues().selectedShowName + ' Percentage',
                align: 'center',
                render: (record) => (
                    renderPercentage(record.CurrentShowCount, record.CurrentShowTotal)
                ),
            },
            { dataIndex: 'PriorShowCount', title: getValues().priorShowName + ' Count', align: 'center' },
            //{ dataIndex: 'PriorShowTotal', title: getValues().priorShowName + ' Total', align: 'center' },
            {
                title: getValues().priorShowName + ' Percentage',
                align: 'center',
                render: (record) => (
                    renderPercentage(record.PriorShowCount, record.PriorShowTotal)
                ),
            },
            //{ dataIndex: 'PriorCount', title: getValues().priorShowName + ' Count', align: 'center', hidden: getValues().hidepriorshowcolumns },
            //{ dataIndex: 'PriorTotal', title: getValues().priorShowName + ' Percentage', align: 'center', hidden: getValues().hidepriorshowcolumns }
        ].filter(item => !item.hidden);

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    return (
        <div className="row">
            <div className="col-lg-2">
                Select Show<br />
                <Select id="ddSShows"
                    value={getValues().selectedShowCode}
                    className="form-control-custom"
                    mode="single"
                    placeholder="Select Show"
                    onChange={onShowChange}
                    className="form-control-custom w-100"
                    bordered={false}
                >
                    {getValues().showList?.map((show) => <Option key={show.showcode} value={show.showcode}>{show.name}</Option>)}
                </Select>
            </div>
            <div className="col-lg-10"></div>

            <div className="col-lg-12">&nbsp;</div>

            <div className="col-lg-12">
                <Spin spinning={!state.dataLoaded}>
                    <div style={!state.dataLoaded ? {} : { display: 'none' }}>
                        <Alert
                            message="Loading Mail Code Counts"
                            description="Please stand by while we retrieve mail code counts"
                            type="info"
                        />
                    </div>
                    {state.dataLoaded &&
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                {state.mailcds.length === 0 ?
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                        <span>
                                            No Mail Code Counts Found For Selected Show
                                        </span>
                                    } />
                                    :
                                    <>
                                        {
                                        getValues().hidepriorshowcolumns === true &&
                                            <div>
                                                <h4>* No Prior Show Found</h4>
                                            </div>
                                        }
                                        <div className="fullTable form-group col-lg-12">
                                            <Table className="custom-ant-selection"
                                                rowKey={item => item.Mailcd}
                                                rowSelection={rowSelection}
                                                hideSelectionColumn={true}
                                                bordered
                                                dataSource={state.mailcds}
                                                columns={columns}
                                                pagination={{
                                                    defaultPageSize: 25,
                                                    showSizeChanger: true,
                                                    pageSizeOptions: ['10', '25', '50', '100'],
                                                    showTotal: (total, range) => (
                                                        <span className="color-dark-blue" style={{ left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" }}>
                                                            Showing {range[0]}-{range[1]} of {total}
                                                        </span>
                                                    )
                                                }}
                                                onRow={(record) => {
                                                    return {
                                                        onClick: () => {
                                                            let selRows = [record.Mailcd];
                                                            setSelectedRowKeys([...selRows]);
                                                        }
                                                    }
                                                }}
                                                summary={pageData => {
                                                    let totalCurrentShowCount = 0;
                                                    let totalPriorShowCount = 0;

                                                    pageData.forEach(({ CurrentShowCount, PriorShowCount }) => {
                                                        totalCurrentShowCount += CurrentShowCount;
                                                        totalPriorShowCount += PriorShowCount;
                                                    });

                                                    return (
                                                        <Table.Summary.Row>
                                                            <Table.Summary.Cell></Table.Summary.Cell>
                                                            <Table.Summary.Cell><b>TOTALS</b></Table.Summary.Cell>
                                                            <Table.Summary.Cell><div className="text-center"><b>{totalCurrentShowCount}</b></div></Table.Summary.Cell>
                                                            <Table.Summary.Cell></Table.Summary.Cell>
                                                            <Table.Summary.Cell><div className="text-center"><b>{totalPriorShowCount}</b></div></Table.Summary.Cell>
                                                            <Table.Summary.Cell></Table.Summary.Cell>
                                                        </Table.Summary.Row>
                                                    );
                                                }}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    }
                </Spin>
            </div>
        </div>
    );
}