import React, { useEffect, useState, useRef } from 'react';
import Axios from '../../config/axios';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Empty, Spin, Alert, Table, Input } from 'antd';
import IssueDetailsSliderVO from './IssueDetailsSliderVO';
import ShowProgramDetailsSliderVO from './ShowProgramDetailsSliderVO';

export default function AdvertisingInfo(props) {
    const [state, setState] = useState({
        selectedCustomerId: props.selectedCustomerId,
        selectedSalesOrderId: null,
        dataLoaded: false,
        showShowProgramDetailsSliderVO: false,
        showIssueDetailsSliderVO: false,
        searchValue: ''
    });

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);

    const [issues, setIssues] = useState([]);
    const [filteredIssues, setfilteredIssues] = useState([]);

    useEffect(() => {
        
    }, []);

    useEffect(() => {
        getCustomerIssues(props.selectedCustomerId);

    }, [props]);

    function getCustomerIssues(id) {
        let postdata = {};

        postdata.company_id = id;
        Axios.post(`/api/GetCustomerIssues`, postdata
        ).then(response => {
            const issues = response.data;

            for (let i = 0; i < issues.length; i++) {
                // loop through issues
                if (i === (issues.length - 1)) {
                    var myFirstAdYear = (issues[i].issue_yymm).substring(0, 4);
                    var myCurrentYear = new Date().getFullYear();
                }
            }

            setIssues(issues);
            setfilteredIssues(issues);

            setState({
                ...state,
                issues,
                dataLoaded: true,
                totalAds: issues.length,
                firstAd: myFirstAdYear + ' (' + (myCurrentYear - parseInt(myFirstAdYear)) + ' years)',
                showShowProgramDetailsSliderVO: false,
                showIssueDetailsSliderVO: false
            });

        }).catch(error => {
            console.log(error);
        });
    }

    const columns = [
        {
            dataIndex: 'issue',
            title: 'Issue',
            key: 'issue',
            sorter: (a, b) => a.issue.localeCompare(b.issue)
        }, {
            dataIndex: 'color_type',
            title: 'Color',
            key: 'color_type',
            sorter: (a, b) => a.color_type.localeCompare(b.color_type)
        }, {
            dataIndex: 'size_unit',
            title: 'Size Unit',
            key: 'size_unit',
            sorter: (a, b) => a.size_unit.localeCompare(b.size_unit)
        }, {
            dataIndex: 'sales_order_id',
            title: 'Sales Order',
            key: 'sales_order_id',
            sorter: (a, b) => a.sales_order_id.localeCompare(b.sales_order_id)
        }, {
            dataIndex: 'order_status',
            title: 'Status',
            key: 'order_status',
            sorter: (a, b) => a.order_status.localeCompare(b.order_status)
        }, {
            title: 'Ad Details',
            key: 'action',
            width: 120,
            render: (record) => (
                <span className="hover" onClick={(e) => handleLoadIssueDetails(e, record)}><i id="adDetails" className="fas fa-edit fa-1x ml-3 text-center hover"></i></span>
            ),
            align: 'center'
        }
    ].filter(item => !item.hidden);

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    function handleLoadIssueDetails(e, record) {
        if (record.sales_order_id.charAt(0) === 'S') {
            setState({
                ...state,
                selectedSalesOrderId: record.sales_order_id,
                showIssueDetailsSliderVO: false,
                showShowProgramDetailsSliderVO: true
            });
        }
        else {
            setState({
                ...state,
                selectedSalesOrderId: record.sales_order_id,
                showShowProgramDetailsSliderVO: false,
                showIssueDetailsSliderVO: true
            });
        }
    }

    function searchIssues(value) {
        let searchMatches = [];
        var currIssue;
        var valueCleared = false;
        for (let i = 0; i < issues.length; i++) {
            currIssue = issues[i];

            if (value !== '') {
                if ((currIssue.issue !== null && currIssue.issue.toLowerCase().includes(value.toLowerCase())) ||
                    (currIssue.sales_order_id !== null && currIssue.sales_order_id.toLowerCase().includes(value.toLowerCase()))) {

                    searchMatches = [...searchMatches, issues[i]];
                }
            }
            else {
                valueCleared = true;
            }
        }

        setfilteredIssues(valueCleared ? [...issues] : [...searchMatches]);
    }

    function searchValueChanged(e) {
        setState({
            ...state,
            searchValue: e.target.value,
            showShowProgramDetailsSliderVO: false,
            showIssueDetailsSliderVO: false
        });
    }

    return (
        <div className="row">
            <div className="col-lg-12">
                <Spin spinning={!state.dataLoaded}>
                    <div style={!state.dataLoaded ? {} : { display: 'none' }}>
                        <Alert
                            message="Loading Advertising Details"
                            description="Please stand by while we retrieve advertising information for this customer"
                            type="info"
                        />
                    </div>
                    {state.dataLoaded &&
                        <div className="row">
                            <h5 className="frame-heading mt-3">
                                -- ADVERTISING INFO --
                            </h5>
                            <div className="col-sm-11 col-md-11 col-lg-12">
                                <Input.Search
                                    style={{ paddingTop: "20px", margin: "0 0 10px 0", width: "50%" }}
                                    placeholder="Search by issue or sales order..."
                                    enterButton
                                    onSearch={searchIssues}
                                    allowClear
                                    value={state.searchValue}
                                    onChange={searchValueChanged}
                                />
                            </div>

                            <div className="col-sm-10 col-md-10 col-lg-10">
                                {filteredIssues.length === 0 ?
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                        <span>
                                            No Advertising Information Found
                                        </span>
                                    } />
                                    :
                                <Table className="custom-ant-selection"
                                    rowKey={item => item.sales_order_id}
                                    rowSelection={rowSelection}
                                    hideSelectionColumn={true}
                                    bordered
                                    dataSource={filteredIssues}
                                    columns={columns}
                                    pagination={{
                                        defaultPageSize: 10,
                                        showSizeChanger: true,
                                        pageSizeOptions: ['10', '25', '50', '100'],
                                        showTotal: (total, range) => (
                                            <span className="color-dark-blue" style={{ left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" }}>
                                                Showing {range[0]}-{range[1]} of {total}
                                            </span>
                                        )
                                    }}
                                    onRow={(record) => {
                                        return {
                                            onClick: () => {
                                                let selRows = [record.sales_order_id];
                                                setSelectedRowKeys([...selRows]);
                                            },
                                            onDoubleClick: (e) => {
                                                handleLoadIssueDetails(e, record);
                                            }
                                        }
                                    }}
                                />
                                }
                            </div>
                            <div className="col-sm-2 col-md-2 col-lg-2 text-left">
                                {state.issues.length > 0 &&
                                    <div>
                                        <table style={{ border: 'solid thin navy', padding: '10px' }}>
                                            <tbody>
                                                <tr><td colSpan="2" style={{ paddingLeft: '10px', paddingRight: '10px', fontWeight: 'bold' }}>Ad Summary</td></tr>
                                                <tr><td style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }} colSpan="2">First Ad: {state.firstAd}</td></tr>
                                                <tr><td style={{ paddingLeft: '10px' }}>Total:</td><td className="text-right" style={{ paddingRight: '10px' }}>{state.totalAds}</td></tr>
                                            </tbody>
                                        </table>
                                        {/*<a href="/" className="btn btn-submit mt-3" id="btnEditAdInfo" onClick={handleClick}>View Sales Order Information</a>*/}
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </Spin>
            </div>
            {state.showIssueDetailsSliderVO &&
                <IssueDetailsSliderVO showPane={state.showIssueDetailsSliderVO} selectedSalesOrderId={state.selectedSalesOrderId} hidePane={() => setState({ ...state, showIssueDetailsSliderVO: false })} />
            }
            {state.showShowProgramDetailsSliderVO &&
                <ShowProgramDetailsSliderVO showPane={state.showShowProgramDetailsSliderVO} selectedSalesOrderId={state.selectedSalesOrderId}
                    hidePane={() => setState({ ...state, showShowProgramDetailsSliderVO: false})} />
            }
        </div>
    );
}