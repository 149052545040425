import React, { useEffect, useState, useRef } from "react";
import { withRouter } from 'react-router-dom';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import QRCode from 'qrcode.react';
import { useForm, Controller } from 'react-hook-form';
import Moment from 'moment';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import { Select, Empty, Spin, Alert, message, Tabs, Drawer, Table } from 'antd';
import { Fragment } from 'react';
import EmailTemplatePane from './EmailTemplatePane';

export default function EmailManager(props) {
    const [state, setState] = useState({
        formFields: {
            emailServers_ID: null,
            smtpServer: '',
            smtpPort: '',
            smtpUser: '',
            smtpPassword: '',
            app: ''
        },
        searchRunning: true,
        templates: [],
        selectedTemplateId: null,
        showTemplateDetailsSlider: false,
        templateEditMode: 'Add',
        selectedIdx: null,
        selectedTemplateType: '',
        showEmailRecipientsPane: false,
        availableEmployees: [],
        selectedApp: 'WEBCM'
    });

    const { register, getValues, setValue, control, watch } = useForm();

    const [showEmailTemplatePane, setShowEmailTemplatePane] = useState(false);
    const [showEmailRecipientsPane, setShowEmailRecipientsPane] = useState(false);

    const [templates, setTemplates] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);

    const { Option } = Select;

    useEffect(() => {
        Axios.get(`/api/GetEmailManagerInfo`, {
            params: {
                App: 'WEBCM'
            }
        }).then(response => {
            console.log(response.data);
            let templatesList = response.data.emailTemplates;

            let availableEmployees = response.data.availableEmployees;

            setValue('formFields', response.data.mailserverinfo);

            setTemplates(templatesList);

            setState({
                ...state,
                templates: templatesList,
                availableEmployees: availableEmployees,
                searchRunning: false
            });

        }).catch(error => {
            console.log(error);
        });
    }, [props]);

    function handleSaveEmailServerSettings() {
        let postdata = getValues("formFields");

        if (postdata.smtpServer === '' ||
            postdata.smtpUser === '' ||
            postdata.smtpPort === ''
        ) {
            toast.error('SMTP Server, Port and User are required fields');
            return;
        } else {
            Axios.post(`/api/UpdateMailServerInfo`, postdata
            ).then(response => {

                toast.success('Email Server Info Successfully Updated');
            }).catch(error => {
                console.log(error);
            });
        }
    }

    function getRecipients(record) {
        var separateRecipients;

        if (record.recipients === null) {
            return (
                <div style={{color: "red"}}>N/A</div>
            );
        }
        else if (record.recipients.includes(";")) {
            separateRecipients = record.recipients.split("; ");
        }
        else {
            separateRecipients = record.recipients;
        }

        return (
            <Fragment>
                <Select
                    mode="multiple"
                    placeholder="Please select"
                    onChange={(value) => handleRecipientsChange(record, value)}
                    className="form-control-custom w-100"
                    bordered={false}
                    maxTagCount={6}
                    value={separateRecipients}
                >
                    {state.availableEmployees.map((e, idx) => <Option index={idx} key={idx + "b" + idx} value={e.email}>{e.email}</Option>)}
                </Select>

                <label>
                    <button type="button"
                        id="updaterecipientsbutton"
                        onClick={() => {handleSaveEmailRecipients(record)}}
                        className="btn btn-link">
                        Update Recipients
                    </button>
                </label>
            </Fragment>
        );
    }

    const columns = [
        {
            dataIndex: 'emailTemplates_ID',
            title: 'emailTemplates_ID',
            key: 'emailTemplates_ID',
            hidden: true
        }, {
            dataIndex: 'templateType',
            title: 'Email Type',
            key: 'templateType',
            sorter: (a, b) => a.templateType.localeCompare(b.templateType)
        }, {
            dataIndex: 'templateDate',
            title: 'Upload Date',
            key: 'templateDate',
            width: 150,
            sorter: (a, b) => new Date(a.templateDate) - new Date(b.templateDate),
            render: (templateDate) => {
                return (
                    <div>
                        {templateDate === null ? '' : Moment(dateWithNoTimezone(templateDate)).format("L")}
                    </div>
                );
            }
        }, {
            dataIndex: 'uploadedBy',
            title: 'Uploaded By',
            key: 'uploadedBy',
            sorter: (a, b) => a.uploadedBy.localeCompare(b.uploadedBy)
        }, {
            dataIndex: 'notes',
            title: 'Notes',
            key: 'notes',
            sorter: (a, b) => a.notes.localeCompare(b.notes),
        }, {
            title: 'Recipients',
            key: 'action',
            render: (record) => (
                getRecipients(record)
            )
        }, {
            title: 'Download Template',
            key: 'action',
            width: 120,
            render: (record) => (
                <span className="hover" onClick={(e) => DownloadEmailTemplate(e, record)}><i id="downloadTemplate" className="fa fa-download fa-1x ml-3 text-center hover"></i></span>
            ),
            align: 'center'
        }, {
            title: 'Update Template',
            key: 'action',
            width: 120,
            render: (record) => (
                <span className="hover" onClick={(e) => LoadTemplateDetails(e, record)}><i id="templateDetails" className="fas fa-edit fa-1x ml-3 text-center hover"></i></span>
            ),
            align: 'center'
        }, {
            title: 'Delete Template',
            key: 'action',
            width: 120,
            render: (record) => (
                <i id="deleteTemplate" className="far fa-trash-alt fa-1x ml-3 text-center hover text-danger" onClick={(e) => handleDeleteTemplate(e, record)}></i>
            ),
            align: 'center'
        }
    ].filter(item => !item.hidden);

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    function handleDeleteTemplate(e, template) {
        confirm({
            title: "You are about to permanently delete this Template",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"]
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                let postdata = {
                    emailTemplates_ID: template.emailTemplates_ID,
                    app: 'WEBCM'
                }

                let formdata = new FormData();
                formdata.append('emailTemplates_ID', template.emailTemplates_ID);
                formdata.append('app', 'WEBCM');

                Axios.post(`/api/DeleteEmailTemplate`, formdata
                ).then(response => {
                    if (response.data) {
                        toast.success("Template has been deleted");

                        setTemplates(response.data);
                    }
                }).catch(error => {
                    console.log(error);
                });

                return 0;
            } else {
                return 0;
            }
        });
    }

    function LoadTemplateDetails(e, record) {
        setState({
            ...state,
            emailTemplate: record,
            selectedTemplateType: record.templateType,
            templateEditMode: 'Edit'
        });

        setShowEmailTemplatePane(true);
    }

    function setEmailTemplateInfo(emailTemplates) {
        setTemplates(emailTemplates);
    }

    function DownloadEmailTemplate(e, template) {
        let postdata = {
            emailtemplate: template.templateType
        }

        const key = "downloademailtemplate";
        message.loading({
            content: 'Downloading Email Template...',
            key,
            duration: 0,
            style: {
                position: "fixed",
                bottom: 0,
                left: 0
            },
        });

        Axios.post(`/api/DownloadEmailTemplate`, postdata, {
            responseType: 'blob'
        }).then(response => {
            message.destroy(key);

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = template.templateName;
            a.click();
            window.URL.revokeObjectURL(url);
        }).catch(error => {
            console.log(error);
        });
    }

    function handleAddNewTemplate() {
        setState({
            ...state,
            templateEditMode: 'Add',
        });

        setShowEmailTemplatePane(true);
    }

    function handleManageRecipients(template) {
        console.log(template);

        setShowEmailRecipientsPane(true);
    }

    function handleRecipientsChange(record, value) {
        let templatesList = [...templates];

        const selectedIdx = templatesList.findIndex(t => t.emailTemplates_ID === record.emailTemplates_ID);
        let item = templatesList[selectedIdx];

        //convert from array to single string
        item.recipients = value.toString().replaceAll(',', '; ');

        templatesList[selectedIdx] = item;

        console.log(templatesList);
        setTemplates(templatesList);

        setState({
            ...state,
            templates: templatesList,
            isDirty: true
        });
    }

    function handleSaveEmailRecipients(row) {
        if (row.recipients === "") {
            toast.error('You must select at least one valid recipient');
            return;
        }

        let postdata = {
            emailTemplate: row
        }

        let formdata = new FormData();
        formdata.append('emailTemplates_ID', row.emailTemplates_ID);
        formdata.append('app', 'WEBCM');
        formdata.append('recipients', row.recipients);

        Axios.post(`/api/UpdateEmailRecipients`, formdata
        ).then(response => {

            toast.success('Email Recipients Successfully Updated');
        }).catch(error => {
            console.log(error);
        });
    }

    function handleSelectedAppChange(e) {
        Axios.get(`/api/GetAllEmailRecipients`, {
            params: {
                App: e.target.value
            }
        }).then(response => {
            let templatesList = response.data.emailTemplates;
            let availableEmployees = response.data.availableEmployees;

            setTemplates(templatesList);

            setState({
                ...state,
                selectedApp: e.target.value,
                availableEmployees: availableEmployees,
                searchRunning: false
            });

        }).catch(error => {
            console.log(error);
        });
    }

    return (
        <div className="code-box-demo">
            <p></p>

            <h3 className="frame-heading">Mail Server Settings</h3>
            <div className="row">
                <div className="form-group col-lg-2">
                    <label className="form-label">SMTP Server</label>
                    <input {...register("formFields.smtpServer")} type="text" className="form-control-custom" />
                </div>
                <div className="form-group col-lg-1">
                    <label className="form-label">SMTP Port</label>
                    <input {...register("formFields.smtpPort")} type="text" className="form-control-custom" />
                </div>
                <div className="form-group col-lg-3">
                    <label className="form-label">SMTP User</label>
                    <input {...register("formFields.smtpUser")} type="text" className="form-control-custom" />
                </div>
                <div className="form-group col-lg-2">
                    <label className="form-label">SMTP Password</label>
                    <input {...register("formFields.smtpPassword")} type="text" className="form-control-custom" />
                </div>
            </div>

            <button className="btn btn-primary" onClick={handleSaveEmailServerSettings}>Save Email Server Settings</button>

            <p>&nbsp;</p>
            <hr />
            <h3 className="frame-heading">System Generated Emails/Templates</h3>

            <div onChange={handleSelectedAppChange}>
                <label className="form-label">Select App:</label>&nbsp;&nbsp;
                <input type="radio" name="templateType" value="WEBCM" className="mt-1" checked={state.selectedApp === "WEBCM"} />&nbsp;<label className="form-label">WEBCM</label>&nbsp;&nbsp;&nbsp;
                <input type="radio" name="templateType" value="MSO" className="mt-1" checked={state.selectedApp === "MSO"} />&nbsp;<label className="form-label">MyShowOnline</label>&nbsp;&nbsp;&nbsp;
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <button className="btn btn-submit float-end" onClick={handleAddNewTemplate}>Add New Template</button>
                </div>
                <div className="col-lg-12">&nbsp;</div>
                <div className="col-lg-12">
                    <Spin spinning={state.searchRunning}>
                        <div style={state.searchRunning ? {} : { display: 'none' }}>
                            <Alert
                                message="Loading Email Templates..."
                                description="Please stand by while we load all email templates"
                                type="info"
                            />
                        </div>
                        {!state.searchRunning &&
                            <div className="row">
                                {templates.length === 0 ?
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                        <span>
                                            No email templates found
                                        </span>
                                    } />
                                    :
                                    <Table className="custom-ant-selection"
                                        rowKey={item => item.emailTemplates_ID}
                                        rowSelection={rowSelection}
                                        hideSelectionColumn={true}
                                        bordered
                                        dataSource={templates}
                                        columns={columns}
                                        pagination={{
                                            defaultPageSize: 10,
                                            showSizeChanger: true,
                                            pageSizeOptions: ['10', '25', '50', '100'],
                                            showTotal: (total, range) => (
                                                <span className="color-dark-blue" style={{ left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" }}>
                                                    Showing {range[0]}-{range[1]} of {total}
                                                </span>
                                            )
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: () => {
                                                    let selRows = [record.emailTemplates_ID];
                                                    setSelectedRowKeys([...selRows]);
                                                },
                                                onDoubleClick: (e) => {
                                                    LoadTemplateDetails(e, record);
                                                }
                                            }
                                        }}
                                    />
                                }
                            </div>
                        }
                    </Spin>
                </div>

                {showEmailTemplatePane && <EmailTemplatePane {...props} hidePane={() => setShowEmailTemplatePane(false)} emailTemplate={state.emailTemplate} updateEmailTemplateInfo={(templates) => setEmailTemplateInfo(templates)} templateEditMode={state.templateEditMode} paneTitle='Update Email Template' templateType={state.selectedTemplateType} />}
            </div>
        </div>
    );
}