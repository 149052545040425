import React, { useEffect, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Axios from '../../config/axios';
import { useForm } from "react-hook-form";
//import { getDocumentTypesByLevel } from '../../common/services/ApiService';

import { useDropzone } from 'react-dropzone';
import { InboxOutlined } from '@ant-design/icons';
import Globals from '../../config/globals';

import { toast } from '@rickylandino/react-messages';

import ReactLoading from 'react-loading';

function MyDropzone(props) {
    const maxSize = 1048576;

    const onDrop = useCallback(acceptedFiles => {
        const reader = new FileReader()
        let file = acceptedFiles[0];

        reader.onload = async function () {
            props.setFile(file);
            //setValue("templateName", templateName);

            //setState({
            //    ...state
            //});
            //props.addFile(file);
        }
        reader.readAsArrayBuffer(file);
    }, [])

    const { isDragActive, getRootProps, getInputProps, isDragReject, rejectedFiles } = useDropzone({
        onDrop,
        minSize: 0,
        accept: 'text/html, text/htm'
    });

    const isFileTooLarge = rejectedFiles?.length > 0 && rejectedFiles[0].size > maxSize;

    return (
        <div className="ant-upload ant-upload-drag">
            <div {...getRootProps()} className="pt-4 h-100">
                <input {...getInputProps()} />
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                {!isDragActive && 'Click here to select an html attachment'}
                {isDragActive && !isDragReject && "Drop the file!"}
                {isDragReject && "File type not accepted, sorry!"}
                {isFileTooLarge && (
                    <div className="text-danger mt-2">
                        File is too large.
                    </div>
                )}
            </div>
        </div>
    );
}

export default function EmailTemplatePane(props) {
    const [state, setState] = useState({
        paneTitle: '',
        templateType: '',
        emailTemplate: {},
        templateEditMode: '',
        showFileUpload: true,
        showClearFile: false
    });

    const [showPane, setShowPane] = useState(true);
    const [file, setFile] = useState({});
    const [documentTypes, setDocumentTypes] = useState([]);

    const [loading, setLoading] = useState(false);

    const { register, handleSubmit, setValue, getValues } = useForm();

    const theme = window.sessionStorage.getItem("theme");

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    useEffect(() => {
        var showFileUpload;
        var showClearFile;

        if (props.templateEditMode === 'Edit' && props.emailTemplate.templateName !== null) {
            setValue("templateName", props.emailTemplate.templateName);
            setValue("templateType", props.emailTemplate.templateType);
            setValue("notes", props.emailTemplate.notes);
            showFileUpload = false;
            showClearFile = true;
        }
        else {
            setValue("templateName", "");
            setValue("templateType", "");
            setValue("notes", "");
            showFileUpload = true;
            showClearFile = false;
        }

        setState({
            ...state,
            paneTitle: props.paneTitle,
            templateType: props.templateType,
            emailTemplate: props.emailTemplate,
            templateEditMode: props.templateEditMode,
            showFileUpload,
            showClearFile
        });

    }, [props]);

    function hidePane() {
        setShowPane(false);

        setTimeout(() => {
            props.hidePane();
        }, 1000)
    }

    function handleSave() {
        setLoading(true);

        //let postdata = {};

        //if (state.templateEditMode === 'Edit') {
        //    postdata = {
        //        templateID: props.emailTemplate.emailTemplates_ID,
        //        templateData: file,
        //        templateName: getValues().templateName,
        //        templateType: getValues().templateType,
        //        notes: getValues().notes,
        //        uploadedBy: Globals.userInfo.userName,
        //        app: 'WEBCM'
        //    }
        //}
        //else {
        //    postdata = {
        //        templateData: file,
        //        templateName: getValues().templateName,
        //        templateType: getValues().templateType,
        //        notes: getValues().notes,
        //        uploadedBy: Globals.userInfo.userName,
        //        app: 'WEBCM'
        //    }
        //}

        let formdata = new FormData();
        if (state.templateEditMode === 'Edit') { formdata.append('templateID', props.emailTemplate.emailTemplates_ID); }
        formdata.append('templateData', file);
        formdata.append('templateName', getValues().templateName);
        formdata.append('templateType', getValues().templateType);
        formdata.append('notes', getValues().notes);
        formdata.append('uploadedBy', userInfo.userName);
        formdata.append('app', 'WEBCM');

        if (!getValues("templateName")) {
            setLoading(false);
            toast.error("No template file selected");
            return;
        }

        if (state.templateEditMode === 'Add') {
            Axios.post(`/api/AddEmailTemplate`, formdata
            ).then(response => {
                toast.success("Email template successfully added!");

                hidePane();

                props.updateEmailTemplateInfo(response.data);

            }).catch(error => {

            });
            console.log(file);
        }
        else {
            Axios.post(`/api/UpdateEmailTemplate`, formdata
            ).then(response => {
                toast.success("Email template successfully updated!");

                hidePane();

                props.updateEmailTemplateInfo(response.data);

            }).catch(error => {

            });
        }
    }

    function handleFile(file) {
        setFile(file);

        let templateName = file?.name;
        setValue("templateName", templateName);

        setState({
            ...state
        });
    }

    function handleClearFile() {
        setValue("templateName", "");

        setState({
            ...state,
            showFileUpload: true,
            showClearFile: false
        });
    }

    return (
        <SlidingPane
            overlayClassName='pinkCard'
            className={'smallPane'}
            isOpen={showPane || false}
            title={state.templateEditMode === 'Add' ? 'Add New Email Template' : 'Update Email Template'}
            onRequestClose={hidePane}
            shouldCloseOnEsc={false}
        >

            <div className="slide-pane-body">
                <div style={state.showFileUpload === true ? {} : { display: 'none' }}>
                    <MyDropzone setFile={(file) => handleFile(file)} />
                </div>
                {/*Selected HTML File:&nbsp;{file?.name && <pre className="mb-4">{file.name}</pre>}*/}

                <form>
                    <div className="row">
                        <div className="form-group col-12">
                            <label className="form-label">Selected HTML File:&nbsp;</label><br />
                            <label className="form-label" style={{ color: "green" }}>{getValues().templateName}</label>
                            <button type="button" class="btn btn-link" onClick={handleClearFile} style={state.showClearFile === true ? {} : { display: 'none' }}>Clear File</button>
                            {/*<input {...register("templateName", { required: true })} className="form-control-custom" disabled={true} />*/}
                        </div>

                        <div className="form-group col-12">
                            <label className="form-label">Template Type</label>
                            <input {...register("templateType", { required: true })} className="form-control-custom" disabled={state.templateEditMode === 'Edit' ? true : false} />
                        </div>
                        <div className="form-group col-12">
                            <label className="form-label">Notes</label>
                            <textarea {...register("notes")} className="form-control-custom" />
                        </div>
                    </div>
                </form>
            </div>

            <div className="modal-footer">
                <div className="form-group col-12 padding-25-10">
                    <button className="btn btn-submit" onClick={handleSave} disabled={loading}>{loading ? <ReactLoading className='tableLoading float-left' type={"spokes"} color={'#F30079'} height={30} width={30} /> : 'Set Template'}</button>
                    <button className="btn btn-outline-primary ms-3 float-right" onClick={hidePane}>Cancel</button>
                </div>
            </div>
        </SlidingPane>
    );
}