import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.css';
import 'antd/dist/antd.css';
import './css/antd-override.css';
import './css/global.css';
import './css/forms.css';
import './css/react-list.css';
import './css/card.css';

import ClearCache from 'react-clear-cache';

const render = () => {
    ReactDOM.render(
        <BrowserRouter>
            <ClearCache>
                {({ isLatestVersion, emptyCacheStorage, loading }) => (
                    <App isLatestVersion={isLatestVersion} emptyCache={emptyCacheStorage} cacheLoading={loading} />
                )}
            </ClearCache>
        </BrowserRouter>, document.getElementById('root')
    );
};

//const render = () => {
//    ReactDOM.render(
//        <BrowserRouter>
//            <App />
//        </BrowserRouter>, document.getElementById('root')
//    );
//};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Render once
render();

// Webpack Hot Module Replacement API
if (module.hot) {
    module.hot.accept('./App', () => {
        render();
    });
}