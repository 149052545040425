import React, { useEffect, useState, useRef } from 'react';
import { toast, confirm } from '@rickylandino/react-messages';
import { useForm, Controller } from "react-hook-form";
import Axios from '../../config/axios';
import { Select, Empty, Spin, Alert, message, Tabs, Drawer, Table, Input } from 'antd';

export default function UserProfileManager(props) {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const [state, setState] = useState({
        searchRunning: true
    });

    const { TabPane } = Tabs;

    const { register, getValues, setValue, control, watch } = useForm();

    const [showSlider, setShowSlider] = useState(false);

    const [listData, setListData] = useState({
        allDashboardWidgets: [],
        jssGroupedReports: {},
        jssReportsList: {},
        jstGroupedReports: {},
        jstReportsList: {},
        loaded: false,
        searchValue: ''
    });

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);

    const [users, setUsers] = useState([]);
    const [filteredUsers, setfilteredUsers] = useState([]);

    useEffect(() => {
        //const subscription = watch((value, { name, type }) => {
        //    if (type === 'change') {
        //        isDirty.current = true;
        //    }
        //});

        Axios.get(`/api/GetManageUserItems`, {
            params: {
                
            }
        }).then(response => {
            setListData({
                allDashboardWidgets: response.data.allDashboardWidgets,
                jssReportsList: response.data.jssReportsList,
                jssGroupedReports: response.data.jssGroupedReports,
                jstReportsList: response.data.jstReportsList,
                jstGroupedReports: response.data.jstGroupedReports,
                loaded: true
            });

        }).catch(error => {
            console.log(error);
        });

        //kills subscription on unmount
        //return () => { subscription.unsubscribe(); };
    }, []);

    useEffect(() => {
        LoadUsers();
    }, [props]);

    useEffect(() => {
        if (showSlider === false) {

        }
    }, [showSlider]);

    function LoadUsers() {
        let postdata = {};
        postdata.jobshopdivision = userInfo.jobShopDivision

        Axios.post(`/api/GetAllUsers`, postdata
        ).then(response => {
            let usersList = response.data;

            setUsers(usersList);
            setfilteredUsers(usersList);

            setState({
                ...state,
                searchRunning: false
            });

        }).catch(error => {
            console.log(error);
        });
    }

    const columns = [
        {
            dataIndex: 'id',
            title: 'User Id',
            key: 'id',
            width: 100,
            sorter: (a, b) => a.id.localeCompare(b.id)
        }, {
            dataIndex: 'username',
            title: 'User Name',
            key: 'username',
            sorter: (a, b) => a.username.localeCompare(b.username)
        }, {
            title: 'User Details',
            key: 'action',
            width: 130,
            render: (record) => (
                <span className="hover" onClick={(e) => LoadUserDetails(e, record)}><i id="userDetails" className="fas fa-edit fa-1x ml-3 text-center hover"></i></span>
            ),
            align: 'center'
        }, {
            title: 'Delete User',
            key: 'action',
            width: 130,
            render: (record) => (
                <i id="deleteUser" className="far fa-trash-alt fa-1x ml-3 text-center hover text-danger" onClick={(e) => handleDeleteUser(e, record)}></i>
            ),
            align: 'center'
        }
    ].filter(item => !item.hidden);

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    function LoadUserDetails(e, record) {
        setValue('user', record);
        setValue('initialuservalues', record);
        setValue('userEditMode', 'edit');
        //setValue('userRowIndex', rowIndex);

        setShowSlider(true);
    }

    function handleDeleteUser(e, record) {
        confirm({
            title: "You are about to permanently delete this User",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"]
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                Axios.post(`/api/DeleteUser`, record
                ).then(response => {
                    if (response.data) {
                        toast.success("User has been deleted");

                        //now update the parent list
                        setUsers(response.data);
                        setfilteredUsers(response.data);

                        setState({
                            ...state,
                            searchValue: ''
                        });
                    }
                }).catch(error => {
                    console.log(error);
                });

                return 0;
            } else {
                return 0;
            }
        });
    }

    function handleSubmit() {
        let user = getValues().user;

        setValue('initialuservalues', getValues().user);

        if (getValues().user.id === '' || getValues().user.id === null || getValues().user.username === '' || getValues().user.username === null) {
            toast.error("User Id and User Name are required fields");
            return;
        } else {
            if (getValues().userEditMode === 'edit') {
                Axios.post(`/api/UpdateUser`, user
                ).then(response => {
                    toast.success("User Information Successfully Updated");

                    //now update the parent list
                    let usersList = [...users];

                    const selectedIdx = usersList.findIndex(u => u.id === user.id);
                    usersList[selectedIdx] = user;
                    setfilteredUsers(usersList);

                    setShowSlider(false);

                    setState({
                        ...state
                    });
                }).catch(error => {
                    toast.error("Problem Encountered Updating User");

                    console.log(error);
                });
            }
            else {
                Axios.post(`/api/AddNewUser`, user
                ).then(response => {
                    //now update the parent list
                    if (response.data) {
                        toast.success("New User Successfully Added");

                        //now update the parent list
                        setUsers(response.data);
                        setfilteredUsers(response.data);

                        setState({
                            ...state
                        });

                        setShowSlider(false);
                    }
                }).catch(error => {
                    toast.error("Problem Encountered Adding New User");

                    console.log(error);
                });
            }
        }
    }

    function handleAddNewUser() {
        setValue('user', {});
        setValue('initialuservalues', {});

        setValue('user.username', '');
        setValue('user.email', '');
        setValue('user.extension', '');
        setValue('user.id', '');
        setValue('user.password', '');
        setValue('user.usertype', '');
        setValue('user.webcmRoles', '');
        setValue('userEditMode', 'add');
        setValue('user.imapsentfolder', 'add');

        setValue('initialuservalues.username', '');
        setValue('initialuservalues.email', '');
        setValue('initialuservalues.extension', '');
        setValue('initialuservalues.id', '');
        setValue('initialuservalues.password', '');
        setValue('initialuservalues.usertype', '');
        setValue('initialuservalues.webcmRoles', '');
        setValue('initialuservalues.imapsentfolder', '');

        setValue('userEditMode', 'add');

        setState({ ...state });

        setShowSlider(true);
    }

    function closeSlider() {
        console.log(getValues().initialuservalues);
        console.log(getValues().user);

        //console.log(JSON.stringify(user) === JSON.stringify(getValues().initialuservalues));

        if (JSON.stringify(getValues().user) !== JSON.stringify(getValues().initialuservalues)) {
            confirm({
                title: "You are about to lose all changes.",
                content: "Are you sure you would like to proceed?",
                buttons: ["Yes", "No"]
            }, (buttonPressed) => {
                if (buttonPressed === 'Yes') {
                    setShowSlider(false);

                    return 0;
                } else {
                    return 0;
                }
            });
        } else {
            setShowSlider(false);
        }
    }

    function handleUserTypeChange(value) {
        setValue('user.usertype', value);
        setState({ ...state });
    }

    function handleImapSentFolderChange(value) {
        setValue('user.imapSentFolder', value);
        setState({ ...state });
    }

    function handleUserRolesChange(event) {
        let target = event.target;
        let name = target.name;

        let userRoles = getValues().user.webcmRoles;

        if (userRoles !== null) {
            //Trim last '|'
            userRoles = userRoles.substring(0, userRoles.length - 1);

            //Trim first '|'
            userRoles = userRoles.substring(1);
        }
       
        let userRolesArray = userRoles?.split('|') || [];

        if (userRolesArray.includes(name)) {
            var index = userRolesArray.indexOf(name);
            if (index > -1) {
                userRolesArray.splice(index, 1);
            }
        }
        else {
            userRolesArray.push(name);
        }

        let newString = '|';
        userRolesArray.forEach(listItem => {
            newString += listItem + '|';
        });

        setValue('user.webcmRoles', newString);

        setState({
            ...state
        });
    }

    function handleJSSReportsAccessChange(division, e) {
        let target = e.target;
        let name = target.name;

        /*isDirty.current = true;*/

        var repAccess;
        if (division === 'JSS') {
            repAccess = getValues().user.jssReports;
        }
        else {
            repAccess = getValues().user.jstReports;
        }
        let splitList = repAccess?.split('|') || [];

        let listIdx = splitList.findIndex(item => item === name);

        if (listIdx >= 0) {
            splitList.splice(listIdx, 1);
        } else {
            splitList.push(name);
        }

        let newString = '';
        splitList.forEach(listItem => {
            newString += listItem + '|';
        });

        newString = newString.slice(0, -1);

        repAccess = newString;

        if (division === 'JSS') {
            setValue('user.jssReports', repAccess);
        }
        else {
            setValue('user.jstReports', repAccess);
        }

        //quick hack to force a state change to update the display of whatever checkbox was selected
        setState({
            ...state
        });
        //TODO: Example of this method execution can be found in CompanyPlanContactTab.js -> handleMailingsChange method ~line 222
    }

    const { Option } = Select;

    let webcmRolesArray = getValues().user?.webcmRoles?.split('|') || [];
    //let specialRolesArr = getValues().employee?.specialRoles?.split('|') || [];
    let jssReportsArr = getValues().user?.jssReports?.split('|') || [];
    let jstReportsArr = getValues().user?.jstReports?.split('|') || [];

    function searchUsers(value) {
        let searchMatches = [];
        var currUser;
        var valueCleared = false;
        for (let i = 0; i < users.length; i++) {
            currUser = users[i];

            if (value !== '') {
                if ((currUser.id !== null && currUser.id.toLowerCase().includes(value.toLowerCase())) ||
                    (currUser.username !== null && currUser.username.toLowerCase().includes(value.toLowerCase()))) {

                    searchMatches = [...searchMatches, users[i]];
                }
            }
            else {
                valueCleared = true;
            }
        }

        setfilteredUsers(valueCleared ? [...users] : [...searchMatches]);
    }

    function searchValueChanged(e) {
        setState({
            ...state,
            searchValue: e.target.value
        });
    }

    return (
        <div className="row">
            <div className="col-lg-12">
                <Spin spinning={state.searchRunning}>
                    <div style={state.searchRunning ? {} : { display: 'none' }}>
                        <Alert
                            message="Loading Users..."
                            description="Please stand by while we load all user profiles"
                            type="info"
                        />
                    </div>
                    {!state.searchRunning &&
                        <div className="row">
                            <div className="col-sm-10 col-md-10 col-lg-10">
                                <Input.Search
                                    style={{ paddingTop: "20px", margin: "0 0 10px 0", width: "50%" }}
                                    placeholder="Search users by user id or user name..."
                                    enterButton
                                    onSearch={searchUsers}
                                    allowClear
                                    value={state.searchValue}
                                    onChange={searchValueChanged}
                                />
                            </div>
                            <div className="col-lg-2">
                                <button className="btn btn-submit float-end" onClick={handleAddNewUser}>Add New User</button>
                            </div>
                            <div className="col-sm-11 col-md-11 col-lg-12">
                                {filteredUsers.length === 0 ?
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                        <span>
                                            No user profiles found
                                        </span>
                                    } />
                                    :
                                    <Table className="custom-ant-selection"
                                    rowKey={item => item.id}
                                        rowSelection={rowSelection}
                                        hideSelectionColumn={true}
                                        bordered
                                        dataSource={filteredUsers}
                                        columns={columns}
                                        pagination={{
                                            defaultPageSize: 10,
                                            showSizeChanger: true,
                                            pageSizeOptions: ['10', '25', '50', '100'],
                                            showTotal: (total, range) => (
                                                <span className="color-dark-blue" style={{ left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" }}>
                                                    Showing {range[0]}-{range[1]} of {total}
                                                </span>
                                            )
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: () => {
                                                    let selRows = [record.id];
                                                    setSelectedRowKeys([...selRows]);
                                                },
                                                onDoubleClick: (e) => {
                                                    LoadUserDetails(e, record);
                                                }
                                            }
                                        }}
                                    />
                                }
                            </div>
                        </div>
                    }
                </Spin>
            </div>
            <Drawer
                closeIcon={<i className="fas fa-chevron-left float-left"></i>}
                title='Manage User'
                placement={'right'}
                onClose={closeSlider}
                visible={showSlider}
                key='editEmployee'
                width={'75%'}

                footer={
                    <div className="d-flex justify-content-between">
                        <div>
                            <button className="btn btn-submit" onClick={handleSubmit}>Save</button>
                            <button className="btn btn-outline-primary float-right" onClick={closeSlider}>Close</button>
                        </div>
                    </div>
                }
            >
                <div className="row">
                    <div className="col col-lg-12 col-12">
                        <div className="row">
                            <div className="form-group col col-lg-3 col-12">
                                <label className="form-label">User's Name</label>
                                <input type="text" className="form-control-custom" {...register('user.username')} />
                            </div>
                            <div className="form-group col col-lg-3 col-12">
                                <label className="form-label">Email</label>
                                <input type="text" className="form-control-custom" {...register('user.email')} />
                            </div>
                            <div className="form-group col col-lg-2 col-12">
                                <label className="form-label">Email Password</label>
                                <input type="text" className="form-control-custom" {...register('user.emailPassword')} />
                            </div>
                            <div className="form-group col col-lg-2 col-12">
                                <label className="form-label">IMAP Sent Folder</label>
                                <Select id="ddImapSentFolder"
                                    value={getValues().user?.imapSentFolder}
                                    className="form-control-custom"
                                    mode="single"
                                    placeholder="Select Imap Sent Folder"
                                    onChange={handleImapSentFolderChange}
                                    className="form-control-custom w-100"
                                    bordered={false}
                                >
                                    <Option value=''>Select</Option>
                                    <Option value='Sent'>Sent</Option>
                                    <Option value='Sent Items'>Sent Items</Option>
                                    <Option value='Sent Messages'>Sent Messages</Option>
                                    <Option value='Sent Mail'>Sent Mail</Option>
                                </Select>
                            </div>
                            <div className="form-group col col-lg-2 col-12">
                                <label className="form-label">Extension</label>
                                <input type="text" className="form-control-custom" {...register('user.extension')} />
                            </div>

                            <div className="form-group col col-lg-4 col-12">
                                <label className="form-label">User Id</label>
                                <input type="text" className="form-control-custom" {...register('user.id')} disabled={getValues('userEditMode') === 'add' ? false : true} />
                            </div>
                            <div className="form-group col col-lg-4 col-12">
                                <label className="form-label">Password</label>
                                <input type="text" className="form-control-custom" {...register('user.password')} />
                            </div>
                            <div className="form-group col col-lg-4 col-12">
                                <label className="form-label">User Type</label>
                                <Select id="ddUserType"
                                    value={getValues().user?.usertype}
                                    className="form-control-custom"
                                    mode="single"
                                    placeholder="Select User Type"
                                    onChange={handleUserTypeChange}
                                    className="form-control-custom w-100"
                                    bordered={false}
                                >
                                    <Option value=''>Select</Option>
                                    <Option value='shows'>JSS</Option>
                                    <Option value='jst'>JST</Option>
                                    <Option value='admin'>Admin</Option>
                                </Select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col col-lg-4 col-4">
                        <div className="smart-form">
                            <header>Special Roles</header>
                            <div className="row scrollableDiv">
                                {listData.allDashboardWidgets.map((item, idx) => (
                                    <div className="form-group text-left col col-12" key={idx}>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" name={item.id} checked={webcmRolesArray.find(role => role === item.id) ? true : false} onChange={handleUserRolesChange} />
                                            <label className="custom-control-label"> {item.id}</label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col col-lg-4 col-4">
                        <div className="smart-form">
                            <header>JSS Reports</header>
                            <div className="row scrollableDiv">
                                {Object.keys(listData.jssGroupedReports).map((key, idx) => {
                                    return (
                                        <div key={idx}>
                                            <div className="strike">
                                                <span>{key} Reports</span>
                                            </div>
                                            {listData.jssGroupedReports[key].map((r, idx2) => (
                                                <div className="form-group text-left col col-12" key={idx2}>
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" name={r.reportId} checked={jssReportsArr.find(rep => rep === r.reportId) ? true : false} onChange={(e) => handleJSSReportsAccessChange("JSS", e)} />
                                                        <label className="custom-control-label"> {r.reportName}</label>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="col col-lg-4 col-4">
                        <div className="smart-form">
                            <header>JST Reports</header>
                            <div className="row scrollableDiv">
                                {Object.keys(listData.jstGroupedReports).map((key, idx) => {
                                    return (
                                        <div key={idx}>
                                            <div className="strike">
                                                <span>{key} Reports</span>
                                            </div>
                                            {listData.jstGroupedReports[key].map((r, idx2) => (
                                                <div className="form-group text-left col col-12" key={idx2}>
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" name={r.reportId} checked={jstReportsArr.find(rep => rep === r.reportId) ? true : false} onChange={(e) => handleJSSReportsAccessChange("JST", e)} />
                                                        <label className="custom-control-label"> {r.reportName}</label>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </Drawer>
        </div>
    );
}