import { atom } from 'recoil';
import Axios from '../config/axios';

export const persistentEmails = atom({
    key: 'persistentEmails', // unique ID (with respect to other atoms/selectors)
    default: [], // default value (aka initial value)
});

export function insertPersistentEmail(postdata) {
    return Axios.post(`/api/InsertPersistentEmail`, postdata).then(response => response.data).catch(error => error);
}
export function deletePersistentEmailAndAttachments(postdata) {
    return Axios.post(`/api/DeletePersistentEmailAndAttachments`, postdata).then(response => response.data).catch(error => error);
}
export function updatePersistentEmail(postdata) {
    let formdata = new FormData();
    formdata.append('item', JSON.stringify(postdata));

    postdata.fileList.forEach((file) => {
        formdata.append('files', file);
    });

    return Axios.post(`/api/UpdatePersistentEmail`, formdata).then(response => response.data).catch(error => error);
}
//export function updatePersistentEmailAttachment(files, id) {

//    let formdata = new FormData();
//    formdata.append('id', id);

//    files.forEach((file) => {
//        formdata.append('files', file);
//    });

//    return Axios.post(`/api/UpdatePersistentEmailAttachment`, formdata).then(response => response.data).catch(error => error);
//}

export function getPersistentEmailsByUser(userId) {
    return Axios.get('/api/GetPersistentEmailsByUser', {
        params: {
            userId
        }
    }).then(response => response.data).catch(error => error);
}