import React, { useEffect, useState, Fragment } from 'react';
import Axios from '../../config/axios';
import Moment from 'moment';
import TaskDetailsSlider from '../Tasks/TaskDetailsSlider';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import { Empty, Spin, Alert, Table, Input } from 'antd';

export default function CustomerTasks(props) {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const [state, setState] = useState({
        dataLoaded: false,
        tasks: [],
        showTaskManagerSlider: false,
        showTaskDetailsSlider: false
    });

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);

    const [tasks, setTasks] = useState([]);

    useEffect(() => {
        GetCustomerTasks();

    }, [props]);

    function GetCustomerTasks() {
        let postdata = {};
        postdata.userid = userInfo.userId;
        postdata.company_id = props.selectedCustomerId;
        postdata.division = userInfo.jobShopDivision;

        Axios.post(`/api/GetCustomerTasks`, postdata
        ).then(response => {
            const tasks = response.data;

            setTasks(tasks);

            setState({
                ...state,
                tasks,
                dataLoaded: true,
                showTaskDetailsSlider: false,
                showTaskManagerSlider: false
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function dateFormatter(cell, row) {
        let date = cell;
        if (typeof cell !== 'object') {
            date = new Date(cell);
        }

        var month = date.getMonth() + 1;
        var day = date.getDate();
        var year = date.getFullYear();
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';

        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;

        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        hours = hours < 10 ? '0' + hours : hours;

        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;

        return month + "/" + day + "/" + year + "  " + strTime;

    }

    const columns = [
        {
            dataIndex: 'Task_ID',
            title: 'Task_ID',
            key: 'Task_ID',
            hidden: true
        }, {
            dataIndex: 'company_id',
            title: 'company_id',
            key: 'company_id',
            hidden: true
        }, {
            dataIndex: 'Type',
            title: 'Task Type',
            key: 'Type',
            width: 120,
            sorter: (a, b) => a.Type.localeCompare(b.Type)
        }, {
            dataIndex: 'DateTime',
            title: 'Date / Time',
            key: 'DateTime',
            width: 200,
            sorter: (a, b) => new Date(a.DateTime) - new Date(b.DateTime),
            render: (DateTime) => {
                return (
                    <div>
                        {DateTime === null ? '' : dateFormatter(DateTime)}
                    </div>
                );
            }
        }, {
            dataIndex: 'ContactCompany',
            title: 'Contact/Company',
            key: 'ContactCompany',
            sorter: (a, b) => a.ContactCompany.localeCompare(b.ContactCompany)
        }, {
            dataIndex: 'Priority',
            title: 'Priority',
            key: 'Priority',
            width: 120,
            sorter: (a, b) => a.Priority.localeCompare(b.Priority)
        }, {
            title: 'Task Details',
            key: 'action',
            width: 120,
            render: (record) => (
                <span className="hover" onClick={(e) => handleTaskDetails(e, record)}><i id="taskDetails" className="fas fa-edit fa-1x ml-3 text-center hover"></i></span>
            ),
            align: 'center'
        }
    ].filter(item => !item.hidden);

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    function showTaskManager() {
        setState({
            ...state,
            showTaskManagerSlider: true,
            showTaskDetailsSlider: false
        })
    }

    function handleAddNewTask() {
        setState({
            ...state,
            Task_ID: 0,
            showTaskDetailsSlider: true,
            showTaskManagerSlider: false
        })
    }

    function handleTaskDetails(e, record) {
        setState({
            ...state,
            Task_ID: record.Task_ID,
/*            selectedIdx: rowIndex,*/
            selectedTaskId: record.Task_ID,
            showTaskDetailsSlider: true,
            showTaskManagerSlider: false
        })
    }

    async function updateTableDisplay(tasks, taskAction, idxToDirectTo = null) {
        await setState({ ...state, dataLoaded: false });

        if (taskAction !== 'add') {
            let refreshedtasks = [...tasks];

            setTasks(refreshedtasks);

            setState({
                ...state,
                //tasks,
                //selectedTask: tasks[idxToDirectTo],
                dataLoaded: true,
                showTaskDetailsSlider: false,
                showTaskManagerSlider: false
            });
        }
        else {
            //Reload tasks from DB
            GetCustomerTasks();
        }
    }

    return (
        <div className="row">
            <Spin spinning={!state.dataLoaded}>
                <div style={!state.dataLoaded ? {} : { display: 'none' }}>
                    <Alert
                        message="Loading Tasks"
                        description="Please stand by while we retrieve tasks for this customer"
                        type="info"
                    />
                </div>
                {state.dataLoaded &&
                    <div className="row">
                        <h5 className="frame-heading mb-4">
                            -- Tasks --
                        </h5>
                        <div className="col-lg-10">
                            <div>
                                {state.dataLoaded &&
                                    <Fragment>
                                        {tasks.length === 0 ?
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                                <span>
                                                    No Tasks Found For This Customer
                                                </span>
                                            } />
                                            :
                                            <Table className="custom-ant-selection"
                                                rowKey={item => item.Task_ID}
                                                rowSelection={rowSelection}
                                                hideSelectionColumn={true}
                                                bordered
                                                dataSource={tasks}
                                                columns={columns}
                                                pagination={{
                                                    defaultPageSize: 10,
                                                    showSizeChanger: true,
                                                    pageSizeOptions: ['10', '25', '50', '100'],
                                                    showTotal: (total, range) => (
                                                        <span className="color-dark-blue" style={{ left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" }}>
                                                            Showing {range[0]}-{range[1]} of {total}
                                                        </span>
                                                    )
                                                }}
                                                onRow={(record) => {
                                                    return {
                                                        onClick: () => {
                                                            let selRows = [record.Task_ID];
                                                            setSelectedRowKeys([...selRows]);
                                                        },
                                                        onDoubleClick: (e) => {
                                                            handleTaskDetails(e, record);
                                                        }
                                                    }
                                                }}
                                            />
                                        }
                                    </Fragment>
                                }
                            </div>
                            {state.showTaskDetailsSlider &&
                                <TaskDetailsSlider showPane={state.showTaskDetailsSlider} Task_ID={state.Task_ID} updateTableDisplay={updateTableDisplay}
                                    selectedTaskId={state.selectedTaskId} tasks={state.tasks} selectedIdx={state.selectedIdx} hidePane={() => setState({ ...state, showTaskDetailsSlider: false })}
                                    parent='CustomerTasks' selectedCustomerId={props.selectedCustomerId} selectedCustomerName={props.selectedCustomerName} />
                            }

                        </div>
                        <div className="col-sm-2 col-md-2 col-lg-2 text-left">
                            <button className="btn btn-submit" onClick={handleAddNewTask}>Add New Task</button>
                        </div>
                    </div>
                }
            </Spin>  
        </div>
    );
}