import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import { useNavigate, useLocation } from "react-router-dom";
import { Select, Empty, Spin, Alert, message, Table, Input } from 'antd';
import Globals from '../../config/globals';
import DatePicker from 'react-datepicker';
import { TbUserCheck } from 'react-icons/tb';
import { GiJumpAcross } from 'react-icons/gi';
import { useForm, Controller } from "react-hook-form";

export default function QueryBuilderResults(props) {
    const navigate = useNavigate();
    const location = useLocation();

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const { register, getValues, setValue, control, watch } = useForm();

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);

    const [state, setState] = useState({
        customers: [],
        dataLoaded: false,
        showNames: '',
        searchValue: ''
    });

    const [loadingQuery, setLoadingQuery] = useState(true);

    const [queryResults, setQueryResults] = useState([]);
    const [filteredQueryResults, setfilteredQueryResults] = useState([]);

    useEffect(() => {
        setValue('formFields.query', location.state.query);

        setLoadingQuery(true);

        BuildSearchCriteriaDetailsStr();

        setQueryResults(location.state.resultSet);
        setfilteredQueryResults(location.state.resultSet);

        setState({
            ...state,
            customers: location.state.resultSet,
            query: location.state.query,
            searchRunning: false,
            dataLoaded: true
        });

        //console.log(myPage);
        //console.log(mySizePerPage);
    }, [props]);

    useEffect(() => {
        let myPage = 1;
        let mySizePerPage = 10;

        if (location.state?.page !== undefined) { myPage = location.state.page; }
        if (location.state?.sizePerPage !== undefined) { mySizePerPage = location.state.sizePerPage; }
        if (location.state?.selectedCustomerId !== undefined) { setValue('selectedCustomerId', location.state.selectedCustomerId); }

        if (location.state.selectedCustomerId !== undefined) {
            setValue('selectedCustomerId', location.state.selectedCustomerId);

            //this highlights the previously selected row in the table 
            let selRows = [location.state.selectedCustomerId];
            setSelectedRowKeys([...selRows]);
        }

        if (loadingQuery === false) {
            //console.log(state.currentPage);
            updatePageState(myPage, mySizePerPage); // This is be executed when `loadingQuery` state changes
        }
    }, [loadingQuery])

    function BuildSearchCriteriaDetailsStr() {
        let mySearchCriteriaDetailsStr = "";
        let mySearchCriteria1 = "";

        if (getValues().formFields.query.type === "E") {
            mySearchCriteriaDetailsStr += "Query Type --> Exhibitors<br />";
        }
        else {
            mySearchCriteriaDetailsStr += "Query Type --> Prospects<br />";
        }

        mySearchCriteriaDetailsStr += "Contact Type --> " + getValues().formFields.query.contactType + "<br />";

        if (getValues().formFields.query.cmbSearchField1 !== 'Select Field') {
            mySearchCriteriaDetailsStr += getValues().formFields.query.cmbSearchField1 + " --> " + getValues().formFields.query.cmbOperator1 + " --> " + getValues().formFields.query.txtSearchCriteria1 + "<br />";
        }
        if (getValues().formFields.query.cmbSearchField2 !== 'Select Field') {
            mySearchCriteriaDetailsStr += getValues().formFields.query.cmbSearchField2 + " --> " + getValues().formFields.query.cmbOperator2 + " --> " + getValues().formFields.query.txtSearchCriteria2 + "<br />";
        }
        if (getValues().formFields.query.cmbSearchField3 !== 'Select Field') {
            mySearchCriteriaDetailsStr += getValues().formFields.query.cmbSearchField3 + " --> " + getValues().formFields.query.cmbOperator3 + " --> " + getValues().formFields.query.txtSearchCriteria3 + "<br />";
        }
        if (getValues().formFields.query.cmbSearchField4 !== 'Select Field') {
            mySearchCriteriaDetailsStr += getValues().formFields.query.cmbSearchField4 + " --> " + getValues().formFields.query.cmbOperator4 + " --> " + getValues().formFields.query.txtSearchCriteria4 + "<br />";
        }
        if (getValues().formFields.query.cmbSearchField5 !== 'Select Field') {
            mySearchCriteriaDetailsStr += getValues().formFields.query.cmbSearchField5 + " --> " + getValues().formFields.query.cmbOperator5 + " --> " + getValues().formFields.query.txtSearchCriteria5 + "<br />";
        }
        if (getValues().formFields.query.cmbSearchField6 !== 'Select Field') {
            mySearchCriteriaDetailsStr += getValues().formFields.query.cmbSearchField6 + " --> " + getValues().formFields.query.cmbOperator6 + " --> " + getValues().formFields.query.txtSearchCriteria6 + "<br />";
        }

        if (getValues().formFields.query.DataFields !== "") {
            mySearchCriteriaDetailsStr += "Selected Output Fields --> " + getValues().formFields.query.dataFields.replaceAll("|", ", ") + "<br />";
        }

        var searchCriteriaContent = <div dangerouslySetInnerHTML={{ __html: mySearchCriteriaDetailsStr }} />

        setValue('formFields.searchCriteria', searchCriteriaContent);

        setState({
            ...state
        });

        setLoadingQuery(false);
    }

    function handleJumpTo(e, record, index) {
        navigate("/customerview", { state: { selectedCustomerId: record.id, callingComponent: 'QueryBuilder', query: location.state.query, resultSet: queryResults, page: state.currentPage, sizePerPage: state.currentPageSize } });
    }

    const columns = [
        {
            dataIndex: 'id',
            title: 'ID',
            key: 'id',
            width: 100
        }, {
            dataIndex: 'name',
            title: 'Company',
            key: 'name'
        }, {
            dataIndex: 'street1',
            title: 'Address',
            key: 'street1',
            sorter: (a, b) => a.street1.localeCompare(b.street1)
        }, {
            dataIndex: 'city',
            title: 'City',
            key: 'city',
            sorter: (a, b) => a.city.localeCompare(b.city)
        }, {
            dataIndex: 'state',
            title: 'State',
            key: 'state',
            width: 120,
            sorter: (a, b) => a.state.localeCompare(b.state)
        }, {
            dataIndex: 'exhibitor',
            title: 'Type',
            key: 'exhibitor',
            width: 130,
            align: 'center',
            sorter: (a, b) => a.exhibitor - b.exhibitor,
            render: (exhibitor) => {
                return (
                    <div>
                        {exhibitor ? 'Exhibitor' : 'Prospect'}
                    </div>
                );
            }
        }, {
            title: 'Jump To',
            key: 'action',
            width: 120,
            render: (text, record, index) =>
                <div id="jumpTo" className="hover"><TbUserCheck id="jumpTo" className="fa-1x text-icon" onClick={(e) => handleJumpTo(e, record, index)} /></div>
            ,
            align: 'center'
        }
    ].filter(item => !item.hidden);

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    function returnToQueryBuilder() {
        navigate("/querybuilder", { state: { querytopreload: location.state.query } });
    }

    function exportToCSVFile() {
        var myFileName;

        const key = "loading";

        message.loading({
            content: 'CSV Export in progress...',
            key,
            duration: 0,
            style: {
                position: "fixed",
                bottom: 0,
                left: 0
            },
        });

        //Load postdata from query object sent in
        let postdata = getPostData();
        console.log(postdata);

        myFileName = "JSS_QueryBuilder_Search_Results";
        Axios.post(`/api/DownloadQueryBuilderSearchResults_CSV`, postdata, {
            responseType: 'blob'
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = myFileName + ".csv";
            a.click();
            window.URL.revokeObjectURL(url);

            message.destroy(key);

        }).catch(error => {
            console.log(error);
        });
    }

    function getPostData() {
        let myQuery = location.state.query;

        let postdata = {};

        postdata.userId = userInfo.userId;
        postdata.type = myQuery.type;

        if (myQuery.id === null) {
            postdata.id = 0;
        }
        else {
            postdata.id = myQuery.id;
        }

        postdata.name = myQuery.name;

        postdata.txtSearchCriteria1 = myQuery.txtSearchCriteria1;
        postdata.txtSearchCriteria2 = myQuery.txtSearchCriteria2;
        postdata.txtSearchCriteria3 = myQuery.txtSearchCriteria3;
        postdata.txtSearchCriteria4 = myQuery.txtSearchCriteria4;
        postdata.txtSearchCriteria5 = myQuery.txtSearchCriteria5;
        postdata.txtSearchCriteria6 = myQuery.txtSearchCriteria6;

        postdata.cmbSearchField1 = myQuery.cmbSearchField1;
        postdata.cmbSearchField2 = myQuery.cmbSearchField2;
        postdata.cmbSearchField3 = myQuery.cmbSearchField3;
        postdata.cmbSearchField4 = myQuery.cmbSearchField4;
        postdata.cmbSearchField5 = myQuery.cmbSearchField5;
        postdata.cmbSearchField6 = myQuery.cmbSearchField6;

        postdata.cmbOperator1 = myQuery.cmbOperator1;
        postdata.cmbOperator2 = myQuery.cmbOperator2;
        postdata.cmbOperator3 = myQuery.cmbOperator3;
        postdata.cmbOperator4 = myQuery.cmbOperator4;
        postdata.cmbOperator5 = myQuery.cmbOperator5;
        postdata.cmbOperator6 = myQuery.cmbOperator6;

        postdata.dedupOnCompanyId = myQuery.dedupOnCompanyId;
        postdata.useLogicalOrForState = myQuery.useLogicalOrForState;
        postdata.useLogicalOrForSource = myQuery.useLogicalOrForSource;

        postdata.contactType = myQuery.contactType;

        postdata.dataFields = myQuery.dataFields;

        return postdata;
    }

    const { Option } = Select;

    function updatePageState(page, pageSize) {
        setState({
            ...state,
            currentPage: page,
            currentPageSize: pageSize
        });
    }

    function searchResults(value) {
        let searchMatches = [];
        var currCustomer;
        var valueCleared = false;
        for (let i = 0; i < queryResults.length; i++) {
            currCustomer = queryResults[i];

            console.log(currCustomer);

            if (value !== '') {
                if ((currCustomer.id !== null && currCustomer.id.toString().includes(value.toLowerCase())) ||
                    (currCustomer.name !== null && currCustomer.name.toLowerCase().includes(value.toLowerCase())) ||
                    (currCustomer.street1 !== null && currCustomer.street1.toLowerCase().includes(value.toLowerCase())) ||
                    (currCustomer.city !== null && currCustomer.city.toLowerCase().includes(value.toLowerCase())) ||
                    (currCustomer.state !== null && currCustomer.state.includes(value))) {

                    searchMatches = [...searchMatches, queryResults[i]];
                }
            }
            else {
                valueCleared = true;
            }
        }

        setfilteredQueryResults(valueCleared ? [...queryResults] : [...searchMatches]);
    }

    function searchValueChanged(e) {
        setState({
            ...state,
            searchValue: e.target.value
        });
    }

    return (
        <div className="row">
            <div className="form-group col-9">
                <h3 className="frame-heading">
                    Query Builder Search Results
                </h3>

                <label className="form-label">{getValues().formFields?.searchCriteria}</label>
            </div>
            <div className="col-3" style={{ display: 'flex', alignItems: 'flex-end'}}>
                <Button className="btn btn-submit" onClick={returnToQueryBuilder}>Back to Query Builder</Button>
                <Button className="btn btn-submit ms-3" onClick={exportToCSVFile}>Export To CSV File</Button>
            </div>
            <div className="form-group col-lg-12 col-12">
                <hr />
                <Spin spinning={state.searchRunning}>
                    <div style={state.searchRunning ? {} : { display: 'none' }}>
                        <Alert
                            message="Loading Companies..."
                            description="Please stand by while we retrieve companies that match your selected criteria"
                            type="info"
                        />
                    </div>
                    {!state.searchRunning &&
                        <div className="row">
                            {state.customers?.length === 0 ?
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                    <span>
                                        {state.hasSearchBeenRunYet === false ? "Search for Exhibitors and Exhibitor Prospects using the search criteria above" : "No companies found matching your search criteria"}
                                    </span>
                                } />
                                :
                                <>
                                    <div className="col-sm-11 col-md-11 col-lg-12">
                                        <Input.Search
                                            style={{ paddingTop: "20px", margin: "0 0 10px 0", width: "50%" }}
                                            placeholder="Search query results..."
                                            enterButton
                                            onSearch={searchResults}
                                            allowClear
                                            value={state.searchValue}
                                            onChange={searchValueChanged}
                                        />
                                    </div>
                                    <div className="fullTable form-group col-lg-12">
                                        <Table className="custom-ant-selection"
                                            rowKey={item => item.id}
                                            rowSelection={rowSelection}
                                            hideSelectionColumn={true}
                                            bordered
                                            dataSource={filteredQueryResults}
                                            columns={columns}
                                            pagination={{
                                                defaultPageSize: 25,
                                                showSizeChanger: true,
                                                pageSizeOptions: ['10', '25', '50', '100'],
                                                showTotal: (total, range) => (
                                                    <span className="color-dark-blue" style={{ left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" }}>
                                                        Showing {range[0]}-{range[1]} of {total}
                                                    </span>
                                                ),
                                                onChange: (page, pageSize) => {
                                                    updatePageState(page, pageSize);
                                                },
                                                current: state.currentPage,
                                                pageSize: state.currentPageSize
                                            }}
                                            onRow={(record, index) => {
                                                return {
                                                    onClick: () => {
                                                        let selRows = [record.id];
                                                        setSelectedRowKeys([...selRows]);
                                                    },
                                                    onDoubleClick: (e) => {
                                                        handleJumpTo(e, record, index);
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                </>
                            }
                        </div>
                    }
                </Spin>
            </div>
        </div>
    );
}