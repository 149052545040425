import React, { useEffect, useState } from 'react';
import { Layout, Tabs } from 'antd';
import UserProfileManager from './UserProfileManager';
import EmailManager from './EmailManager';
import ManageWorkflow from './ManageWorkflow';
import ManageAdminReviewQueue from './ManageAdminReviewQueue';
import { getAdminManageItems, availableUsersState, queueUsersState, workflowItemsState } from '../../services/AdminService';
import { useSetRecoilState, useRecoilValue } from 'recoil';

export default function ManagementDashboard(props) {
    const setAvailableUsers = useSetRecoilState(availableUsersState);
    const setQueueUsers = useSetRecoilState(queueUsersState);
    const setWorkflowItems = useSetRecoilState(workflowItemsState);
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const [state, setState] = useState({

    });

    const { TabPane } = Tabs;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getAdminManageItems().then(data => {
            setLoading(false);
            setAvailableUsers(data.availableUsers);
            setQueueUsers(data.queueUsers);
            setWorkflowItems(data.allWorkflowItems);
        });
    }, [props]);

    return (
        <div className="row">
            <div className="form-group col-lg-2 col-12">
                <h3 className="frame-heading">
                    Management Dashboard
                </h3>
            </div>
            <div className="form-group col-lg-2 col-12"></div>

            <div className="col-lg-12">
                <Tabs defaultActiveKey='tab-notes' tabPosition='top' type='card' destroyInactiveTabPane={true}>
                    <TabPane tab='User Profiles' key='0'>
                        <UserProfileManager />
                    </TabPane>
                    <TabPane tab='Email Manager' key='1'>
                        <EmailManager reload={true} />
                    </TabPane>
                    <TabPane tab='Workflow Manager' key='2'>
                        <ManageWorkflow loading={loading} />
                    </TabPane>
                    <TabPane tab='Manage Administrative Review Queue' key='3'>
                        <ManageAdminReviewQueue />
                    </TabPane>
                </Tabs>
            </div>
        </div>

        //<Layout>
        //    <Tabs tabPosition='left'>
        //        <TabPane tab='User Profile Manager' key='tab-user-profile-manager'>
        //            <UserProfileManager />
        //        </TabPane>
        //        <TabPane tab='Email Template Manager' key='tab-other'>
        //            <EmailTemplateManager />
        //        </TabPane>
        //    </Tabs>
        //</Layout>
    );
}