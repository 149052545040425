import React, { useState, useEffect, useRef, useCallback, Fragment } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { useForm, Controller } from "react-hook-form";
import JoditEditor, { Jodit } from 'jodit-react';
import { toast, confirm } from '@rickylandino/react-messages';
import { getRepEmailTemplates, deleteRepEmailTmplate, addRepEmailTemplate, updateRepEmailTemplate } from '../../services/RepEmailTemplateService';
import { useDropzone } from 'react-dropzone';
import { UploadOutlined } from '@ant-design/icons';
import { Select, Upload, Button, message } from 'antd';
import Axios from '../../config/axios';
import { sendRepEmail } from '../../services/EmailSenderService';
import { insertCustomerNote } from '../../services/NotesService';
import { EmailValidator } from 'node-email-validation';
import { convertHtmlToText } from '../Helpers/ManipulateHTML';
import { useRecoilState } from 'recoil';
import { persistentEmails, insertPersistentEmail, updatePersistentEmail, deletePersistentEmailAndAttachments } from '../../services/PersistEmailService';
import { persistentNotes } from '../../services/PersistNotes';
import { persistentFilteredNotes } from '../../services/PersistNotes';
import { notesFilterType } from '../../services/PersistNotes';

export default function SendEmailSlider(props) {
    const [state, setState] = useState({
        showPane: false,
        dataLoaded: false
    });

    const { Option } = Select;
    const editor = useRef(null);
    const [content, setContent] = useState('');

    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [templateDropdown, setTemplateDropdown] = useState(props?.templates?.filter(t => t.templateType === 'email') || []);
    const [emailTemplates, setEmailTemplates] = useState([]);
    const [emailSignatures, setEmailSignatures] = useState([]);
    const [editorSignatures, setEditorSignatures] = useState({});
    const [editorMergeFields, setEditorMergeFields] = useState([]);
    const [file, setFile] = useState(null);
    const [files, setFiles] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [emailSenderInstanceId, setEmailSenderInstanceId] = useState(Date.now());

    const [localPersistentEmails, setPersistentEmails] = useRecoilState(persistentEmails);

    const [localPersistentNotes, setPersistentNotes] = useRecoilState(persistentNotes);
    const [localPersistentFilteredNotes, setPersistentFilteredNotes] = useRecoilState(persistentFilteredNotes);
    const [localNotesFilterType, setNotesFilterType] = useRecoilState(notesFilterType);

    const { register, getValues, setValue, control, watch } = useForm();
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
    
    //useEffect(() => {
    //    setState({
    //        ...state,
    //        showPane: props.showPane,
    //        dataLoaded: true
    //    });

    //}, [props]);

    useEffect(() => {
        if (props.showPane) {
            setState({
                ...state,
                dataLoaded: false
            });

            Axios.get(`/api/InitializeEmailSender`, {
                params: {
                    ownedby: userInfo.userId,
                    division: userInfo.jobShopDivision
                }
            }).then(response => {
                let templates = [];
                let signatures = [];

                setValue('repDocuments', response.data.repDocuments);

                for (var i = 0; i < response.data.templates.length; i++) {
                    if (response.data.templates[i].templateType === 'email') {
                        if (response.data.templates[i].shared === true && response.data.templates[i].ownedBy !== userInfo.userId) {
                            response.data.templates[i].templateName += ' (shared by ' + response.data.templates[i].ownedBy + ')';
                        }
                        templates.push(response.data.templates[i]);
                    }
                    else {
                        signatures.push(response.data.templates[i]);
                    }
                }

                setEmailTemplates(templates);
                setEmailSignatures(signatures);

                //load email signatures for editor dropdown
                let editorSignatures = {};
                signatures.filter(et => et.templateType === 'signature')
                    .forEach(et => {
                        editorSignatures[et.repEmailTemplateLibrary_ID] = et.templateName
                    });
                setEditorSignatures(editorSignatures);

                //load merge fields for editor dropdown
                let editorMergeFields = ["Contact Name", "Company Name"];
                setEditorMergeFields(editorMergeFields);

                setTemplateDropdown(templates);

                setState({
                    ...state,
                    dataLoaded: true
                });
            }).catch(error => {
                console.log(error);
            });
        }
    }, [props.showPane]);

    useEffect(() => {
        if (state.dataLoaded === true) {
            if (props.emailInformation.id !== '') {
                let fileList = [];
                props.emailInformation.fileList.forEach(file => {
                    fileList.push(new File([file.documentData], file.name, {
                        type: "application/pdf"
                    }));
                });
                
                var saveToNotes = props.emailInformation.saveToNotes === true ? true : (props.emailInformation.saveToNotes === false) ? false : true;
                var saveToImap = props.emailInformation.saveToImap === true ? true : (props.emailInformation.saveToImap === false) ? false : true;
                
                if (props.emailInformation?.templateName) {

                    let foundTemplate = templateDropdown.find(t => t.templateName === props.emailInformation.templateName);
                    setSelectedTemplate(foundTemplate);
                }

                setValue('templateName', props.emailInformation.templateName);
                setValue('sendTo', props.emailInformation.sendTo || props.selectedContact.email);
                setValue('sendCc', props.emailInformation.sendCc || '');
                setValue('emailSubject', props.emailInformation.emailSubject || '');
                setValue('saveToNotes', saveToNotes);
                setValue('saveToImap', saveToImap);
                setValue('selectedRepDocuments', props.emailInformation?.repDocumentsToUpdate?.map(rd => rd.repDocumentLibrary_ID) || []);
                setContent(props.emailInformation.emailContent || '');
                setFileList(fileList);
            } else {
                addPersistentEmailInstance();

                //setTemplateDropdown(emailTemplates);
                setSelectedTemplate(null);
                //setValue('selectedTemplateText', null);
                setValue('templateName', '');
                setValue('sendTo', props.emailInformation.selectedContact.email);
                setValue('emailSubject', '');
                setValue('saveToNotes', true);
                setValue('saveToImap', true);
                setContent('');
            }

            setState({
                ...state,
                showPane: true
            });
        }
    }, [state.dataLoaded]);


    function addPersistentEmailInstance() {

        var persistentEmailCopy = [...localPersistentEmails];

        var newPersistentEmail = {
            id: emailSenderInstanceId.toString(),
            sendTo: props.emailInformation.selectedContact?.email,
            selectedCustomer: props.emailInformation.selectedCustomer,
            selectedContact: props.emailInformation.selectedContact,
            selectedCustomerId: props.emailInformation.selectedCustomer.id,
            selectedContactId: props.emailInformation.selectedContact.contact_id,
            userId: userInfo.userId
        }

        persistentEmailCopy.push(newPersistentEmail);
        
        setPersistentEmails(persistentEmailCopy);

        //TODO - Add newPeristentEmail to db
        insertPersistentEmail(newPersistentEmail);
    }

    function updatePersistentEmailInstance() {
        var persistentEmailCopy = [...localPersistentEmails];
        let idToCheckAgainst = props.emailInformation?.id || emailSenderInstanceId.toString();

        let idx = persistentEmailCopy.findIndex(item => item.id === idToCheckAgainst);

        let tempObj = { ...persistentEmailCopy[idx] };

        let repDocumentsToUpdate = [];

        if (getValues().selectedRepDocuments?.length > 0) {
            getValues().selectedRepDocuments.forEach(id => {
                var item = getValues().repDocuments.find(rd => rd.repDocumentLibrary_ID === id);
                repDocumentsToUpdate.push(item);
            });
        }
        
        tempObj = {
            ...tempObj,
            ...getValues(),
            fileList: [...fileList],
            emailContent: content,
            repDocumentsToUpdate
        };
        
        persistentEmailCopy[idx] = tempObj;
        setPersistentEmails(persistentEmailCopy);
        updatePersistentEmail(tempObj);
        
    }

    function removePersistentEmailAndHidePane() {
        let persistentEmailCopy = [...localPersistentEmails];

        let idToCheckAgainst = props.emailInformation?.id || emailSenderInstanceId.toString();
        let idx = persistentEmailCopy.findIndex(item => item.id === idToCheckAgainst);

        let persistentEmailToRemove = persistentEmailCopy[idx];
        deletePersistentEmailAndAttachments(persistentEmailToRemove);

        persistentEmailCopy.splice(idx, 1);
        setPersistentEmails(persistentEmailCopy);

        setState({
            ...state,
            showPane: false,
            dataLoaded: false
        });

        setTimeout(() => {
            props.hidePane();
        }, 1000);
    }

    function hidePane() {
        setState({
            ...state,
            showPane: false,
            dataLoaded: false
        });
        
        setTimeout(() => {
            props.hidePane();
        }, 1000);

        updatePersistentEmailInstance();
    }

    function PopulateMergeFields(templateContent) {
        let updatedTemplateContent = templateContent;

        if (props.emailInformation) {
            updatedTemplateContent = updatedTemplateContent.replace("[[Contact Name]]", props.emailInformation.selectedContact?.jsS_name);
            updatedTemplateContent = updatedTemplateContent.replace("[[Company Name]]", props.emailInformation.selectedCustomer?.name);
        } else {
            updatedTemplateContent = updatedTemplateContent.replace("[[Contact Name]]", props.emailInformation.selectedContact?.jsS_name);
            updatedTemplateContent = updatedTemplateContent.replace("[[Company Name]]", props.emailInformation.selectedCustomer?.name);
        }
        
        return updatedTemplateContent;
    }

    function DropInMergeField(mergeField) {
        var returnField;

        switch (mergeField) {
            case 'Contact Name':
                returnField = props.selectedContact.jsS_name;
                break;
            case 'Company Name':
                returnField = props.selectedCustomer.name;
                break;
        }

        return returnField;
    }

    function selectTemplate(e) {
        e.preventDefault();
        let target = e.target;
        let name = target.name;
        let value = target.value;

        if (value) {
            let foundTemplate = templateDropdown.find(t => t.repEmailTemplateLibrary_ID === parseInt(value));
            
            if (foundTemplate) {
                setSelectedTemplate(foundTemplate);
                //setValue('selectedTemplateText', foundTemplate.repEmailTemplateLibrary_ID);
                setValue('templateName', foundTemplate.templateName);
                setValue('emailSubject', foundTemplate.templateSubject);
                setContent(PopulateMergeFields(foundTemplate.stringContent));
            } else {
                setSelectedTemplate(null);
                //setValue('selectedTemplateText', null);
                setValue('templateName', '');
                setValue('emailSubject', '');
                setContent('');
            }
        } else {
            setSelectedTemplate(null);
            //setValue('selectedTemplateText', null);
            setValue('templateName', '');
            setValue('emailSubject', '');
            setContent('');
        }
    }

    function handleSendEmail() {
        if (getValues().sendTo.trim() === '' || getValues().emailSubject.trim() === '' || content.trim() === '') {
            toast.error("Email To, Subject and Body Content are required fields");

            return;
        }

        // Validate list of sendTo and sendCC email addresses
        var validator = require("node-email-validation");
        var splitList;

        //Validate list of sendTo email addresses
        if (getValues().sendTo.includes(";")) {
            splitList = getValues().sendTo.split(';');
        }
        else if (getValues().sendTo.includes(",")) {
            splitList = getValues().sendTo.split(',');
        }
        if (splitList === undefined) {
            if (validator.is_email_valid(getValues().sendTo) === false) {
                toast.error("Invalid format found for 'To' address");
                return;
            }
        }
        else {
            for (let i = 0; i < splitList.length; i++) {
                if (validator.is_email_valid(splitList[i].trim()) === false) {
                    toast.error("Invalid format found for 'To' addresses");
                    return;
                }
            }
        }

        //Validate list of sendCc email addresses
        if (getValues().sendCc.trim() !== '') {
            if (getValues().sendCc.includes(";")) {
                splitList = getValues().sendCc.split(';');
            }
            else if (getValues().sendCc.includes(",")) {
                splitList = getValues().sendCc.split(',');
            }
            if (splitList === undefined) {
                if (validator.is_email_valid(getValues().sendCc) === false) {
                    toast.error("Invalid format found for 'Cc' address");
                    return;
                }
            }
            else {
                for (let i = 0; i < splitList.length; i++) {
                    if (validator.is_email_valid(splitList[i].trim()) === false) {
                        toast.error("Invalid format found for 'Cc' addresses");
                        return;
                    }
                }
            }
        }

        const key = "sendEmail";
        message.loading({
            content: 'Sending Email...',
            key,
            duration: 0,
            style: {
                position: "fixed",
                bottom: 0,
                left: 0
            },
        });

        sendRepEmail(userInfo.userId, getValues().sendTo, getValues().sendCc, getValues().emailSubject, content, getValues().selectedRepDocuments, fileList, getValues().saveToImap).then(data => {
            if (getValues().saveToNotes === true) {
                var customerId;
                if (props.emailInformation) {
                    customerId = props.emailInformation.selectedCustomer.id;
                }
                else {
                    customerId = props.selectedCustomer.id;
                }

                var note = "";
                if (content.includes("<div id=\"signature\"")) {
                    note = content.substring(0, content.indexOf("<div id=\"signature\""));
                }
                else {
                    note = content;
                }

                note = convertHtmlToText(note);

                var note_date = new Date();
                var division = userInfo.jobShopDivision;
                var userId = userInfo.userId;
                var pinned = false;

                insertCustomerNote(customerId, note, note_date, division, userId, pinned);
                getCustomerNotes(customerId, userInfo.jobShopDivision, userInfo.userid, 'allDivisionNotes');
            }

            message.destroy(key);

            if (data.includes("Success:")) {
                toast.success(data.replace("Success:", ""));
                removePersistentEmailAndHidePane();
                //hidePane();
            }
            else if (data.includes("Error:")) {
                toast.error(data.replace("Error:", ""));
            }
            else {
                toast.info(data.replace("Info:", ""));
                hidePane();
            }

        }).catch(error => {
            toast.error("Problem encountered sending email");
        });
    }

    function getCustomerNotes(id, division, user, notesfiltertype) {
        let postdata = {};
        postdata.userid = user;
        postdata.company_id = id;
        postdata.jobshopdivision = division;
        postdata.notesfiltertype = notesfiltertype;

        Axios.post(`/api/GetCustomerNotes`, postdata
        ).then(response => {
            const notes = response.data.displayList;

            setPersistentNotes(notes);
            setPersistentFilteredNotes(notes);
        }).catch(error => {
            console.log(error);
        });
    }

    function handleDocLibraryAttachmentsChange(value) {
        //TODO
        setValue('selectedRepDocuments', value);
        setState({ ...state });
    }

    function handleSavetoNotesChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        //TODO
        setValue('saveToNotes', value);

        //setState({
        //    ...state,
        //});
    }

    function handleSavetoImapChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        //TODO
        setValue('saveToImap', value);

        setState({
            ...state,
        });
    }

    function removePersistentEmail() {
        confirm({
            title: "You are about to permanently delete this staged email",
            content: "Are you sure you would like continue?",
            buttons: ["Yes", "No"],
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                let persistentEmailCopy = [...localPersistentEmails];

                let idToCheckAgainst = props.emailInformation?.id || emailSenderInstanceId.toString();
                let idx = persistentEmailCopy.findIndex(item => item.id === idToCheckAgainst);

                let persistentEmailToRemove = persistentEmailCopy[idx];
                deletePersistentEmailAndAttachments(persistentEmailToRemove);

                persistentEmailCopy.splice(idx, 1);
                setPersistentEmails(persistentEmailCopy);

                setState({
                    ...state,
                    showPane: false,
                    dataLoaded: false
                });

                setTimeout(() => {
                    props.hidePane();
                }, 1000);

                return 0;
            } else {
                return 0;
            }
        });
    }

    var joditButtons = [...Jodit.defaultOptions.buttons];
    joditButtons.push({
        name: 'Signatures',
        list: editorSignatures,
        exec: (ed, nodeOrFalse, control, origEvent, btn) => {
            let foundTemp = emailSignatures?.find(es => es.repEmailTemplateLibrary_ID === parseInt(control.control.name));
            if (foundTemp) {
                ed.s.insertHTML("<div id=\"signature\"" + foundTemp.stringContent + "</div>");
            }
        }
    });
    joditButtons.push({
        name: 'Merge Fields',
        list: editorMergeFields,
        exec: (ed, nodeOrFalse, control, origEvent, btn) => {
            let foundField = editorMergeFields?.find(emf => emf === control.control.name);
            if (foundField) {
                ed.s.insertHTML(DropInMergeField(control.control.name));
            }
        }
    });

    
    const fileprops = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            const isPDF = file.type === 'application/pdf';
            if (!isPDF) {
                toast.error(`${file.name} is not a PDF file. Only PDF files are allowed`);
                return false;
            }
            const newFileList = [...fileList, file];
            setFileList(newFileList);

            return false;
        },
        fileList,
    };

    return (
        <SlidingPane
            className='some-custom-class w-65'
            overlayClassName='showCard'
            isOpen={state.showPane}
            title='D2P Web CM Email Sender'
            onRequestClose={hidePane}
            width={'90%'}
        >
            <div className="slide-pane-body scrollableDiv">
                <div>
                    {state.dataLoaded &&
                        <Fragment>
                            <div className="row">
                                <div className="form-group col-4">
                                    <label>Select Template</label>
                                    <select className="ant-input" onChange={selectTemplate} value={selectedTemplate?.repEmailTemplateLibrary_ID}>
                                        <option value={null}>{'<Free Form Text>'}</option>
                                        {templateDropdown.map((t, idx) => <option key={idx} value={t.repEmailTemplateLibrary_ID}>{t.templateName}</option>)}
                                    </select>
                                </div>
                                <div className="form-group col-lg-8"></div>

                                <div className="form-group col-6">
                                    <label>To:</label>
                                    <input className="ant-input" {...register('sendTo')} />
                                </div>

                                <div className="form-group col-6">
                                    <label>Cc:</label>
                                    <input className="ant-input" {...register('sendCc')} />
                                </div>

                                <div className="form-group col-lg-12">
                                    <label>Email Subject</label>
                                    <input className="ant-input" {...register('emailSubject')} />
                                </div>

                                <div className="form-group col-lg-6">
                                    <label>Document Library Attachments</label>
                                    <Select id="ddDocLibraryDocs"
                                        value={getValues().selectedRepDocuments}
                                        className="form-control-custom"
                                        mode="multiple"
                                        placeholder="Select one or more documents from your document library"
                                        onChange={(value) => handleDocLibraryAttachmentsChange(value)}
                                        className="form-control-custom d-inline-block"
                                        bordered={false}
                                        style={{ width: '100%', marginTop: 7 }}
                                    >
                                        {getValues().repDocuments?.map((doc) => <Option key={doc.repDocumentLibrary_ID} value={doc.repDocumentLibrary_ID}>{doc.documentName} {doc.ownedBy === userInfo.userId ? '' : ' - Shared By ' + doc.ownedBy}</Option>)}
                                    </Select>
                                </div>
                                <div className="form-group col-lg-6">
                                    <label>Other File Attachments</label>
                                    <Upload {...fileprops} className="custom-upload row" accept=".pdf">
                                        <Button icon={<UploadOutlined />}>Click to Attach</Button>
                                    </Upload>
                                </div>
                                <div className="col col-12">
                                    <JoditEditor
                                        ref={editor}
                                        value={content}
                                        tabIndex={1} // tabIndex of textarea
                                        onBlur={(newContent) => { setContent(newContent); }} // preferred to use only this option to update the content for performance reasons
                                        onChange={(newContent) => { }}
                                        config={{
                                            height: "450",
                                            allowResizeY: true,
                                            uploader: {
                                                insertImageAsBase64URI: true
                                            },
                                            buttons: joditButtons,
                                            removeButtons: ['source', 'about', 'video', 'file', 'fullsize', 'preview', 'image'],
                                        }}
                                    />
                                </div>
                            </div>
                            {/*<div class="align-middle">*/}
                                <div className="row container">
                                    <div className="col col-2 mt-3">
                                        <button className="ant-btn ant-btn-primary" onClick={handleSendEmail}>Send Email</button>
                                    </div>
                                    <div className="col col-2">
                                        <div className="custom-control custom-checkbox mt-4">
                                            <input type="checkbox" className="custom-control-input" name="saveToNotes" {...register("saveToNotes")} onChange={handleSavetoNotesChange} />
                                            <label className="custom-control-label">Save To Notes</label>
                                        </div>
                                    </div>
                                    <div className="col col-3">
                                        <div className="custom-control custom-checkbox mt-4">
                                            <input type="checkbox" className="custom-control-input" name="saveToImap" {...register("saveToImap")} checked={getValues().saveToImap} onChange={handleSavetoImapChange} />
                                            <label className="custom-control-label">Save To Outlook Sent Folder</label>
                                        </div>
                                    </div>
                                    <div className="col col-5 mt-3 text-end">
                                        <button className="ant-btn ant-btn-danger float-right m-1" onClick={removePersistentEmail}><i className='far fa-trash-alt'></i> &nbsp; Delete this Email</button>
                                    </div>
                                </div>

                            {/*</div>*/}
                        </Fragment>
                    }
                </div>
            </div>

            <div className="modal-footer">
                <div className="form-group col-12 padding-25-10">
                    <button className="btn btn-outline-primary margin-left-15 float-right" onClick={hidePane}>Close</button>
                </div>
            </div>

        </SlidingPane>
    );
}