import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import cookie from 'react-cookies';
import Axios from '../../config/axios';
import { toast } from '@rickylandino/react-messages';
import './login.css';

export default function TFALogin(props) {
    const [state, setState] = useState({
        formFields: {
            tfaInput: '',
            trustdevice: false,
        }
    });

    useEffect(() => {
        //console.log(props.userid);
        //console.log(props.password);

    }, [props]);

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setState({ ...state, formFields: { ...state.formFields, [name]: value } });
    }

    async function verify2FA(event) {
        event.preventDefault();
        let validCode = false;

        let postdata = {};
        postdata.userid = props.userid.toString();
        postdata.password = state.formFields.tfaInput;

        await Axios.post(`/api/Verify2FA`, postdata
        ).then(response => {
            validCode = response.data;
        }).catch(error => {
            console.log(error);
        });

        if (validCode) {
            //console.log(state.formFields.trustdevice);

            //const timeInSeconds = 3600;  //1 hour
            const timeInSeconds = 86400;   //24 hours
            //const timeInSeconds = 604800;   //7 days

            const expires = new Date();
            expires.setDate(Date.now() + timeInSeconds * 60 * 60 * 24 * 14);
            if (state.formFields.trustdevice === true) {
                cookie.save('bypass2FA' + props.userid, true, { path: '/', expires, maxAge: timeInSeconds });
            }
            props.validateUser(props.userid, props.password);
        }
        else {
            toast.error("Invalid 2FA code entered. Enter the security code from the authenticator app on your mobile device");
        }
    }

    function goLogin() {
        props.goLogin();

        return;
    }

    return (
        <div style={{ maxWidth: '400px' }}>
            <form>
                <div className="row align-items-center justify-content-center">
                    Enter Code From Your Mobile Device
                </div>
                <div className="row">&nbsp;</div>
                <div className="row form-group">
                    <label className="form-label">Enter the security code from the authenticator app on your mobile device</label>
                    <input autoFocus type="text" id="tfaInput" className="form-control-custom" name="tfaInput" value={state.formFields.tfaInput} onChange={handleInputChange} />
                </div>
                    
                <div style={{ textAlign: "left" }}>
                    <label className="checkbox form-label">
                        <input type="checkbox" name="trustdevice" id="trustdevice" value={state.formFields.trustdevice} onChange={handleInputChange} />
                        &nbsp;Trust this device. Security code will not be requested any more on this device.
                    </label>
                </div>

                <div className="row">&nbsp;</div>

                <button onClick={(e) => { verify2FA(e); }} className="btn btn-submit">
                    Submit
                </button>

                <hr />

                <div className="row goBackLogin">
                    <a onClick={goLogin}><i className="fa fa-arrow-left"></i> Go back to login</a>
                </div>
                <br />
                {state.validUserFound === false &&
                    <div>
                        <span style={{ color: 'red' }}><h5>Invalid User Credentials</h5></span>
                    </div>
                }

            </form>
        </div>
    );
}