import Axios from '../config/axios';
import { atom } from 'recoil';

export const availableUsersState = atom({
    key: 'availableUsersState',
    default: []
});

export const queueUsersState = atom({
    key: 'queueUsersState',
    default: []
});

export const workflowItemsState = atom({
    key: 'workflowItemsState',
    default: []
});

export function getMailingLists() {
  return Axios.get('/api/GetMailingLists').then(response => response.data).catch(error => error);
}

export function getContactMailingListItems(custNum, conNum, epicorCompany) {
    return Axios.get(`/api/GetContactMailingListItems`, {
        params: {
            custNum: custNum,
            conNum: conNum,
            epicorCompany: epicorCompany,
        }
    })
    .then(response => response.data)
    .catch(error => error);
}

export function createMailingList(postdata) {
  return Axios.post('/api/CreateMailingList', postdata).then(response => response.data).catch(error => error);
}

export function updateMailingList(postdata) {
  return Axios.post('/api/UpdateMailingList', postdata).then(response => response.data).catch(error => error);
}

export function exportMailingList(postdata) {
  return Axios.post('/api/ExportMailingList', postdata, { responseType: 'blob' })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
      const a = document.createElement('a');
      a.href = url;
      a.download = postdata.ItemDescription + ".xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    })
}

export function deleteMailingList(id) {
  return Axios.delete(`/api/DeleteMailingList/${id}`).then(response => response.data).catch(error => error);
}

export function getAdminManageItems() {
    return Axios.get('/api/GetAdminManageItems').then(response => response.data).catch(error => error);
/*    return Axios.get('/api/GetAdminManageItems', { params: { epicorCompany }}).then(response => response.data).catch(error => error);*/
}

export function insertQueueUsers(postdata) {
    return Axios.post('/api/InsertQueueUsers', postdata).then(response => response.data).catch(error => error);
}

export function removeQueueUsers(postdata) {
    return Axios.post('/api/RemoveQueueUsers', postdata).then(response => response.data).catch(error => error);
}

export function insertWorkflowItem(postdata) {
    return Axios.post('/api/InsertWorkflowItem', postdata).then(response => response.data).catch(error => error);
}

export function updateWorkflowItemRecipients(postdata) {
    return Axios.post('/api/UpdateWorkflowItemRecipients', postdata).then(response => response.data).catch(error => error);
}

//Users
export function getAllUsers() {
    return Axios.post('/api/GetAllUsers').then(response => response.data).catch(error => error);
}

export function UpdateUser(postdata) {
    return Axios.post('/api/UpdateUser', postdata).then(response => response.data).catch(error => error);
}

export function AddNewUser(postdata) {
    console.log(postdata);
    return Axios.post('/api/AddNewUser', postdata).then(response => response.data).catch(error => error);
}

export function DeleteUser(postdata) {
    return Axios.post('/api/DeleteUser', postdata).then(response => response.data).catch(error => error);
}