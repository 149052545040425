import React, { useEffect, useState } from 'react';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import { useNavigate, useLocation } from "react-router-dom";
import { Drawer, List } from 'antd';

export default function SavedQueriesSlider(props) {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const [state, setState] = useState({
        showModal: props.showModal,
        queries: []
    });

    useEffect(() => {
        LoadSavedQueries();
    }, [props]);

    function LoadSavedQueries() {
        let postdata = {};
        postdata.userid = userInfo.userId;
        
        Axios.post(`/api/GetSavedQueries`, postdata
        ).then(response => {
            let queries = response.data;

            setState({
                ...state,
                queries,
                showModal: props.showModal
            });

        }).catch(error => {
            console.log(error);
        });
    }

    function hideModal() {
        setState({
            ...state,
            showModal: false
        });
    }

    function LoadSelectedQuery(myQueryId) {
        let postdata = {};
        postdata.id = myQueryId;

        Axios.get(`/api/GetQueryById`, {
            params: {
                id: myQueryId
            }
        }).then(response => {
            setTimeout(() => props.loadSelectedQuery(response.data), 1000);

            hideModal();

        }).catch(error => {
            console.log(error);
        });
    }

    function DeleteQuery(myQueryId) {
        console.log(myQueryId);

        confirm({
            title: "You are about to delete this saved query.",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"]
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                let postdata = {};
                postdata.id = myQueryId.toString();

                console.log(postdata);

                Axios.post(`/api/DeleteQueryBuilder`, postdata
                ).then(response => {
                    LoadSavedQueries();

                }).catch(error => {
                    console.log(error);
                });

                return 0;
            } else {
                return 0;
            }
        });
    }

    return (
        <Drawer
            title='My Saved Queries'
            placement={'right'}
            onClose={hideModal}
            visible={state.showModal}
            key='valAttachment'
            width={'25%'}
            closeIcon={<i className="fas fa-chevron-left"></i>}
        >
            {state.loading ? <span>Loading...</span>
                :
                <>
                    <List
                        itemLayout="vertical"
                        size="large"
                        dataSource={state.queries}
                        style={{ cursor: 'pointer' }}
                        renderItem={item => (
                            <List.Item
                                key={item.name}
                                className='ant-list-item-flex'
                            >
                                <div onClick={() => LoadSelectedQuery(item.id)}>{item.name}</div><i id="delete" className="fas fa-trash-alt text-danger float-right hover" onClick={() => DeleteQuery(item.id)}></i>
                            </List.Item>
                        )}
                    />
                </>
            }

        </Drawer>
    );
}