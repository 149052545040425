import React, { useState, useEffect, useRef } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Axios from '../../config/axios';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import { Fragment } from 'react';
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import { useForm, Controller } from "react-hook-form";
import { toast, confirm } from '@rickylandino/react-messages';
import { Select, Drawer } from 'antd';
import Card from 'react-bootstrap/Card';

export default function JST_RequestAdWFSlider(props) {
    const [state, setState] = useState({
        showPane: false,
        dataLoaded: false,
        selectedcustomer: null,
        salesreps: [],
        salesrepsListLoaded: false,
        searchForm: {
            addate: "",
            salesrep: "",
            packagetype: "",
            packageyear: "",
            adtype: "",
            destroyold: "",
            companyname: "",
            street1: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            phone: "",
            fax: "",
            emailaddress: "",
            domain: "",
            proof1size: "",
            proof2size: "",
            artoriginal: false,
            artbrochure: false,
            artphotograph: false,
            artwebsiteimagery: false,
            artwebsitecontent: false,
            notes: "",
            proofmethod: ""
        },
        searchFormInitialState: {
            addate: ""
        }
    });

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    useEffect(() => {
        setState({
            ...state,
            salesrepsListLoaded: false
        });

        GetAdProofsSalesReps();

    }, [props]);

    function GetAdProofsSalesReps() {
        // get sales reps
        let postdata = {};

        Axios.post(`/api/GetAdProofsSalesReps`, postdata
        ).then(async response => {
            const salesreps = response.data;

            console.log(salesreps);

            setState({
                ...state,
                dataLoaded: true,
                salesreps,
                showPane: props.showPane,
                salesrepsListLoaded: true
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function hidePane() {
        setState({
            ...state,
            showPane: false
        });

        setTimeout(() => {
            props.hidePane();
        }, 1000);
    }

    function handleSubmitWorkflow() {

    }

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        // https://stackoverflow.com/questions/27105257/storing-an-object-in-state-of-a-react-component Kevin Danikowski answer
        setState({
            ...state,
            searchForm: { ...state.searchForm, [name]: value }
        });
    }

    return (
        <Drawer
            closeIcon={<i className="fas fa-chevron-left float-left"></i>}
            title='Request Ad'
            placement={'right'}
            onClose={hidePane}
            visible={state.showPane}
            key='requestAd'
            width={'85%'}

            footer={
                <div className="form-group col-12 padding-25-10">
                    <button id="btnSaveChanges" onClick={handleSubmitWorkflow} className="btn btn-submit me-3" style={{ 'marginLeft': '5px' }}>Submit Workflow</button>
                    <button className="btn btn-outline-primary margin-left-15 float-right" onClick={hidePane}>Close</button>
                </div>
            }
        >
            <div className="slide-pane-body">
                <div className="row mb-3">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <Card className="d2pCard mb-3">
                            <Card.Header><span>Ad Overview</span></Card.Header>
                            <Card.Body>
                                <div className="row">
                                    <div className="form-group col-lg-1">
                                        <label className="form-label">Date</label>
                                        <DatePicker onChange={(date) => setState({ ...state, searchForm: { ...state.searchForm, addate: date } })} name="addate" selected={state.searchForm.addate} className="form-control-custom" />
                                    </div>
                                    <div className="form-group col-lg-2">
                                        <label className="form-label">Sales Rep:</label>
                                        <select name="salesrep" value={state.searchForm.salesrep} onChange={handleInputChange} className="form-control-custom">
                                            <option value="">Select Rep</option>
                                            {/*{salesRepOptionItems}*/}
                                        </select>
                                    </div>
                                    <div className="form-group col-lg-2">
                                        <label className="form-label" htmlFor="majcatselect">Target Publication:</label>
                                        <select name="packagetype" value={state.searchForm.packagetype} onChange={handleInputChange} className="form-control-custom">
                                            <option value="">Select Issue</option>
                                            <option value="Feb D2P">Feb D2P</option>
                                            <option value="Mar D2P">Mar D2P</option>
                                            <option value="Apr D2P">Apr D2P</option>
                                            <option value="May D2P">May D2P</option>
                                            <option value="Supplier Directory (Jun/Jul)">Supplier Directory (Jun/Jul)</option>
                                            <option value="Aug D2P">Aug D2P</option>
                                            <option value="Sep D2P">Sep D2P</option>
                                            <option value="Oct D2P">Oct D2P</option>
                                            <option value="Nov D2P">Nov D2P</option>
                                            <option value="----------------------" disabled>----------------------</option>
                                            <option value="TX Program">TX program</option>
                                            <option value="GA Program">GA Program</option>
                                            <option value="NJ Program">CT Program</option>
                                            <option value="IL Program">IL Program</option>
                                            <option value="SC Program">SC Program</option>
                                            <option value="MN Program">MN Program</option>
                                            <option value="LA Program">MA Program</option>
                                            <option value="MA Program">LA Program</option>
                                            <option value="NC Program">PA Program</option>
                                            <option value="OH Program">IN Program</option>
                                            <option value="MO Program">NC Program</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-lg-1">
                                        <label className="form-label" htmlFor="majcatselect">Select Year:</label>
                                        <select name="packageyear" value={state.searchForm.packageyear} onChange={handleInputChange} className="form-control-custom">
                                            <option value="">Select Year</option>
                                            <option value="2019">2019</option>
                                            <option value="2020">2020</option>
                                            <option value="2021">2021</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <div><label className="form-label" htmlFor="majcatselect">Type:</label></div>
                                        <div style={{ marginRight: '20px', float: 'left' }}><span><input type="radio" name="adtype" value="0" checked={state.searchForm.adtype === '0'} onChange={handleInputChange} /></span>&nbsp;New Ad</div>
                                        <div style={{ marginRight: '20px', float: 'left' }}><span><input type="radio" name="adtype" value="1" checked={state.searchForm.adtype === '1'} onChange={handleInputChange} /></span>&nbsp;Change Existing Ad</div>
                                        <div style={{ marginRight: '20px', float: 'left' }}><span><input type="radio" name="adtype" value="2" checked={state.searchForm.adtype === '2'} onChange={handleInputChange} /></span>&nbsp;New Ad for Existing Advertiser</div>
                                    </div>
                                    <div className="form-group col-lg-2">
                                        <div><label className="form-label" htmlFor="majcatselect">Destroy Old Ads:</label></div>
                                        <div style={{ marginRight: '20px', float: 'left' }}><span><input type="radio" name="destroyold" value="0" checked={state.searchForm.destroyold === '0'} onChange={handleInputChange} /></span>&nbsp;Yes</div>
                                        <div style={{ marginRight: '20px', float: 'left' }}><span><input type="radio" name="destroyold" value="1" checked={state.searchForm.destroyold === '1'} onChange={handleInputChange} /></span>&nbsp;No</div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <Card className="d2pCard mb-3">
                            <Card.Header><span>Company Information</span></Card.Header>
                            <Card.Body>
                                <div className="row">
                                    <div className="form-group col-lg-12">
                                        <label className="form-label">Company</label>
                                        <input className="form-control-custom" type="text" name="companyname" placeholder="" value={state.searchForm.companyname} onChange={handleInputChange} readOnly />
                                    </div>

                                    <div className="form-group col-lg-8">
                                        <label className="form-label">Address</label>
                                        <input className="form-control-custom" type="text" name="street1" placeholder="" value={state.searchForm.street1} onChange={handleInputChange} />
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">P.O. Box</label>
                                        <input className="form-control-custom" type="text" name="street2" placeholder="" value={state.searchForm.street2} onChange={handleInputChange} />
                                    </div>

                                    <div className="form-group col-lg-4">
                                        <label className="form-label">City</label>
                                        <input className="form-control-custom" type="text" name="city" placeholder="" value={state.searchForm.city} onChange={handleInputChange} />
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">State</label>
                                        <input className="form-control-custom" type="text" name="state" placeholder="" value={state.searchForm.state} onChange={handleInputChange} />
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">Zip</label>
                                        <input className="form-control-custom" type="text" name="zip" placeholder="" value={state.searchForm.zip} onChange={handleInputChange} />
                                    </div>

                                    <div className="form-group col-lg-6">
                                        <label className="form-label">Contact</label>
                                        <input className="form-control-custom" type="text" name="contactname" placeholder="" value={state.searchForm.contactname} onChange={handleInputChange} />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label className="form-label">Title</label>
                                        <input className="form-control-custom" type="text" name="contacttitle" placeholder="" value={state.searchForm.contacttitle} onChange={handleInputChange} />
                                    </div>

                                    <div className="form-group col-lg-6">
                                        <label className="form-label">Phone</label>
                                        <input className="form-control-custom" type="text" name="contactphone" placeholder="" value={state.searchForm.contactphone} onChange={handleInputChange} />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label className="form-label">Email</label>
                                        <input className="form-control-custom" type="text" name="contactemail" placeholder="" value={state.searchForm.contactemail} onChange={handleInputChange} />
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>

                

                {/*<div className="row mb-3">*/}
                {/*    <div className="col-sm-12 col-md-12 col-lg-8">*/}
                {/*        <Card className="d2pCard mb-3">*/}
                {/*            <Card.Header><span>Company Information</span></Card.Header>*/}
                {/*            <Card.Body>*/}
                {/*                <div className="row">*/}
                {/*                    <div className="form-group col-lg-12">*/}
                {/*                        <label className="form-label">Company</label>*/}
                {/*                        <input className="form-control-custom" type="text" name="companyname" placeholder="" value={state.searchForm.companyname} onChange={handleInputChange} readOnly />*/}
                {/*                    </div>*/}

                {/*                    <div className="form-group col-lg-8">*/}
                {/*                        <label className="form-label">Address</label>*/}
                {/*                        <input className="form-control-custom" type="text" name="street1" placeholder="" value={state.searchForm.street1} onChange={handleInputChange} />*/}
                {/*                    </div>*/}
                {/*                    <div className="form-group col-lg-4">*/}
                {/*                        <label className="form-label">P.O. Box</label>*/}
                {/*                        <input className="form-control-custom" type="text" name="street2" placeholder="" value={state.searchForm.street2} onChange={handleInputChange} />*/}
                {/*                    </div>*/}

                {/*                    <div className="form-group col-lg-4">*/}
                {/*                        <label className="form-label">City</label>*/}
                {/*                        <input className="form-control-custom" type="text" name="city" placeholder="" value={state.searchForm.city} onChange={handleInputChange} />*/}
                {/*                    </div>*/}
                {/*                    <div className="form-group col-lg-4">*/}
                {/*                        <label className="form-label">State</label>*/}
                {/*                        <input className="form-control-custom" type="text" name="state" placeholder="" value={state.searchForm.state} onChange={handleInputChange} />*/}
                {/*                    </div>*/}
                {/*                    <div className="form-group col-lg-4">*/}
                {/*                        <label className="form-label">Zip</label>*/}
                {/*                        <input className="form-control-custom" type="text" name="zip" placeholder="" value={state.searchForm.zip} onChange={handleInputChange} />*/}
                {/*                    </div>*/}

                {/*                    <div className="form-group col-lg-6">*/}
                {/*                        <label className="form-label">Contact</label>*/}
                {/*                        <input className="form-control-custom" type="text" name="contactname" placeholder="" value={state.searchForm.contactname} onChange={handleInputChange} />*/}
                {/*                    </div>*/}
                {/*                    <div className="form-group col-lg-6">*/}
                {/*                        <label className="form-label">Title</label>*/}
                {/*                        <input className="form-control-custom" type="text" name="contacttitle" placeholder="" value={state.searchForm.contacttitle} onChange={handleInputChange} />*/}
                {/*                    </div>*/}

                {/*                    <div className="form-group col-lg-6">*/}
                {/*                        <label className="form-label">Phone</label>*/}
                {/*                        <input className="form-control-custom" type="text" name="contactphone" placeholder="" value={state.searchForm.contactphone} onChange={handleInputChange} />*/}
                {/*                    </div>*/}
                {/*                    <div className="form-group col-lg-6">*/}
                {/*                        <label className="form-label">Email</label>*/}
                {/*                        <input className="form-control-custom" type="text" name="contactemail" placeholder="" value={state.searchForm.contactemail} onChange={handleInputChange} />*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </Card.Body>*/}
                {/*        </Card>*/}

                {/*        <Card className="d2pCard mb-3">*/}
                {/*            <Card.Header><span>Order Details</span></Card.Header>*/}
                {/*            <Card.Body>*/}
                {/*                <div style={{ marginRight: '20px', float: 'left' }}><input type="checkbox" name="materialChange" checked={state.searchForm.materialChange} onChange={handleInputChange} />&nbsp;Material Change</div>*/}
                {/*                <div style={{ marginRight: '20px', float: 'left' }}><input type="checkbox" name="sizeChange" checked={state.searchForm.sizeChange} onChange={handleInputChange} />&nbsp;Size Change</div>*/}
                {/*                <div style={{ marginRight: '20px', float: 'left' }}><input type="checkbox" name="rateChange" checked={state.searchForm.rateChange} onChange={handleInputChange} />&nbsp;Rate Change</div>*/}
                {/*                <div style={{ marginRight: '20px', float: 'left' }}><input type="checkbox" name="otherChange" checked={state.searchForm.otherChange} onChange={handleInputChange} />&nbsp;Other Change</div>*/}

                {/*                <div className="row">*/}
                {/*                    <label>*/}
                {/*                        Total Cost:&nbsp;<input className="inline-text-box" type="text" name="totalcost" placeholder="" value={state.searchForm.totalcost} onChange={handleInputChange} />*/}
                {/*                    </label>*/}
                {/*                </div>*/}
                {/*            </Card.Body>*/}
                {/*        </Card>*/}

                {/*        <Card className="d2pCard">*/}
                {/*            <Card.Header><span>Notes</span></Card.Header>*/}
                {/*            <Card.Body>*/}
                {/*                <textarea style={{ width: '100%', height: '200px' }} name="notes" placeholder="" value={state.searchForm.notes} onChange={handleInputChange} />*/}
                {/*            </Card.Body>*/}
                {/*        </Card>*/}
                {/*    </div>*/}
                {/*    <div className="col-xs-12 col-md-12 col-lg-4">*/}
                {/*        <Card className="d2pCard">*/}
                {/*            <Card.Header><span>Upcoming Issues</span></Card.Header>*/}
                {/*            <Card.Body>*/}
                {/*                {state.futureIssues &&*/}
                {/*                    state.futureIssues.map((issue, index) => (*/}
                {/*                        <div style={{ "paddingBottom": "10px" }} className="row">*/}
                {/*                            <div className="col-sm-12 col-md-12 col-lg-12">*/}
                {/*                                */}{/*<input type="checkbox" name={`leadretrieval${show.name}`} onChange={() => handleOptionChange('leadretrieval', show.name)} checked={show.leadretrieval === 'Yes'} /> Lead Retrieval*/}
                {/*                                <input type="checkbox" checked={issue.selected === 'Yes'} name={issue.issue} onChange={() => handleIssueSelectedChange(issue.issue)} />&nbsp;{issue.issue}: {issue.description}*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    ))*/}
                {/*                }*/}
                {/*            </Card.Body>*/}
                {/*        </Card>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="row mb-3">*/}
                {/*    <div className="col-sm-12 col-md-12 col-lg-8">*/}
                        
                {/*    </div>*/}
                {/*    */}{/*<div className="col-xs-12 col-md-12 col-lg-4">*/}
                {/*    */}{/*    <Card className="d2pCard">*/}
                {/*    */}{/*        <Card.Header><span>Packages</span></Card.Header>*/}
                {/*    */}{/*        <Card.Body>*/}
                {/*    */}{/*            <div><input type="checkbox" name="leadretrieval" checked={state.searchForm.leadretrieval} onChange={handleInputChange} />&nbsp;Lead Retrieval</div>*/}
                {/*    */}{/*            <div><input type="checkbox" name="internetlink" checked={state.searchForm.internetlink} onChange={handleInputChange} />&nbsp;Internet Link</div>*/}
                {/*    */}{/*            <div><input type="checkbox" name="qualifiedprospectlist" checked={state.searchForm.qualifiedprospectlist} onChange={handleInputChange} />&nbsp;Qualified Prospect List</div>*/}

                {/*    */}{/*            <div className="form-group col-lg-6">*/}
                {/*    */}{/*                <label className="form-label">Total Cost</label>*/}
                {/*    */}{/*                <input className="form-control-custom" type="text" name="totalcost" placeholder="" value={state.searchForm.totalcost} onChange={handleInputChange} />*/}
                {/*    */}{/*            </div>*/}
                {/*    */}{/*        </Card.Body>*/}
                {/*    */}{/*    </Card>*/}
                {/*    */}{/*</div>*/}
                {/*</div>*/}
                {/*<div className="row mb-3">*/}
                {/*    <div className="col-sm-12 col-md-12 col-lg-12">*/}
                        
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </Drawer>
    );
}