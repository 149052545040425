import React, { useEffect, useState } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { Modal, Table, Select } from 'antd';
import { useForm } from 'react-hook-form';
import { toast } from '@rickylandino/react-messages';

import { insertWorkflowItem, workflowItemsState, availableUsersState, updateWorkflowItemRecipients } from '../../services/AdminService';
//import { persistentEpicorCompanyState } from '../../../services/UserService';
import SkeletonTable from '../Helpers/SkeletonTable';

const { Option } = Select;

export default function ManageWorkflow(props) {
    const { register, getValues, setValue, control, watch } = useForm();

    const [showAddWorkflowItem, setShowAddWorkflowItem] = useState(false);
    const [workflowItems, setWorkflowItems] = useRecoilState(workflowItemsState);
    const availableUsers = useRecoilValue(availableUsersState);
    const [loading, setLoading] = useState(props.loading);
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading]);

    function handleCloseModal() {
        setShowAddWorkflowItem(false);
        setValue('workflowItem', '');
        setValue('workflowType', '');
    }

    function handleSaveWorkflowItem() {
        let postdata = {
            workflowType: getValues().workflowType,
            workflowItem: getValues().workflowItem,
            division: userInfo.jobShopDivision
        }

        if (!postdata.workflowType || !postdata.workflowItem) {
            toast.error("Please fill in all required fields");
        } else {
            insertWorkflowItem(postdata).then(data => {
                if (data) {
                    setWorkflowItems(data);
                    handleCloseModal();
                    toast.success("Workflow Item added successfully");
                } else {
                    toast.error("Something went wrong");
                }
            }).catch(error => {
                toast.error("Something went wrong");
            })
        }
    }

    function handlePrimaryRecipientChange(primaryRecipient, row) {
        let wfi = [...workflowItems];
        let item = { ...wfi[row.index] };

        if (item) {
            item.username = primaryRecipient;
            item.isDirty = true;
        } else {
            item = {
                username: primaryRecipient,
                isDirty: true
            }
        }

        wfi[row.index] = item;
        setWorkflowItems(wfi);
    }

    function handleSaveWorkflowChanges() {
        let updateList = [];
        workflowItems.forEach((item, idx) => {
            if (item.isDirty) {
                updateList.push(item);
            }
        });

        console.log(updateList);

        updateWorkflowItemRecipients(updateList).then(data => {
            if (data) {
                setWorkflowItems(data);
                toast.success("Workflow has been updated");
            } else {
                toast.error("Something went wrong");
            }
        }).catch(error => {
            toast.error("Something went wrong");
        })
    }

    const startingIndex = 0;

    const primaryRecipientFormat = (text, record, index) => {
        return (
            <Select
                placeholder="Please select"
                onChange={(value, idx) => handlePrimaryRecipientChange(value, idx)}
                className="form-control-custom"
                bordered={false}
                value={record?.username || ''}
            >
                <Option index={index + startingIndex} value='JSS Admin Review'>JSS Admin Review Queue</Option>
                {availableUsers.map((e, idx) => <Option index={index + startingIndex} key={index + "a" + idx} value={e.username}>{e.username}</Option>)}
            </Select>
        );
    }

    const columns = [
        {
            title: 'Workflow Item',
            dataIndex: "workflowItem",
            key: 'workflowItem',
            width: 250,
            ellipsis: true,
        },
        {
            title: 'Recipient',
            key: 'action1',
            render: primaryRecipientFormat,
        }
    ];

    return (
        <>
            <div className="text-end">
                <button type="button" className="btn btn-submit margin-0-10" onClick={() => setShowAddWorkflowItem(true)}>
                    Add Workflow Item
                </button>
            </div>
            <div className="my-3">
                {loading ?
                
                    <SkeletonTable columns={columns} rowCount={5} />
                    :
                    <Table columns={columns} size="small"
                        dataSource={workflowItems} rowKey='workflowItem_ID' />
                }
            </div>

            <button type="button" className="btn btn-submit margin-0-10" onClick={handleSaveWorkflowChanges}>
                Save Changes
            </button>

            <Modal
                visible={showAddWorkflowItem}
                title="Add Workflow Item"
                onOk={handleSaveWorkflowItem}
                onCancel={handleCloseModal}
                footer={[
                    <button className="btn btn-submit me-3" key="0" onClick={handleSaveWorkflowItem}>
                        Save
                    </button>,
                    <button className="btn btn-outline-primary" key="1" onClick={handleCloseModal}>
                        Cancel
                    </button>
                ]}
            >
                <div className="row">
                    <div className="form-group col col-lg-6 col-12">
                        <label className="form-label">Workflow Type <span className="text-danger">*</span></label>
                        <select {...register('workflowType')} className="form-control-custom">
                            <option value=""></option>
                            <option>Customer</option>
                            <option>Quoting</option>
                            <option>Contracts</option>
                        </select>
                    </div>
                    <div className="form-group col col-12">
                        <label className="form-label">Workflow Type <span className="text-danger">*</span></label>
                        <input className="form-control-custom" {...register('workflowItem')} />
                    </div>
                </div>
            </Modal>
        </>    
    );
}