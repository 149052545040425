import React, { useState, useEffect } from 'react';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import Moment from 'moment';
import { useForm, Controller } from "react-hook-form";
import { Modal, Alert, Empty, Spin, Select, Tabs, Table, Radio, message, Input } from 'antd';
import ReactLoading from 'react-loading';
import { Fragment } from 'react';
import { Button } from 'react-bootstrap';
import { TbUserCheck } from 'react-icons/tb';
import { useNavigate, useLocation } from "react-router-dom";
import SendEmailSlider from '../Email/SendEmailSlider';

export default function WidgetCallSheets(props) {
    const { register, getValues, setValue, control, watch } = useForm();
    const navigate = useNavigate();
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
    const { Option } = Select;
    const location = useLocation();

    const [state, setState] = useState({
        showModal: props.showModal,
        futureshows: [],
        shows: [],
        salesReps: [],
        prospectSources: [],
        loading: true,
        callSheetType: 'pastExhibitors',
        selectedTargetShow: '',
        selectedPastShows: [],
        selectedSalesRep: '',
        selectedSources: [],
        searchRunning: false,
        prospects: [],
        hasSearchBeenRunYet: false,
        searchValue: '',
        initialized: false,
        currentPage: 1,
        currentPageSize: 10,
        callSheetCriteriaLoaded: false,
        showSendEmailSlider: false,
        selectedContact: {},
        emailInformation: {}
    });

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);

    const [prospects, setProspects] = useState([]);
    const [filteredProspects, setfilteredProspects] = useState([]);

    const RadioGroup = Radio.Group;

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    useEffect(() => {
        setState({
            ...state,
            loading: true
        });

        Axios.get(`/api/InitializeCallSheetGenerator/`
        ).then(response => {
            let futureshows = response.data.futureshows;
            let shows = response.data.shows;
            let salesReps = response.data.salesReps;
            let prospectSources = response.data.prospectSources;

            setState({
                ...state,
                futureshows,
                shows,
                salesReps,
                prospectSources,
                showModal: true,
                callSheetType: 'pastExhibitors',
                initialized: true
            });

        }).catch(error => {
            console.log(error);
        });
    }, [props]);

    useEffect(() => {
        if (state.initialized === true) {
            SetCallSheetCriteria();
        }
    }, [state.initialized]);

    useEffect(() => {
        if (state.callSheetCriteriaLoaded === true && location.state !== null) {
            let myPage = 1;
            let mySizePerPage = 10;
            if (location.state.page !== undefined) { myPage = location.state.page; }
            if (location.state.sizePerPage !== undefined) { mySizePerPage = location.state.sizePerPage; }
            if (location.state.selectedContact !== undefined) {
                //this highlights the previously selected row in the table
                setSelectedRowKeys(location.state.selectedContact);
            }

            generateCallSheet(myPage, mySizePerPage);
        }
    }, [state.callSheetCriteriaLoaded]);

    function closeModal() {
        setState({
            ...state,
            showModal: false
        });
    }

    function openModal() {
        setState({
            ...state,
            showModal: true
        });
    }

    function handleSelectTargetShow(value) {
        setState({
            ...state,
            selectedTargetShow: value
        });
    }

    function handlePastShowsChange(value) {
        setState({
            ...state,
            selectedPastShows: value
        });
    }

    function handleSalesRepChange(value) {
        setState({
            ...state,
            selectedSalesRep: value
        });
    }

    function handleSourcesChange(value) {
        setState({
            ...state,
            selectedSources: value
        });
    }

    function onRadioChange(e) {
        //console.log('radio checked', e.target.value);
        setState({
            ...state,
            callSheetType: e.target.value
        });
    }

    function generateCallSheet(myPage, mySizePerPage) {
        var mySources = [];
        var mySourcesStr;

        if (state.callSheetType === 'pastExhibitors' || state.callSheetType === 'prospects') {
            if (state.selectedSalesRep === '' || state.selectedTargetShow === '' || state.selectedPastShows.toString() === '') {
                toast.error('A sales rep, target show and at least one past show must be selected');
                return;
            }
        }
        else {
            if (state.selectedSalesRep === '' || state.selectedTargetShow === '' || state.selectedSources.toString() === '') {
                toast.error('A sales rep, target show and at least one source must be selected');
                return;
            }
        }

        setState({
            ...state,
            searchRunning: true
        });

        if (state.selectedSources.length > 0) {
            for (var i = 0; i < state.selectedSources.length; i++) {
                mySources[i] = "'" + state.selectedSources[i] + "'";
            }
            mySourcesStr = mySources.toString();
            //mySources = state.selectedSources.join(",");
        }
        else {
            mySourcesStr = '';
        }

        let postdata = {
            showcode: state.selectedTargetShow,
            pastshows: state.selectedPastShows.toString(),
            salesrep: state.selectedSalesRep,
            callSheetType: state.callSheetType,
            sources: mySourcesStr
        };
        window.sessionStorage.setItem("lastCallSheetCriteria", JSON.stringify(postdata));

        Axios.get(`/api/GenerateCallSheet`, {
            params: {
                showcode: state.selectedTargetShow,
                pastshows: state.selectedPastShows.toString(),
                salesrep: state.selectedSalesRep,
                callSheetType: state.callSheetType,
                sources: mySourcesStr
            }
        }).then(response => {
            let prospects = response.data;

            setProspects(prospects);
            setfilteredProspects(prospects);

            setState({
                ...state,
                prospects: prospects,
                currentPage: myPage,
                currentPageSize: mySizePerPage,
                hasSearchBeenRunYet: true,
                searchRunning: false,
                loading: false
            });

        }).catch(error => {
            console.log(error);
        });
    }

    function SetCallSheetCriteria() {
        var mySelectedPastShows;
        var mySelectedSources;
        let lastCallSheetCriteria = JSON.parse(window.sessionStorage.getItem("lastCallSheetCriteria"));

        if (lastCallSheetCriteria === null) {
            ResetSearchCriteria();
            return;
        }

        //selected past shows
        if (lastCallSheetCriteria.pastshows !== '') {
            mySelectedPastShows = lastCallSheetCriteria.pastshows.split(",").map(Number);
        }
        else {
            mySelectedPastShows = [];
        }

        //selectedSources,
        if (lastCallSheetCriteria.sources !== '') {
            mySelectedSources = lastCallSheetCriteria.sources.split(",").map(Number);
        }
        else {
            mySelectedSources = [];
        }

        setState({
            ...state,
            callSheetType: lastCallSheetCriteria.callSheetType,
            selectedTargetShow: lastCallSheetCriteria.showcode,
            selectedPastShows: mySelectedPastShows,
            selectedSalesRep: lastCallSheetCriteria.salesrep,
            selectedSources: mySelectedSources,
            hasSearchBeenRunYet: false,
            callSheetCriteriaLoaded: true
        });

        if (lastCallSheetCriteria.selectedTargetShow !== '' || lastCallSheetCriteria.selectedPastShows !== '' || lastCallSheetCriteria.selectedSources !== '' || lastCallSheetCriteria.selectedSalesRep !== '') {
            toast.info("Your last call sheet selections were re-loaded");
        }
    }

    function ResetSearchCriteria() {
        setfilteredProspects([]);

        setState({
            ...state,
            callSheetType: 'pastExhibitors',
            selectedTargetShow: '',
            selectedPastShows: [],
            selectedSalesRep: '',
            selectedSources: [],
            prospects: [],
            hasSearchBeenRunYet: false,
            loading: false
        });

        window.sessionStorage.setItem("lastCallSheetCriteria", null);
    }

    function replaceAll(string, search, replace) {
        return string.split(search).join(replace);
    }

    const columns = [
        {
            dataIndex: 'contact_id',
            title: 'Contact ID',
            key: 'contact_id',
            hidden: true
        }, {
            dataIndex: 'id',
            title: 'ID',
            key: 'id',
            hidden: true
        }, {
            dataIndex: 'company',
            title: 'Company',
            key: 'company',
            sorter: (a, b) => a.company.localeCompare(b.company)
        }, {
            dataIndex: 'name',
            title: 'Contact',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name)
        }, {
            dataIndex: 'phone',
            title: 'Phone',
            key: 'phone',
            sorter: (a, b) => a.phone.localeCompare(b.phone),
            render: (phone) => {
                return (
                    <div>
                        {phone === null ? '' : <a href={`tel:${replaceAll(phone, "-", "")}`}>{phone}</a>}
                    </div>
                );
            }
        }, {
            dataIndex: 'email',
            title: 'Email',
            key: 'email',
            sorter: (a, b) => a.email.localeCompare(b.email),
            render: (text, record, index) => (
                <button type="button" class="btn btn-link btn-lg" onClick={(e) => EmailContact(e, record, index)}>{record.email}</button>
            ),
        }, {
            dataIndex: 'state',
            title: 'State',
            key: 'state',
            width: 100,
            sorter: (a, b) => a.state.localeCompare(b.state)
        }, {
            title: 'Jump To',
            key: 'action',
            width: 120,
            render: (text, record, index) =>
                <div id="jumpTo" className="hover"><TbUserCheck id="jumpTo" className="fa-1x text-icon" onClick={(e) => handleJumpTo(e, record, index)} /></div>
            ,
            align: 'center'
        }
    ].filter(item => !item.hidden);

    const onSelectChange = (newSelectedRowKeys) => {
        /*console.log('selectedRowKeys changed: ', newSelectedRowKeys);*/
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    function handleExportCallSheet() {
        var mySources = [];
        var mySourcesStr;

        if (state.selectedSources.length > 0) {
            for (var i = 0; i < state.selectedSources.length; i++) {
                mySources[i] = "'" + state.selectedSources[i] + "'";
            }
            mySourcesStr = mySources.toString();
            //mySources = state.selectedSources.join(",");
        }
        else {
            mySourcesStr = '';
        }

        let postdata = {
            showcode: parseInt(state.selectedTargetShow),
            pastshows: state.selectedPastShows.toString(),
            salesrep: state.selectedSalesRep,
            callSheetType: state.callSheetType,
            sources: mySourcesStr
        }

        const key = "downloadleads";
        message.loading({
            content: 'Downloading Your Leads...',
            key,
            duration: 0,
            style: {
                position: "fixed",
                bottom: 0,
                left: 0
            },
        });

        Axios.post(`/api/DownloadCallSheet`, postdata, {
            responseType: 'blob'
        }).then(response => {
            console.log(response.data);

            message.destroy(key);

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = "JSSCallSheet.xlsx";
            a.click();
            window.URL.revokeObjectURL(url);
        }).catch(error => {
            console.log(error);
        });

    }

    function searchProspects(value) {
        let searchMatches = [];
        var currProspect;
        var valueCleared = false;
        for (let i = 0; i < prospects.length; i++) {
            currProspect = prospects[i];

            if (value !== '') {
                if ((currProspect.company !== null && currProspect.company.toLowerCase().includes(value.toLowerCase())) ||
                    (currProspect.name !== null && currProspect.name.toLowerCase().includes(value.toLowerCase())) ||
                    (currProspect.phone !== null && currProspect.phone.includes(value))) {

                    searchMatches = [...searchMatches, prospects[i]];
                }
            }
            else {
                valueCleared = true;
            }
        }

        setfilteredProspects(valueCleared ? [...prospects] : [...searchMatches]);
    }

    function searchValueChanged(e) {
        setState({
            ...state,
            searchValue: e.target.value
        });
    }


    function updatePageState(page, pageSize) {
        setState({
            ...state,
            currentPage: page,
            currentPageSize: pageSize
        });
    }

    function handleJumpTo(e, record, index) {
        navigate("/customerview", { state: { selectedCustomerId: record.id, selectedContact: selectedRowKeys, callingComponent: 'CallSheetGenerator', page: state.currentPage, sizePerPage: state.currentPageSize } });
    }

    function EmailContact(e, record, index) {
        //this gets the contact and associated customer objects although this is not the most efficient way
        Axios.get(`/api/GetContactInfoByContactId`, {
            params: {
                id: record.contact_id
            }
        }).then(response => {
            let contact = response.data;

            let postdata = {
                company_id: record.id,
                jobshopdivision: userInfo.jobShopDivision
            };
            Axios.post(`/api/GetCustomerById`, postdata
            ).then(response => {
                //Init Customer fields 
                let customer = response.data.customer;

                console.log(customer);

                var emailInformation = {
                    id: '',
                    sendTo: contact.email,
                    selectedCustomer: customer,
                    selectedContact: contact,
                    selectedCustomerId: customer.id,
                    selectedContactId: contact.contact_id,
                    userId: userInfo.userId,
                    fileList: [],
                    emailContent: '',
                }

                setState({
                    ...state,
                    selectedContact: contact,
                    selectedCustomer: customer,
                    emailInformation,
                    showSendEmailSlider: true
                });

            }).catch(error => {
                console.log(error);
            });
        }).catch(error => {
            console.log(error);
        }); 
    }

    return (
        <>
            <div className="row" style={{ paddingBottom: 10 }}>
                <div className="form-group col-12">
                    <h3 className="frame-heading">
                        D2P Call Sheet Generator
                    </h3>
                </div>
                <div className="form-group col-lg-2">
                    <label className="form-label">Select Sales Rep</label>
                    <Select className="form-control-custom d-inline-block" onChange={handleSalesRepChange} style={{ width: '100%' }} placeholder="Select Sales Rep" value={state.selectedSalesRep || undefined} >
                        {state.salesReps.map((rep, idx) => <option key={idx} value={rep.name}>{rep.name}</option>)}
                    </Select>
                </div>

                <div className="form-group col-lg-2">
                    <label className="form-label">Select Target Show</label>
                    <Select className="form-control-custom d-inline-block" onChange={handleSelectTargetShow} style={{ width: '100%' }} placeholder="Select Target Show" value={state.selectedTargetShow || undefined} >
                        {state.futureshows.map((show, idx) => <option key={idx} value={show.showcode}>{show.name}</option>)}
                    </Select>
                </div>

                <div className="form-group col-lg-8" style={{ marginTop: -15 }}>
                    <RadioGroup name={'rb1'} onChange={onRadioChange} style={{ display: 'flex' }} className="form-label" defaultValue='pastExhibitors' value={state.callSheetType || undefined}>
                        <Radio style={radioStyle} value='pastExhibitors' checked={state.callSheetType === 'pastExhibitors'}>Past Exhibitors</Radio>
                        <Radio style={radioStyle} value='prospects' checked={state.callSheetType === 'prospects'}>Prospects</Radio>
                        <Radio style={radioStyle} value='sources' checked={state.callSheetType === 'sources'}>Sources</Radio>
                    </RadioGroup>
                    {(state.callSheetType === 'pastExhibitors' || state.callSheetType === 'prospects') &&
                        <Select mode="multiple" className="form-control-custom d-inline-block" onChange={handlePastShowsChange} style={{ width: '100%', marginTop: 7 }} placeholder="Select one or more past shows" value={state.selectedPastShows || undefined} >
                            {state.shows.map((show, idx) => <option key={idx} value={show.showcode}>{show.name}</option>)}
                        </Select>
                    }
                    {state.callSheetType === 'sources' &&
                        <Select mode="multiple" className="form-control-custom d-inline-block" onChange={handleSourcesChange} style={{ width: '100%', marginTop: 7 }} placeholder="Select one or more source" value={state.selectedSources || undefined} >
                            {state.prospectSources.map((source, idx) => <option key={idx} value={source.source}>{source.source}</option>)}
                        </Select>
                    }
                </div>
            </div>

            <div className="form-group col-lg-12 col-12">
                <Button className="btn btn-submit" onClick={() => generateCallSheet(state.currentPage, state.currentPageSize)}>Generate Call Sheet</Button><button className="btn btn-outline-primary ms-3" onClick={ResetSearchCriteria}>Reset Selections</button>
            </div>

            <div className="form-group col-lg-12 col-12">
                <hr />
                <Spin spinning={state.searchRunning}>
                    <div style={state.searchRunning ? {} : { display: 'none' }}>
                        <Alert
                            message="Loading Prospects..."
                            description="Please stand by while we retrieve prospects that match your selected criteria"
                            type="info"
                        />
                    </div>
                    {!state.searchRunning &&
                        <div className="row">
                            {filteredProspects?.length === 0 ?
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                    <span>
                                        {state.hasSearchBeenRunYet === false ? "Search for Exhibitor Prospects using the search criteria above" : "No exhibitor prospects found matching your search criteria"}
                                    </span>
                                } />
                                :
                                <>
                                    <div className="form-group col-lg-10"></div>
                                    <div className="form-group col-lg-2">
                                        <button className="btn btn-submit btn-sm" onClick={handleExportCallSheet}>Export Call Sheet</button>
                                    </div>

                                    <div className="col-sm-11 col-md-11 col-lg-12">
                                        <Input.Search
                                            style={{ paddingTop: "20px", margin: "0 0 10px 0", width: "50%" }}
                                            placeholder="Search prospects..."
                                            enterButton
                                            onSearch={searchProspects}
                                            allowClear
                                            value={state.searchValue}
                                            onChange={searchValueChanged}
                                        />
                                    </div>
                                    <div className="fullTable form-group col-lg-12">
                                        <Table className="custom-ant-selection"
                                            rowKey={item => item.contact_id}
                                            rowSelection={rowSelection}
                                            hideSelectionColumn={true}
                                            bordered
                                            dataSource={filteredProspects}
                                            columns={columns}
                                            pagination={{
                                                defaultPageSize: 10,
                                                showSizeChanger: true,
                                                pageSizeOptions: ['10', '25', '50', '100'],
                                                showTotal: (total, range) => (
                                                    <span className="color-dark-blue" style={{ left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" }}>
                                                        Showing {range[0]}-{range[1]} of {total}
                                                    </span>
                                                ),
                                                onChange: (page, pageSize) => {
                                                    updatePageState(page, pageSize);
                                                },
                                                current: state.currentPage,
                                                pageSize: state.currentPageSize
                                            }}
                                            onRow={(record, index) => {
                                                return {
                                                    onClick: () => {
                                                        let selRows = [record.contact_id];
                                                        setSelectedRowKeys([...selRows]);
                                                    },
                                                    onDoubleClick: (e) => {
                                                        handleJumpTo(e, record, index);
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                </>
                            }
                        </div>
                    }
                </Spin>
            </div>

            {state.showSendEmailSlider &&
                //<SendEmailSlider selectedCustomer={state.selectedCustomer} selectedContact={state.selectedContact} showPane={state.showSendEmailSlider} hidePane={() => setState({ ...state, showSendEmailSlider: false })} />
                <SendEmailSlider emailInformation={state.emailInformation} showPane={state.showSendEmailSlider} hidePane={() => setState({ ...state, showSendEmailSlider: false })} />
            }
        </>
    );
}