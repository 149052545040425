import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import './login.css';
import logo from '../../img/login_logo.png';
import cookie from 'react-cookies';
import { Spin, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Globals from '../../config/globals';
import Axios from '../../config/axios';
import { toast } from '@rickylandino/react-messages';
import TFASetup from "./TFASetup";
import TFALogin from "./TFALogin";

export default function Login(props) {
    const navigate = useNavigate();

    const [showTfaSetup, setShowTfaSetup] = useState(false);
    const [showTfaLogin, setShowTfaLogin] = useState(false);
    const [loading, setLoading] = useState(false);

    const [state, setState] = useState({
        validUserFound: true,
        mode: "noShowTwoFactor",
        hideLdap: false,
        isLdapChecked: false,
        userid: "",
        password: "",
        formFields: {
            userid: '',
            password: ''
        },
        loading: false
    });

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    useEffect(() => {
        //For now, disable LDAP authentication
        setState({ ...state, isLdapChecked: false, hideLdap: true });

        //Uncomment to re-enable LDAP
        //if (window.location.href.indexOf("localhost") > -1) {
        //    setState({ ...state, isLdapChecked: false, hideLdap: false });
        //}
        //else {
        //    setState({ ...state, isLdapChecked: true, hideLdap: true });
        //}
    }, []);

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setState({
            ...state,
            formFields: { ...state.formFields, [name]: value }
        });
    }

    async function handleSubmit(e) {

        e.preventDefault();

        // ***** Capture form fields to variables *****
        var useldapauth = document.getElementById("ldapauth");
        var myuserid = document.getElementById("userid");
        var mypwd = document.getElementById("password");

        if (myuserid === null || myuserid === "" || mypwd === null || mypwd === "") {
            document.getElementById("userid").focus();
            toast.error("Login Denied - User ID & Password Required");
            return;
        }

        // ***** Validate user, LDAP if indicated, otherwise against db table *****
        let bearer = "";

        let postdata = {};
        postdata.UserId = myuserid.value;
        postdata.Password = mypwd.value;
        postdata.isLdapChecked = state.isLdapChecked;

        await Axios.post(`/api/validatewebcmuser`, postdata
        ).then(response => {
            bearer = response.data;

            // ***** If no limited scope bearer token returned, the user credentials were invalid *****
            if (bearer === "") {
                setState({
                    ...state,
                    validUserFound: false,
                    loading: false
                });

                toast.error("Invalid User Credentials Entered");

                return;
            } else {
                // set limited scope bearer token where Axios knows where to find it
                let userInfo = { ...Globals.userInfo };
                userInfo.bearer = bearer.token;
                userInfo.userId = bearer.userID;
                //userInfo.userName = commandresult.username;
                //userInfo.fullName = commandresult.fName + " " + commandresult.lName;
                userInfo.isLoggedIn = true;
                userInfo.userQueueAccess = bearer.userQueueAccess;

                Globals.userInfo = userInfo;

                window.sessionStorage.setItem("userInfo", JSON.stringify(userInfo));

                Determine2FA(myuserid, mypwd);
            }
        }).catch(error => {
            toast.error("Invalid User Credentials Entered");
        });
    }

    async function Determine2FA(myuserid, mypwd) {
        // ***** Determine if this user has TFA enabled, this is used to determine 2FA setup vs 2FA code entry screen *****
        let postdata = {};
        let tfaEnabled = false;

        postdata = {};
        postdata.UserId= myuserid.value;
        postdata.Password = mypwd.value;

        await Axios.post(`/api/GetTFAEnabled`, postdata
        ).then(response => {
            tfaEnabled = response.data;
        }).catch(error => {
            console.log(error);
        });

        //Uncomment this line to bypass 2FA 
        //validateUser(myuserid.value, mypwd.value);

        //Uncomment this code block to implement 2FA
        if (cookie.load('bypass2FA' + myuserid.value)) {
            validateUser(myuserid.value, mypwd.value);
        } else {
            if (tfaEnabled === true) {
                setState({
                    ...state,
                    mode: "showTwoFactor",
                    validUserFound: true,
                    userid: myuserid.value,
                    password: mypwd.value
                });
            } else {
                setState({
                    ...state,
                    mode: "showTwoFactorSetUp",
                    validUserFound: true,
                    userid: myuserid.value,
                    password: mypwd.value
                });
            }
        }
    }

    async function validateUser(myuserid, mypwd) {

        // at this point, 2FA successful, or bypassed by a cookie
        let userInfo = "";
        let bearer = "";

        //console.log(myuserid);
        //console.log(mypwd);

        let postdata = {};
        postdata.UserId = myuserid;
        postdata.Password = mypwd;

        /*console.log(postdata);*/

        await Axios.post(`/api/webcmtoken`, postdata
        ).then(response => {
            bearer = response.data;

            userInfo = Globals.userInfo;
            userInfo.bearer = bearer;

            //set new token in Globals for use going forward
            Globals.userInfo = userInfo;

            window.sessionStorage.setItem("userInfo", JSON.stringify(userInfo));

        }).catch(error => {
            console.log(error);
        });

        let myauthenticateduser = {};

        postdata = {};
        postdata.userid = myuserid;
        postdata.password = mypwd;
        await Axios.post(`/api/GetUserById`, postdata
        ).then(response => {
            myauthenticateduser = response.data;

        }).catch(error => {
            console.log(error);
        });

        userInfo = Globals.userInfo;
        userInfo.userName = myauthenticateduser.username;
        userInfo.userType = myauthenticateduser.usertype;
        switch (myauthenticateduser.usertype) {
            case 'jst':
                userInfo.jobShopDivision = "JST";
                break;
            default:
                userInfo.jobShopDivision = "JSS"; // shows, admin
                break;
        }
        userInfo.webcmRoles = myauthenticateduser.webcmRoles;
        userInfo.email = myauthenticateduser.email;
        userInfo.emailpassword = myauthenticateduser.emailPassword;
        userInfo.imapSentFolder = myauthenticateduser.imapSentFolder;
        
        Globals.userInfo = userInfo;
        window.sessionStorage.setItem("userInfo", JSON.stringify(userInfo));

        navigate("/dashboard", { replace: true });
    }

    async function goLogin() {
        setState({
            ...state,
            mode: "noShowTwoFactor",
        });
    }

    return (
        <div className="background">
            <div className="blankpage-form-field">
                <div className="page-logo m-0 w-100 align-items-center justify-content-center rounded border-bottom-left-radius-0 border-bottom-right-radius-0 px-4 row">
                    <div className="child col-12"><img src={logo} height="50px" alt="D2P Web CM" aria-roledescription="logo" /></div>
                    <div className="child col-12">Customer Management</div>
                </div>

                <div className="card p-4 border-top-left-radius-0 border-top-right-radius-0">
                    <div className="well no-padding">
                        {state.mode === "showTwoFactor" &&
                            <TFALogin validateUser={validateUser} goLogin={goLogin} userid={state.userid} password={state.password} />
                        }
                        {state.mode === "showTwoFactorSetUp" &&
                            <TFASetup validateUser={validateUser} goLogin={goLogin} userid={state.userid} password={state.password} />
                        }
                        {state.mode === "noShowTwoFactor" &&
                        
                                <form>
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="userid">User ID</label>
                                        <input autoFocus type="text" id="userid" className="form-control-custom" name="userid" value={state.formFields.userid} onChange={handleInputChange} />
                                        {/*<span className="help-block">*/}
                                        {/*    Your User ID*/}
                                        {/*</span>*/}
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="password">Password</label>
                                        <input type="password" id="password" name="password" value={state.formFields.password} onChange={handleInputChange} className="form-control-custom" />
                                        {/*<span className="help-block">*/}
                                        {/*    Your Password*/}
                                        {/*</span>*/}
                                    </div>

                                    <div style={{ textAlign: "left" }} id="ldapauthsection" style={{ display: 'none'}}>
                                        <label className="checkbox form-label">
                                            <input type="checkbox" id="ldapauth" checked={state.isLdapChecked} onChange={() => setState({ ...state, isLdapChecked: !state.isLdapChecked })} />
                                            &nbsp;Use LDAP Authenication
                                        </label>
                                    </div>

                                    <div style={{ textAlign: "left" }}>
                                        <label className="checkbox form-label">
                                            <input type="checkbox" name="remember" />
                                            &nbsp;Stay signed in
                                        </label>
                                    </div>

                                    {loading && <Spin indicator={antIcon} />}
                                    <button className="btn btn-submit float-right" onClick={handleSubmit}>Sign in</button>
                                </form>
                        
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}