import React, { useEffect, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Axios from '../../config/axios';
import { useForm } from "react-hook-form";
//import { getDocumentTypesByLevel } from '../../common/services/ApiService';

import { useDropzone } from 'react-dropzone';
import { InboxOutlined } from '@ant-design/icons';
import Globals from '../../config/globals';

import { toast } from '@rickylandino/react-messages';

import ReactLoading from 'react-loading';

function MyDropzone(props) {
    const maxSize = 1048576;

    const onDrop = useCallback(acceptedFiles => {
        const reader = new FileReader()
        let file = acceptedFiles[0];

        reader.onload = async function () {
            props.setFile(file);
        }
        reader.readAsArrayBuffer(file);
    }, [])

    const { isDragActive, getRootProps, getInputProps, isDragReject, rejectedFiles } = useDropzone({
        onDrop,
        minSize: 0,
        accept: 'application/pdf'
        /*accept: 'application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document'*/
    });

    const isFileTooLarge = rejectedFiles?.length > 0 && rejectedFiles[0].size > maxSize;

    return (
        <div className="ant-upload ant-upload-drag">
            <div {...getRootProps()} className="pt-4 h-100">
                <input {...getInputProps()} />
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                {!isDragActive && 'Click here to select aPDF attachment'}
                {isDragActive && !isDragReject && "Drop the file!"}
                {isDragReject && "File type not accepted, sorry!"}
                {isFileTooLarge && (
                    <div className="text-danger mt-2">
                        File is too large.
                    </div>
                )}
            </div>
        </div>
    );
}

export default function DocumentPane(props) {
    const [state, setState] = useState({
        paneTitle: '',
        selectedDocument: {},
        documentEditMode: '',
        showFileUpload: true,
        showClearFile: false
    });

    const [showPane, setShowPane] = useState(true);
    const [file, setFile] = useState({});

    const [loading, setLoading] = useState(false);

    const { register, handleSubmit, setValue, getValues } = useForm();

    const theme = window.sessionStorage.getItem("theme");

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    useEffect(() => {
        var showFileUpload;
        var showClearFile;
        var myDocType;

        if (props.documentEditMode === 'Edit' && props.selectedDocument.documentName !== null) {
            console.log(props.selectedDocument);

            setValue("documentName", props.selectedDocument.documentName);
            setValue("notes", props.selectedDocument.notes);
            showFileUpload = false;
            showClearFile = true;

            if (props.selectedDocument.shared === false) {
                myDocType = 'Private';
            }
            else {
                myDocType = 'Shared';
            }
        }
        else {
            setValue("documentName", "");
            setValue("notes", "");
            showFileUpload = true;
            showClearFile = false;
            myDocType = 'Private';
        }

        setState({
            ...state,
            paneTitle: props.paneTitle,
            selectedDocument: props.selectedDocument,
            documentEditMode: props.documentEditMode,
            showFileUpload,
            showClearFile,
            docType: myDocType
        });

    }, [props]);

    function hidePane() {
        setShowPane(false);

        setTimeout(() => {
            props.hidePane();
        }, 1000)
    }

    function handleSave() {
        setLoading(true);

        //make sure a document was uploaded
        if (!getValues("documentName") || (state.documentEditMode === 'Add' && JSON.stringify(file) === '{}')) {
            setLoading(false);
            toast.error("No document file selected");
            return;
        }

        let formdata = new FormData();
        if (state.documentEditMode === 'Edit') {
            formdata.append('documentID', props.selectedDocument.repDocumentLibrary_ID);
            formdata.append('contentType', props.selectedDocument.contentType);
        }
        else {
            formdata.append('contentType', file.type);
        }
        formdata.append('documentData', file);
        formdata.append('documentName', getValues().documentName);
        formdata.append('division', userInfo.jobShopDivision);
        formdata.append('notes', getValues().notes === null ? '' : getValues().notes);
        formdata.append('ownedBy', userInfo.userId);
        formdata.append('ownedByFilter', props.ownedByFilter);
        formdata.append('divisionFilter', userInfo.jobShopDivision);
        
        if (state.docType === 'Private') {
            formdata.append('shared', false);
        }
        else {
            formdata.append('shared', true);
        }

        if (state.documentEditMode === 'Add') {
            Axios.post(`/api/AddRepDocument`, formdata
            ).then(response => {
                toast.success("Rep document successfully added!");

                hidePane();

                props.updateDocumentInfo(response.data);

            }).catch(error => {

            });
            console.log(file);
        }
        else {
            Axios.post(`/api/UpdateRepDocument`, formdata
            ).then(response => {
                toast.success("Rep document successfully updated!");

                hidePane();

                props.updateDocumentInfo(response.data);

            }).catch(error => {

            });
        }
    }

    function handleFile(file) {
        setFile(file);

        let documentName = file?.name;
        setValue("documentName", documentName);

        setState({
            ...state
        });
    }

    function handleClearFile() {
        setValue("documentName", "");

        setState({
            ...state,
            showFileUpload: true,
            showClearFile: false
        });
    }

    function handleDocTypeChange(e) {
        setState({
            ...state,
            docType: e.target.value,
        });
    }

    return (
        <SlidingPane
            overlayClassName='pinkCard'
            className={'smallPane'}
            isOpen={showPane || false}
            title={state.documentEditMode === 'Add' ? 'Add New Document' : 'Update Document'}
            onRequestClose={hidePane}
            shouldCloseOnEsc={false}
        >

            <div className="slide-pane-body">
                <div style={state.showFileUpload === true ? {} : { display: 'none' }}>
                    <MyDropzone setFile={(file) => handleFile(file)} />
                </div>
                {/*Selected HTML File:&nbsp;{file?.name && <pre className="mb-4">{file.name}</pre>}*/}

                <form>
                    <div className="row">
                        <div className="form-group col-12">
                            <label className="form-label">Selected File:&nbsp;</label><br />
                            <label className="form-label" style={{ color: "green" }}>{getValues().documentName}</label>&nbsp;&nbsp;&nbsp;
                            <button type="button" class="btn btn-link" onClick={handleClearFile} style={state.showClearFile === true ? {} : { display: 'none' }}>Clear File</button>
                        </div>

                        <div className="form-group col-12">
                            <label className="form-label">Document Name</label>
                            <input {...register("documentName", { required: true })} className="form-control-custom" disabled={state.documentEditMode === 'Edit' ? true : false} />
                        </div>

                        <div className="form-group col-12">
                            <label className="form-label">Notes</label>
                            <textarea {...register("notes")} className="form-control-custom" />
                        </div>

                        <div className="form-group col-12" onChange={handleDocTypeChange}>
                            <label className="form-label">Document Type:</label><br />
                            <input type="radio" name="documentType" value="Private" className="mt-1" checked={state.docType === "Private"} />&nbsp;<label className="form-label">Private Document</label>&nbsp;&nbsp;&nbsp;<br />
                            <input type="radio" name="documentType" value="Shared" className="mt-1" checked={state.docType === "Shared"} />&nbsp;<label className="form-label">Shared Document</label>&nbsp;&nbsp;&nbsp;
                        </div>
                    </div>
                </form>
            </div>

            <div className="modal-footer">
                <div className="form-group col-12 padding-25-10">
                    <button className="btn btn-submit" onClick={handleSave} disabled={loading}>{loading ? <ReactLoading className='tableLoading float-left' type={"spokes"} color={'#F30079'} height={30} width={30} /> : props.documentEditMode === 'Edit' ? 'Update Document' : 'Add Document'}</button>
                    <button className="btn btn-outline-primary ms-3 float-right" onClick={hidePane}>Cancel</button>
                </div>
            </div>
        </SlidingPane>
    );
}