import React, { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Transfer } from 'antd';
import { toast } from '@rickylandino/react-messages';
import { availableUsersState, queueUsersState, insertQueueUsers, removeQueueUsers } from '../../services/AdminService';

const QUEUE_NAME = "Admin Review";

export default function ManageAdminReviewQueue(props) {

    const [targetKeys, setTargetKeys] = useState([]);
    const availableUsers = useRecoilValue(availableUsersState);
    const queueUsers = useRecoilValue(queueUsersState);

    useEffect(() => {
        setTargetKeys(queueUsers.filter(q => q.queue === QUEUE_NAME).map(q => q.id));
    }, []);

    function handleChange(newTargetKeys, direction, moveKeys) {
        let users = moveKeys.map(m => ({ id: m, queue: QUEUE_NAME }));
        if (direction === 'right') {
            
            insertQueueUsers(users).then(data => {
                if (data) {
                    toast.success(`User has been added to the ${QUEUE_NAME} Queue`);
                } else {
                    toast.error('Something went wrong.');
                }
            });
        } else if (direction === 'left') {
            removeQueueUsers(users).then(data => {
                if (data) {
                    toast.success(`User has been removed from the ${QUEUE_NAME} Queue`);
                } else {
                    toast.error('Something went wrong.');
                }
            });
        }

        setTargetKeys(newTargetKeys)
    };

    const renderItem = item => {
        const customLabel = (
            <span className="custom-item">
                {item.username}
            </span>
        );

        return {
            id: item.id,
            label: customLabel, // for displayed item
            value: item.id, // for title and filter matching
        };
    };

    return (
        <div className="row justify-content-center">
            <Transfer
                dataSource={availableUsers}
                showSearch
                listStyle={{
                    width: 300,
                    height: 300,
                }}
                rowKey={record => record.id}
                targetKeys={targetKeys}
                onChange={handleChange}
                render={renderItem}
                titles={['Available Users', 'Current Users']}
            />
        </div>
    );
}