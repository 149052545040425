import Axios from '../config/axios';
import { atom } from 'recoil';
import { setRecoil } from 'recoil-nexus'

export const worklistState = atom({
    key: 'worklistState',
    default: []
});

export const adminReviewQueueState = atom({
    key: 'adminReviewQueueState',
    default: []
});

export function insertContactChangeRequest(postdata) {
  return Axios.post(`/api/InsertContactChangeRequest`, postdata).then(response => response.data).catch(error => error);
}

export function adminApproveDenyRequestMoreInfo(postData) {
    return Axios.post(`/api/AdminApproveDenyRequestMoreInfo`, postData)
    .then(response => response.data)
    .catch(error => error);
}

export function getOutstandingChangesByWorklistId(id) {
    return Axios.get(`/api/GetOutstandingChangesByWorklistId`, {
        params: {
            worklistId: id
        }
    })
    .then(response => response.data)
    .catch(error => error);
}

export function getContactWithRequestedChanges(company, custNum, custConNum, wid) {
    return Axios.get(`/api/GetContactWithRequestedChanges`, {
        params: {
            epicoreCompany: company,
            epicoreCustNum: custNum,
            epicoreCustomerContactNum: custConNum,
            worklistId: wid
        }
    })
    .then(response => response.data)
    .catch(error => error);
}

export function getOpenWorklistsByCustId(id) {
  return Axios.get(`/api/GetOpenWorklistsByCustId`, {
    params: {
      custId: id,
    }
  })
  .then(response => response.data)
  .catch(error => error);
}

export function insertChangeRequestsWithWorklistItem(postdata) {
    return Axios.post('/api/InsertChangeRequestsWithWorklistItem', postdata).then(response => response.data).catch(error => error);
}

export function insertDummyWorklistItem(postdata) {
    return Axios.post('/api/InsertDummyWorklistItem', postdata).then(response => response.data).catch(error => error);
}

//export function getWorklistByUser() {
//    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
//    return Axios.get('/api/GetWorklistByUser', {
//        params: {
//            username: userInfo.userId,
//        }
//    }).then(response => {
//        console.log(response.data);
//        setRecoil(worklistState, response.data);
//    }).catch(error => error);
//}


export function getWorklistByUser() {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
    return Axios.get('/api/GetWorklistByUser', {
        params: {
            username: userInfo.userId,
        }
    }).then(response => response.data).catch(error => error);
}


export function assignWorklistItem(postdata) {
    return Axios.post('/api/AssignWorklistItem', postdata).then(response => response.data).catch(error => error);
}

export function processCustomerChange(postdata) {
    return Axios.post('/api/ProcessCustomerChange', postdata).then(response => response.data).catch(error => error);
}

export function processContactChange(postData) {
  return Axios.post('/api/ProcessContactChange', postData).then(response => response.data).catch(error => error);
}

export function provideMoreInfo(postdata) {
    return Axios.post('/api/ProvideMoreInfo', postdata).then(response => response.data).catch(error => error);
}

export function getDialogueByParentId(parentId) {
    return Axios.get('/api/GetDialogueByParentId', {
        params: {
            parentId
        }
    }).then(response => response.data);
}

export function insertPendingQuoteWithWorklistItem(postdata) {
    return Axios.post('/api/InsertPendingQuoteWithWorklistItem', postdata).then(response => response.data).catch(error => error);
}

export function insertNewCustomerRequestWithWorklistItem(postdata) {
    return Axios.post('/api/InsertNewCustomerRequestWithWorklistItem', postdata).then(response => response.data).catch(error => error);
}

export function insertNewWorklistItem(postdata) {
    return Axios.post('/api/InsertNewWorklistItem', postdata).then(response => response.data).catch(error => error);
}

export function getNewCustomerRequest(newCustomerRequests_ID) {
    return Axios.get('/api/GetNewCustomerRequest', {
        params: {
            newCustomerRequests_ID
        }
    }).then(response => response.data);
}

export function GetEpicorCustomerByCustID(epiToken, custID) {
    return Axios.get('/api/GetEpicorCustomerByCustID', {
        params: {
            epiToken,
            custID
        }
    }).then(response => response.data);
}