import React, { useEffect, useState, Fragment, useRef } from 'react';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import { LoadingOutlined } from '@ant-design/icons';
import { useForm, Controller } from "react-hook-form";
import Globals from '../../config/globals';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from 'react-number-format';
import Moment from 'moment';
import { Button } from 'react-bootstrap';
import SlidingPane from 'react-sliding-pane';
import { Select, List, message, AutoComplete, Spin } from 'antd';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Async, { useAsync } from 'react-select/async';

export default function TaskDetailsSlider(props) {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
    const antIcon = <LoadingOutlined spin />;
    const ContainerHeight = 400;

    const [state, setState] = useState({
        task: {},
        Task_ID: props.Task_ID,
        users: [],
        contacts: [],
        customers: [],
        shows: [],
        //mode: props.mode,
        /*mode: props.Task_ID === 0 ? "Add" : "Edit",*/
        customerid: props.customerId,
        customername: props.customerName,
        showPane: false,
        formFields: {},
        dataLoaded: false,
        usersListData: [],
        customersListData: [],
        tasks: [],
        taskEditMode: ''
    });

    const { register, getValues, setValue, control, watch } = useForm();
    const snoozeMode = props.snoozeMode;
    const [usersListData, setUsersListData] = useState([]);
    const [customersListData, setCustomersListData] = useState([]);
    const [contactsListData, setContactsListData] = useState([]);
    const [searchResultsLoaded, setSearchResultsLoaded] = useState([]);

    const [showDropdown, setShowDropdown] = useState(false);

    var isDirty = useRef(false);

    const timer = useRef(null);              // Timer identifier
    const waitTime = 1000; //in ms

    //useEffect(() => {
    //    const onKeyDown = (e) => {

    //        const { key } = e;

    //        if (key === 'Enter') {

    //            if (e.target.id === 'rc_select_2') {
    //                e.preventDefault();

    //                setShowDropdown(true);
    //            }
    //        }
    //    }

    //    document.addEventListener('keydown', onKeyDown);

    //    return () => {
    //        document.removeEventListener('keydown', onKeyDown);
    //    }
    //    // eslint-disable-next-line react-hooks/exhaustive-deps
    //}, []);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            //console.log(value);
            //console.log(name);
            //console.log(type);
        });

        return () => subscription.unsubscribe();

    }, []);

    useEffect(() => {

        LoadFormData();

    }, [props]);

    function setTaskEditFields() {
        // Edit mode
        let postdata = {};
        postdata.task_id = props.Task_ID.toString();

        Axios.get(`/api/GetTaskById`, {
            params: {
                task_id: props.Task_ID.toString()
            }
        }).then(response => {
            //setValue("formFields", response.data.request);

            setValue('formFields.contact', response.data.task.contact_id)
            setValue('formFields.company', response.data.company_id);
            setValue('formFields.custname', response.data.custname);
            setValue('formFields.taskDateTime', response.data.task.task_date + ' ' + response.data.task.task_time)
            setValue('formFields.assignedto', response.data.task.scheduled_for)
            setValue('formFields.taskType', response.data.task.task_type)
            if (response.data.task.alarm == 1) {
                setValue('formFields.taskAlarm', response.data.task.alarm_before);
            }
            setValue('formFields.taskPriority', response.data.task.priority);
            setValue('formFields.regarding', response.data.task.regarding);
            setValue('formFields.taskStatus', response.data.task.status);
            setValue('formFields.contactphone', response.data.phone)
            setValue('formFields.contactfax', response.data.fax)

            //shows
            if (response.data.task.showlist !== '') {
                setValue('formFields.shows', response.data.task.showlist.split(",").map(Number));
            }
            else {
                setValue('formFields.shows', []);
            }

            getCompanyContacts(response.data.company_id, userInfo.jobShopDivision);

            //setTimeout(() => setValue('formFields.company', response.data.company_id), 1000);

            //console.log(getValues().formFields);

        }).catch(error => {
            console.log(error);
        });
    }

    function setTaskAddFields() {
        // Add mode
        setValue('formFields.taskType', 'Call')
        setValue('formFields.taskAlarm', '0 min before')
        setValue('formFields.taskPriority', 'Low')
        setValue('formFields.taskDateTime', round(new Date(), Moment.duration(30, "minutes"), "ceil"));

        if (props.selectedCustomerId != null) {
            setValue('formFields.company', props.selectedCustomerId)
            getCompanyContacts(props.selectedCustomerId, userInfo.jobShopDivision);
            setValue('formFields.custname', props.selectedCustomerName);
        }
        else {
            setValue('formFields.company', 'Select Company');
            setValue('formFields.custname', '');
        }
        setValue('formFields.contact', 'Select Contact');
        setValue('formFields.taskStatus', 'Active')
        setValue('formFields.assignedto', userInfo.userId)
        setValue('formFields.regarding', '');
        setValue('formFields.shows', []);
        setContactsListData([]);
    }

    function LoadFormData() {
        let taskEditMode = '';

        setCustomersListData([]);

        let postdata = {};
        Axios.post(`/api/GetTaskUsersAndShows`, postdata
        ).then(response => {
            let usersList = response.data.users;
            let shows = response.data.shows;

            if (props.Task_ID === 0) {
                taskEditMode = 'Add';
                setTaskAddFields();
            } else {
                taskEditMode = 'Edit';
                setTaskEditFields();
            }

            setState({
                ...state,
                Task_ID: props.Task_ID,
                showPane: true,
                //mode: taskEditMode,
                usersListData: usersList,
                shows,
                dataLoaded: true,
                tasks: props.tasks,
                taskEditMode,
            });
            setSearchResultsLoaded(true);
        }).catch(error => {
            console.log(error);
        });
    }

    //function GetTaskUsersAndCustomers(key) {
    //    let taskEditMode = '';

    //    Axios.get(`/api/GetTaskUsersAndCustomers`, {
    //        params: {
                
    //        }
    //    }).then(response => {
    //        let usersList = response.data.users;
    //        //let customersList = response.data.customers;

    //        if (props.Task_ID === 0) {
    //            taskEditMode = 'Add';
    //            setTaskAddFields();
    //        } else {
    //            taskEditMode = 'Edit';
    //            setTaskEditFields();
    //        }

    //        setState({
    //            ...state,
    //            Task_ID: props.Task_ID,
    //            showPane: true,
    //            mode: taskEditMode,
    //            usersListData: usersList,
    //            //customersListData: customersList,
    //            dataLoaded: true,
    //            tasks: props.tasks
    //        });

    //        message.destroy(key);
    //    }).catch(error => {
    //        console.log(error);
    //    });
    //}

    async function getCompanyContacts(company_id, jobshopdivision) {
        let postdata = {};
        postdata.company_id = company_id;

        // if task edit mode, get company contacts for the division stored in the task record, otherwise
        // for add mode, use the current user division.
        if (jobshopdivision !== undefined && jobshopdivision !== '') {
            postdata.jobshopdivision = jobshopdivision;
        } else {
            postdata.jobshopdivision = Globals.userInfo.jobShopDivision;
        }

        await Axios.post(`/api/GetCompanyContacts`, postdata
        ).then(response => {
            let contacts = response.data;
            setContactsListData(contacts);

        }).catch(error => {
            console.log(error);
        });
    }

    function round (date, duration, method) {
        return Moment(Math[method](+date / +duration) * +duration).toDate();
    }

    function handleButtonClick(event) {

        switch (event.target.id) {
            case 'btnCompleteTask':
                handleComplete();
                break;
            case 'btnCancelTask':
                handleCancel();
                break;
            case 'btnSaveChanges':
                handleSave();
                break;
            default: // btnCancelChanges
                props.closeTasksModal();
                break;
        }

    }

    function handleComplete() {
        confirm({
            title: "You are about to complete a task.",
            content: "Are you sure you would like to complete this task?",
            buttons: ["Yes", "No"],
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                let postData = {};
                postData.tasks_id = props.Task_ID;
                postData.taskStatus = 'Completed';

                Axios.post(`/api/UpdateTaskStatus`, postData
                ).then(response => {
                    toast.success("Task has been completed!");

                    console.log(state.tasks);

                    const newTasksList = state.tasks.filter((task) => task.Task_ID !== props.Task_ID);
                    setState({
                        ...state,
                        tasks: newTasksList
                    });

                    console.log(newTasksList);

                    hidePane(newTasksList, 'complete');

                }).catch(error => {

                });
                return 0;
            } else {
                return 0;
                toast.info("Task has NOT been completed.");
            }
        });
    }

    function handleCancel() {
        confirm({
            title: "You are about to cancel a task.",
            content: "Are you sure you would like to cancel this task?",
            buttons: ["Yes", "No"],
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                let postData = {};
                postData.tasks_id = props.Task_ID;
                postData.taskStatus = 'Cancelled';

                Axios.post(`/api/UpdateTaskStatus`, postData
                ).then(response => {
                    toast.success("Task has been cancelled!");

                    const newTasksList = state.tasks.filter((task) => task.Task_ID !== props.Task_ID);
                    setState({
                        ...state,
                        tasks: newTasksList
                    });
                    hidePane(newTasksList, 'cancel');
                    
                }).catch(error => {

                });
                return 0;
            } else {
                return 0;
                toast.info("Task has NOT been cancelled.");
            }
        });
    }

    function closeModal() {
        if (isDirty.current) {
            confirm({
                title: "You are about to close this task and lose any changes you've made.",
                content: "Are you sure you would like continue?",
                buttons: ["Yes", "No"],
            }, (buttonPressed) => {
                if (buttonPressed === 'Yes') {
                    setTimeout(() => { props.hidePane(); }, 1000);

                    hidePane(state.tasks, null);

                    return 0;
                } else {
                    return 0;
                }
            });
        } else {
            setTimeout(() => { props.hidePane(); }, 1000);
            hidePane(state.tasks, null);
        }

        
    }

    function hidePane(newTasksList, taskAction) {
        //now update the parent list
        let selectedIdx = null;
        let tasks = newTasksList;

        if (taskAction === 'update') {
            let myTaskCompany = getValues().formFields.custname;
            const contactIdx = contactsListData.findIndex(con => con.contact_id === getValues().formFields.contact);
            let myTaskContact = contactsListData[contactIdx].jsS_name;

            const selectedIdx = tasks.findIndex(t => t.Task_ID === props.selectedTaskId);
            tasks[selectedIdx].Priority = getValues().formFields.taskPriority;
            tasks[selectedIdx].DateTime = Moment(getValues().formFields.taskDateTime).toDate();
            tasks[selectedIdx].ContactCompany = myTaskCompany + ', ' + myTaskContact
        }

        setState({
            ...state,
            showPane: false
        });


        //for graceful, animated close
        setTimeout(() => props.updateTableDisplay(tasks, taskAction, selectedIdx), 1000);
    }

    function onUserChange(value) {
        setValue('formFields.assignedto', value)
        setState({ ...state });
    }

    function onContactChange(value, item) {
        //console.log(value)
        //console.log(contactsListData[item.index]);

        setValue('formFields.contact', value)

        if (value != "Select Contact") {
            setValue('formFields.contactphone', contactsListData[item.index].phone);
            setValue('formFields.contactfax', contactsListData[item.index].fax);
        }
        setState({ ...state });
    }

    const onTaskDateTimeChange = (value, dateString) => {
        //console.log('Selected Time: ', value);
        //console.log('Formatted Selected Time: ', dateString);

        setValue('formFields.taskDateTime', value);
    };

    function handleShowsChange(value) {
        setValue('formFields.shows', value);
        setState({ ...state });
    }

    function handleSave() {

        //let taskEditMode = '';

        if (getValues().formFields?.assignedto == null) {
            toast.error('Task Must Be Assigned To Someone')
            return;
        }

        if (getValues().formFields?.contact == "Select Contact") {
            toast.error('A Contact Must Be Selected')
            return;
        }

        // the date and time controls use separate instances of javascript date objects
        // this is to pull the date part from the date, and the time part from the time
        let myTaskDateTime = Moment(getValues().formFields.taskDateTime).toDate();

        let month = myTaskDateTime.getMonth() + 1;
        let day = myTaskDateTime.getDate();
        let year = myTaskDateTime.getFullYear();
        let hours = myTaskDateTime.getHours();
        let sorthours = hours;
        let minutes = myTaskDateTime.getMinutes();
        let ampm = hours >= 12 ? 'PM' : 'AM';

        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
        hours = hours % 12;
        hours = hours ? hours : 12;
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;

        let strDate = month + "/" + day + "/" + year;
        let strSortDate = year + month + day;
        let strTime = hours + ':' + minutes + ' ' + ampm;
        let strSortTime = sorthours.toString() + minutes.toString();

        let postData = {};
        postData.task_id = props.Task_ID;
        postData.contact_id = getValues().formFields?.contact;
        postData.division = userInfo.jobShopDivision;
        postData.status = getValues().formFields?.taskStatus;
        postData.task_type = getValues().formFields?.taskType;
        postData.task_date = strDate;
        postData.task_time = strTime;
        postData.sort_date_time = strSortDate + strSortTime
        postData.priority = getValues().formFields?.taskPriority;
        postData.regarding = getValues().formFields?.regarding;
        postData.phone = getValues().formFields?.contactphone;
        postData.fax = getValues().formFields?.contactfax;
        postData.scheduled_for = getValues().formFields?.assignedto;
        postData.scheduled_by = userInfo.userId;
        postData.alarm_before = getValues().formFields?.taskAlarm;
        if (getValues().formFields?.taskAlarm == 'No Alarm')
        {
            postData.alarm = 0;
        }
        else
        {
            postData.alarm = 1;
        }
        postData.showlist = String(getValues().formFields.shows);

        //if (postData.task_id !== 0) {
        //    taskEditMode = 'Edit';
        //}
        //else {
        //    taskEditMode = 'Add';
        //}

        Axios.post(`/api/SaveTask`, postData
        ).then(async response => {

            if (state.taskEditMode == 'Add') {
                hidePane(state.tasks, 'add');

                toast.success('Task Successfully Created!')
            }
            else {
                hidePane(state.tasks, 'update');

                toast.success('Task Successfully Updated!')
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function setSearchTimer(inputValue) {
        clearTimeout(timer.current);

        // Wait for X ms and then process the request
        timer.current = setTimeout(() => {
            loadOptions(inputValue);
        }, waitTime);
    }

    // load options using API call
    function loadOptions(inputValue) {
        if (inputValue.length > 0) {
            setShowDropdown(true);
            setSearchResultsLoaded(false);

            let postdata = {};
            postdata.searchvalue = inputValue;

            Axios.post(`/api/GetCustomersBySearchValue`, postdata
            ).then(response => {

                let cListData = response.data;

                cListData = cListData.map(item => ({
                    ...item,
                    value: item.name
                }));

                setCustomersListData(cListData);

                setSearchResultsLoaded(true);

            }).catch(error => {
                console.log(error);
            });
        } else {
            setCustomersListData([]);
        }
    };

    function handleChange(value) {
        setValue('formFields.contact', 'Select Contact');
        setContactsListData([]);
    }

    function onCompanySelect(value, option) {
        setValue('formFields.company', value)

        if (typeof (option) != "undefined") {
            getCompanyContacts(option.id, userInfo.jobShopDivision);
        }
        else {
            setValue('formFields.company', null);
            setValue('formFields.contact', 'Select Contact');
            setContactsListData([]);
        }

        setShowDropdown(false);
    }

    const { Option } = Select;

    var tempVar = '';
    if (customersListData?.length > 0) {
        tempVar = customersListData[0].name;
    }

    return (
        <>
            <SlidingPane
                className='some-custom-class'
                overlayClassName='showCard'
                isOpen={state.showPane}
                title={state.taskEditMode === "Add" ? 'Add New Task' : 'Edit Task'}
                onRequestClose={closeModal}
                width={'75%'}
            >
                <div className="slide-pane-body scrollableDiv">
                    <div>
                        {state.dataLoaded &&
                            <Fragment>
                                <div className="row">
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">Assigned To</label><br />
                                        <Select
                                        {...register("formFields.assignedto")}
                                        size="large" placeholder="Select Assigned To"
                                        showSearch
                                        allowClear={true}
                                        optionFilterProp="children"
                                        onChange={onUserChange}
                                        filterOption={(input, option) =>
                                            option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || false
                                        }
                                        value={getValues().formFields?.assignedto}
                                        disabled={userInfo.userType == 'admin' ? false : true}
                                        >
                                            <option value='Select Assign To' />
                                            {state.usersListData?.length > 0 && state.usersListData?.map((user, idx) => <option index={idx} key={user.id} value={user.id}>{user.username}</option>)}
                                        </Select>
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">Status</label><br />
                                        <label className="form-label">{getValues().formFields?.taskStatus}</label>
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">Scheduled By</label><br />
                                        <label className="form-label">{userInfo.userName}</label>
                                    </div>

                                    <div className="form-group col-lg-3">
                                        <label className="form-label">Task Date/Time</label>
                                        <Controller
                                            name="formFields.taskDateTime"
                                            control={control}
                                            setValue={setValue}
                                            defaultValue={getValues().formFields?.taskDateTime ? Moment(getValues().formFields.taskDateTime).toDate() : null}
                                            render={() =>
                                                <DatePicker
                                                    selected={getValues().formFields?.taskDateTime ? Moment(getValues().formFields.taskDateTime).toDate() : null}
                                                    onChange={onTaskDateTimeChange}
                                                    className="form-control-custom"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    dateFormat="yyyy-MM-dd hh:mm:ss"
                                                    showTimeSelect
                                                    timeIntervals={30}
                                                    showTime={{
                                                        format: 'HH:mm',
                                                    }}
                                                />
                                            }
                                        />
                                    </div>
                                    <div className="form-group col-lg-3">
                                        <label className="form-label">Type</label>
                                        <select className="form-control-custom" {...register("formFields.taskType")}>
                                            <option value="Call">Call</option>
                                            <option value="Meeting">Meeting</option>
                                            <option value="ToDo">To Do</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-lg-3">
                                        <label className="form-label">Alarm</label>
                                        {snoozeMode ?
                                            <select className="form-control-custom" {...register("formFields.taskAlarm")}>
                                                <option value="No Alarm">No Alarm</option>
                                                <option value="10 minutes">10 minutes</option>
                                                <option value="30 minutes">30 minutes</option>
                                                <option value="1 hour">1 hour</option>
                                                <option value="2 hours">2 hours</option>
                                                <option value="4 hours">4 hours</option>
                                                <option value="1 day">1 day</option>
                                                <option value="1 week">1 week</option>
                                            </select>
                                            :
                                            <select className="form-control-custom" {...register("formFields.taskAlarm")}>
                                                <option value="No Alarm">No Alarm</option>
                                                <option value="0 min before">0 min before</option>
                                                <option value="5 min before">5 min before</option>
                                                <option value="15 min before">15 min before</option>
                                                <option value="1 hr before">1 hr before</option>
                                                <option value="1 day before">1 day before</option>
                                            </select>
                                        }
                                    </div>
                                    <div className="form-group col-lg-3">
                                        <label className="form-label">Priority</label>
                                        <select className="form-control-custom" {...register("formFields.taskPriority")}>
                                            <option value="Low">Low</option>
                                            <option value="Medium">Medium</option>
                                            <option value="High">High</option>
                                        </select>
                                    </div>

                                    <div className="form-group col-lg-6">
                                        <label className="form-label">Company</label><br />

                                            {state.taskEditMode === "Add" && props.selectedCustomerId === null?
                                            <fragment>
                                            <AutoComplete dropdownClassName="certain-category-search-dropdown"
                                                onSelect={onCompanySelect}
                                                onChange={handleChange}
                                                dropdownMatchSelectWidth={500}
                                                style={{
                                                    width: '100%',
                                                }}
                                                onSearch={setSearchTimer}
                                                placeholder="Search Customers"
                                                options={customersListData}
                                                notFoundContent={searchResultsLoaded ? "No Results Found" : <div><Spin indicator={antIcon} /> Searching...</div>}
                                                open={showDropdown}
                                                onFocus={() => setShowDropdown(true)}
                                                onBlur={() => setShowDropdown(false)}
                                                />
                                            </fragment>
                                            :
                                            <fragment>
                                                <label className="form-label color-dark-blue">{getValues().formFields.custname}</label>
                                            </fragment>
                                        }
                                    </div>

                                    <div className="form-group col-lg-6">
                                        <label className="form-label">Contact</label><br />
                                        <Select
                                            {...register("formFields.contact")}
                                            size="large" placeholder="Select Contact"
                                            style={{ width: 400 }}
                                            showSearch
                                            allowClear={true}
                                            optionFilterProp="children"
                                            onChange={onContactChange}
                                            filterOption={(input, option) =>
                                                option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || false
                                            }
                                            value={getValues().formFields?.contact}
                                            disabled={contactsListData?.length > 0 ? false : true}
                                        >
                                            <option value='Select Contact' />
                                            {contactsListData?.length > 0 && contactsListData?.map((contact, idx) => <option index={idx} key={contact.contact_id} value={contact.contact_id}>{contact.jsS_name}</option>)}
                                        </Select><br />
                                        {getValues().formFields?.contact != "Select Contact" ?
                                            <div>
                                            Phone: {getValues().formFields?.contactphone}&nbsp;&nbsp;&nbsp;Fax: {getValues().formFields?.contactfax}
                                            </div>
                                            :
                                            <div></div>
                                        }
                                    </div>

                                    {userInfo.jobShopDivision === 'JSS' &&
                                        <div className="form-group col-12">
                                            <label className="form-label">Relate Task To One Or More Shows</label>
                                            <Select id="ddSShows"
                                                value={getValues().formFields?.shows}
                                                className="form-control-custom"
                                                mode="multiple"
                                                placeholder="Select one or more Shows"
                                                onChange={handleShowsChange}
                                                className="form-control-custom w-100"
                                                bordered={false}
                                            >
                                                {state.shows.map((show) => <Option key={show.showcode} value={show.showcode}>{show.name}</Option>)}
                                            </Select>
                                        </div>
                                    }
                              
                                    <div className="row">
                                        <section className="col col-xs-12">
                                            <label className="input">Regarding &nbsp;</label>

                                            <textarea style={{ width: '100%' }} rows="4" placeholder="Regarding" {...register("formFields.regarding")} />
                                        </section>
                                    </div>
                                </div>
                            </Fragment>
                        }
                    </div>
                </div>

                <div className="modal-footer">
                    <div className="form-group col-12 padding-25-10">
                        <Button id="btnSaveChanges" onClick={handleSave} className="btn btn-submit me-3" style={{ 'marginLeft': '5px' }}>Save Changes</Button>
{/*                        <Button id="btnCancelChanges" className="btn btn-submit me-3" onClick={handleButtonClick}>Cancel Changes</Button>*/}
                        {state.taskEditMode === "Edit" ?
                            <fragment>
                                <Button id="btnCompleteTask" onClick={handleButtonClick} className="btn btn-submit me-3">Complete Task</Button>
                                <Button id="btnCancelTask" onClick={handleButtonClick} className="btn btn-submit me-3">Cancel Task</Button>
                            </fragment>
                            :
                            <fragment></fragment>
                        }
                        <button className="btn btn-outline-primary me-3" onClick={closeModal}>Close</button>
                    </div>
                </div>

            </SlidingPane>
        </>
    );
}