export function convertHtmlToText(html) {

    var reg = /\<body[^>]*\>([^]*)\<\/body/m;
    var bodyText = html;
    if (html?.match(reg)) {
        bodyText = html.match(reg)[1];
    }

    bodyText = bodyText?.replace(/&nbsp;/g, ' ');

    bodyText = bodyText?.replace('.</p>', '. ');
    bodyText = bodyText?.replace('. </p>', '. ');
    bodyText = bodyText?.replace(',</p>', ', ');
    bodyText = bodyText?.replace(', </p>', ', ');

    bodyText = bodyText?.replace('</p>', '. ');

    bodyText = bodyText?.replace(/<[^>]*>/g, ' ');

    bodyText = bodyText?.replace(/\s+/g, ' ');

    return bodyText.trim();
}

export function isHTML(str) {
    var doc = new DOMParser().parseFromString(str, "text/html");
    return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
}