import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Select, Empty, Spin, Alert, Transfer, Modal, message } from 'antd';
import SavedQueriesSlider from './SavedQueriesSlider';
import List from 'react-list-select'

export default function QueryBuilderJSS(props) {
    const navigate = useNavigate();
    const location = useLocation();

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const { register, getValues, setValue, control, watch } = useForm();

    const [tableControl, setTableControl] = useState({
        page: 1,
        sizePerPage: 10
    });

    const [state, setState] = useState({
        showSavedQueriesSlider: false,
        selectedOutputFields: [],
        shows: []
    });

    const [initializingForm, setInitializingForm] = useState(true);

    const exhibitorFields = ['Select Field', 'Show', 'Sales Status', 'Source', 'Service Category', 'Sales Rep', 'State', 'Zip Code', 'Package Type', 'Total Package Amount',
        'Web Link', 'Pre Reg List', 'Exp. Co. Description', 'Email Campaign', 'Lead Retrieval'];

    const prospectFields = ['Select Field', 'Show', 'Sales Status', 'Source', 'Service Category', 'State', 'Sales Rep'];

    const operators = ['Select Operator', 'Starts With', 'Includes', 'Equals', 'Not Equal', 'Blank', 'Not Blank', 'Greater Than', 'Greater Than or Equal To', 'Less Than', 'Less Than or Equal To'];

    const limitedOperators = ['Select Operator', 'Starts With', 'Includes', 'Equals', 'Not Equal', 'Blank', 'Not Blank'];

    const showOperators = ['Equals'];

    const salesRepOperators = ['Equals'];

    let outputFields = [
        'Company Name',
        'Contact Name',
        'Contact First Name',
        'Contact Last Name',
        'Address',
        'Address 2',
        'City',
        'State',
        'Zip',
        'Phone',
        'Fax',
        'Email',
        'Domain',
        'Booth 1',
        'Sales Rep',
        'MyJSS Password',
        'Shows Completed',
        'Maj Cat',
        '2nd Cat',
        '3rd Cat',
        '4th Cat',
        '5th Cat',
        'Source'
    ]

    const [queryNameModal, setQueryNameModal] = useState({
        show: false
    });

    const [dedupChecked, setDedupChecked] = useState(false);
    const [logicalOrStateChecked, setLogicalOrStateChecked] = useState(false);
    const [logicalOrSourceChecked, setLogicalOrSourceChecked] = useState(false);

    useEffect(() => {
        setValue('formFields.searchTarget', 'Exhibitors');

        let postdata = {};
        Axios.post(`/api/InitializeQueryBuilder`, postdata
        ).then(response => {
            let shows = response.data.shows;
            let salesReps = response.data.salesReps;

            setValue('formFields.shows', shows);
            setValue('formFields.salesReps', salesReps);

            InitializeFormFields();
        }).catch(error => {
            console.log(error);
        });

    }, [props]);

    useEffect(() => {
        if (initializingForm === false) {
            if (location.state.querytopreload !== null) {
                LoadSelectedQuery(location.state.querytopreload);
            }
        }
    }, [initializingForm])

    function InitializeFormFields() {
        let mySelectedSearchTarget = getValues().formFields.searchTarget;

        if (getValues().formFields.searchTarget === 'Exhibitors') {
            setValue('formFields.Search1Fields', exhibitorFields);
            setValue('formFields.Search2Fields', exhibitorFields);
            setValue('formFields.Search3Fields', exhibitorFields);
            setValue('formFields.Search4Fields', exhibitorFields);
            setValue('formFields.Search5Fields', exhibitorFields);
            setValue('formFields.Search6Fields', exhibitorFields);
        }
        else {
            setValue('formFields.Search1Fields', prospectFields);
            setValue('formFields.Search2Fields', prospectFields);
            setValue('formFields.Search3Fields', prospectFields);
            setValue('formFields.Search4Fields', prospectFields);
            setValue('formFields.Search5Fields', prospectFields);
            setValue('formFields.Search6Fields', prospectFields);
        }

        setValue('formFields.Operator1Fields', showOperators);
        setValue('formFields.Operator2Fields', showOperators);
        setValue('formFields.Operator3Fields', showOperators);
        setValue('formFields.Operator4Fields', showOperators);
        setValue('formFields.Operator5Fields', showOperators);
        setValue('formFields.Operator6Fields', showOperators);

        setValue('formFields.searchField1', 'Select Field');
        setValue('formFields.searchField2', 'Select Field');
        setValue('formFields.searchField3', 'Select Field');
        setValue('formFields.searchField4', 'Select Field');
        setValue('formFields.searchField5', 'Select Field');
        setValue('formFields.searchField6', 'Select Field');

        setValue('formFields.operator1', 'Select Operator');
        setValue('formFields.operator2', 'Select Operator');
        setValue('formFields.operator3', 'Select Operator');
        setValue('formFields.operator4', 'Select Operator');
        setValue('formFields.operator5', 'Select Operator');
        setValue('formFields.operator6', 'Select Operator');

        setValue('formFields.searchCriteria1', '');
        setValue('formFields.searchCriteria2', '');
        setValue('formFields.searchCriteria3', '');
        setValue('formFields.searchCriteria4', '');
        setValue('formFields.searchCriteria5', '');
        setValue('formFields.searchCriteria6', '');

        setValue('formFields.searchCriteriaDisplayMode1', 'Text');
        setValue('formFields.searchCriteriaDisplayMode2', 'Text');
        setValue('formFields.searchCriteriaDisplayMode3', 'Text');
        setValue('formFields.searchCriteriaDisplayMode4', 'Text');
        setValue('formFields.searchCriteriaDisplayMode5', 'Text');
        setValue('formFields.searchCriteriaDisplayMode6', 'Text');

        setValue('formFields.selectedShows1', []);
        setValue('formFields.selectedShows2', []);
        setValue('formFields.selectedShows3', []);
        setValue('formFields.selectedShows4', []);
        setValue('formFields.selectedShows5', []);
        setValue('formFields.selectedShows6', []);

        setValue('formFields.selectedOutputFields', '');

        setValue('formFields.contactType', 'Main');
        setValue('formFields.fileMode', 'CreateNew');
        setValue('formFields.searchTarget', mySelectedSearchTarget);

        setValue('formFields.loadedQueryName', '');
        setValue('formFields.loadedQueryId', null);

        setDedupChecked(false);
        setLogicalOrStateChecked(false);
        setLogicalOrSourceChecked(false);

        setValue('savedFormFields', getValues().formFields);

        setState({
            ...state,
            showSavedQueriesSlider: false,
            selectedOutputFields: []
        });

        setInitializingForm(false);
    }

    const handleSearchTargetClick = (event, param) => {
        setValue('formFields.searchTarget', param);

        InitializeFormFields();

        //setState({
        //    ...state,
        //    showSavedQueriesSlider: false
        //});
    };

    const handleSaveQueryModeClick = (event, param) => {
        setValue('saveQueryMode', param);

        setState({
            ...state,
            showSavedQueriesSlider: false
        });
    };

    const handleFileModeClick = (event, param) => {
        setValue('formFields.fileMode', param);

        setState({
            ...state,
            showSavedQueriesSlider: false
        });
    };

    const { Option } = Select;

    function handleSearchFieldChange(value, searchFieldIndex) {
        let mySearchField = 'formFields.searchField' + searchFieldIndex;
        let mySearchCriteria1DisplayMode = 'formFields.searchCriteriaDisplayMode' + searchFieldIndex;
        let myOperatorFieldValues = 'formFields.Operator' + searchFieldIndex + 'Fields';
        let myOperatorField = 'formFields.operator' + searchFieldIndex;

        //formFields.operator2

        setValue(mySearchField, value);
        if (value === 'Select Field') {
            setValue(myOperatorField, 'Select Operator');
        }

        if (value === 'Show') {
            setValue(myOperatorFieldValues, showOperators);
            setValue(mySearchCriteria1DisplayMode, 'ShowSelector');
        }
        else if (value === 'Sales Rep') {
            setValue(myOperatorFieldValues, salesRepOperators);
            setValue(mySearchCriteria1DisplayMode, 'SalesRepSelector');
        }
        else {
            setValue(myOperatorFieldValues, limitedOperators);
            setValue(mySearchCriteria1DisplayMode, 'Text');
        }

        setState({
            ...state,
            showSavedQueriesSlider: false
        });
    }

    function handleOperatorFieldChange(value, operatorFieldIndex) {
        let myOperatorField = 'formFields.operator' + operatorFieldIndex;

        setValue(myOperatorField, value);

        setState({
            ...state,
            showSavedQueriesSlider: false
        });
    }

    function handleShowSelectorFieldChange(value, fieldIndex) {
        let mySelectedShowsField = 'formFields.selectedShows' + fieldIndex;

        setValue(mySelectedShowsField, value);
        setState({ ...state });
    }

    function handleSalesRepSelectorFieldChange(value, fieldIndex) {
        let mySelectedSalesRepsField = 'formFields.selectedSalesReps' + fieldIndex;

        setValue(mySelectedSalesRepsField, value);

        setState({ ...state });
    }

    function handleSaveQueryClick() {
        if (getValues().formFields.loadedQueryId === null) {
            //No query loaded so prompt user for new name to save as
            setValue('formFields.newQueryName', '');

            setQueryNameModal({
                ...queryNameModal,
                show: true
            });
        }
        else {
            if (JSON.stringify(getValues().formFields) === JSON.stringify(getValues().savedFormFields)) {
                toast.info("No Changes Made");
            }
            else {
                confirm({
                    title: "The query you are working with was loaded from you saved queries",
                    content: "Do you want to update this saved query or save it as a new one?",
                    buttons: ["Update Loaded Query", "Save As New Query"],
                    theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                }, (buttonPressed) => {
                    if (buttonPressed === 'Update Loaded Query') {
                        
                        //Update existing query
                        SaveQuery();

                        return 0;
                    } else {

                        //Save as a new query
                        setValue('formFields.loadedQueryId', null);

                        setValue('formFields.newQueryName', '');

                        setQueryNameModal({
                            ...queryNameModal,
                            show: true
                        });

                        return 0;
                    }
                });
            }
        }
    }

    function handleLoadSavedQuery() {
        InitializeFormFields();

        setState({
            ...state,
            selectedOutputFields: [],
            showSavedQueriesSlider: true
        });
    }

    function ResetSearchCriteria() {
        InitializeFormFields();

        toast.info("All selections have been reset to default");
    }

    function handleContactTypeChange(value) {
        setValue('formFields.contactType', value);

        setState({
            ...state,
            showSavedQueriesSlider: false
        });
    }

    function LoadSelectedQuery(q) {
        let mySelectedOutputFieldsArray = [];
        let myOutputFieldsArray = [];

        if (q.type === "E") {
            setValue('formFields.searchTarget', 'Exhibitors');
        }
        else {
            setValue('formFields.searchTarget', 'Prospects');
        }

        setValue('formFields.deDupOnCompanyId', q.dedupOnCompanyId);
        setValue('formFields.useLogicalOrForState', q.useLogicalOrForState);
        setValue('formFields.useLogicalOrForSource', q.useLogicalOrForSource);

        setValue('formFields.contactType', q.contactType);

        setValue('formFields.searchField1', q.cmbSearchField1.replace('Show Code', 'Show'));
        setValue('formFields.searchField2', q.cmbSearchField2.replace('Show Code', 'Show'));
        setValue('formFields.searchField3', q.cmbSearchField3.replace('Show Code', 'Show'));
        setValue('formFields.searchField4', q.cmbSearchField4.replace('Show Code', 'Show'));
        setValue('formFields.searchField5', q.cmbSearchField5.replace('Show Code', 'Show'));
        setValue('formFields.searchField6', q.cmbSearchField6.replace('Show Code', 'Show'));

        //This logic handles the existing queries in the DB 
        if (q.cmbSearchField1 === 'Select Field') {
            setValue('formFields.operator1', 'Select Operator');
        }
        else {
            setValue('formFields.operator1', q.cmbOperator1);
        }
        if (q.cmbSearchField2 === 'Select Field') {
            setValue('formFields.operator2', 'Select Operator');
        }
        else {
            setValue('formFields.operator2', q.cmbOperator1);
        }
        if (q.cmbSearchField3 === 'Select Field') {
            setValue('formFields.operator3', 'Select Operator');
        }
        else {
            setValue('formFields.operator3', q.cmbOperator1);
        }
        if (q.cmbSearchField4 === 'Select Field') {
            setValue('formFields.operator4', 'Select Operator');
        }
        else {
            setValue('formFields.operator4', q.cmbOperator1);
        }
        if (q.cmbSearchField5 === 'Select Field') {
            setValue('formFields.operator5', 'Select Operator');
        }
        else {
            setValue('formFields.operator5', q.cmbOperator1);
        }
        if (q.cmbSearchField6 === 'Select Field') {
            setValue('formFields.operator6', 'Select Operator');
        }
        else {
            setValue('formFields.operator6', q.cmbOperator1);
        }

        //setValue('formFields.operator1', q.cmbOperator1);
        //setValue('formFields.operator2', q.cmbOperator2);
        //setValue('formFields.operator3', q.cmbOperator3);
        //setValue('formFields.operator4', q.cmbOperator4);
        //setValue('formFields.operator5', q.cmbOperator5);
        //setValue('formFields.operator6', q.cmbOperator6);

        if (q.cmbSearchField1 === 'Show Code' || q.cmbSearchField1 === 'Show') {
            setValue('formFields.selectedShows1', q.txtSearchCriteria1.split(" || ").map(Number));
            setValue('formFields.searchCriteriaDisplayMode1', 'ShowSelector');
        }
        else if (q.cmbSearchField1 === 'Sales Rep') {
            setValue('formFields.selectedSalesReps1', q.txtSearchCriteria1.split(" || ").map(String));
            setValue('formFields.searchCriteriaDisplayMode1', 'SalesRepSelector');
        }
        else {
            setValue('formFields.searchCriteria1', q.txtSearchCriteria1);
            setValue('formFields.searchCriteriaDisplayMode1', 'Text');
        }

        if (q.cmbSearchField2 === 'Show Code' || q.cmbSearchField2 === 'Show') {
            setValue('formFields.selectedShows2', q.txtSearchCriteria2.split(" || ").map(Number));
            setValue('formFields.searchCriteriaDisplayMode2', 'ShowSelector');
        }
        else if (q.cmbSearchField2 === 'Sales Rep') {
            setValue('formFields.selectedSalesReps2', q.txtSearchCriteria2.split(" || ").map(String));
            setValue('formFields.searchCriteriaDisplayMode2', 'SalesRepSelector');
        }
        else {
            setValue('formFields.searchCriteria2', q.txtSearchCriteria2);
            setValue('formFields.searchCriteriaDisplayMode2', 'Text');
        }

        if (q.cmbSearchField3 === 'Show Code' || q.cmbSearchField3 === 'Show') {
            setValue('formFields.selectedShows3', q.txtSearchCriteria3.split(" || ").map(Number));
            setValue('formFields.searchCriteriaDisplayMode3', 'ShowSelector');
        }
        else if (q.cmbSearchField3 === 'Sales Rep') {
            setValue('formFields.selectedSalesReps3', q.txtSearchCriteria3.split(" || ").map(String));
            setValue('formFields.searchCriteriaDisplayMode3', 'SalesRepSelector');
        }
        else {
            setValue('formFields.searchCriteria3', q.txtSearchCriteria3);
            setValue('formFields.searchCriteriaDisplayMode3', 'Text');
        }

        if (q.cmbSearchField4 === 'Show Code' || q.cmbSearchField4 === 'Show') {
            setValue('formFields.selectedShows4', q.txtSearchCriteria4.split(" || ").map(Number));
            setValue('formFields.searchCriteriaDisplayMode4', 'ShowSelector');
        }
        else if (q.cmbSearchField4 === 'Sales Rep') {
            setValue('formFields.selectedSalesReps4', q.txtSearchCriteria4.split(" || ").map(String));
            setValue('formFields.searchCriteriaDisplayMode4', 'SalesRepSelector');
        }
        else {
            setValue('formFields.searchCriteria4', q.txtSearchCriteria4);
            setValue('formFields.searchCriteriaDisplayMode4', 'Text');
        }

        if (q.cmbSearchField5 === 'Show Code' || q.cmbSearchField5 === 'Show') {
            setValue('formFields.selectedShows5', q.txtSearchCriteria5.split(" || ").map(Number));
            setValue('formFields.searchCriteriaDisplayMode5', 'ShowSelector');
        }
        else if (q.cmbSearchField5 === 'Sales Rep') {
            setValue('formFields.selectedSalesReps5', q.txtSearchCriteria5.split(" || ").map(String));
            setValue('formFields.searchCriteriaDisplayMode5', 'SalesRepSelector');
        }
        else {
            setValue('formFields.searchCriteria5', q.txtSearchCriteria5);
            setValue('formFields.searchCriteriaDisplayMode5', 'Text');
        }

        if (q.cmbSearchField6 === 'Show Code' || q.cmbSearchField6 === 'Show') {
            setValue('formFields.selectedShows6', q.txtSearchCriteria6.split(" || ").map(Number));
            setValue('formFields.searchCriteriaDisplayMode6', 'ShowSelector');
        }
        else if (q.cmbSearchField6 === 'Sales Rep') {
            setValue('formFields.selectedSalesReps6', q.txtSearchCriteria6.split(" || ").map(String));
            setValue('formFields.searchCriteriaDisplayMode6', 'SalesRepSelector');
        }
        else {
            setValue('formFields.searchCriteria6', q.txtSearchCriteria6);
            setValue('formFields.searchCriteriaDisplayMode6', 'Text');
        }

        if (q.id !== 0) {
            setValue('formFields.loadedQueryName', 'Loaded Query: ' + q.name.replace("Loaded Query: ", ""));
            setValue('formFields.loadedQueryId', q.id);
            //setValue('formFields.selectedOutputFields', q.dataFields);
        }

        setDedupChecked(q.dedupOnCompanyId === 1 ? true : false);
        setLogicalOrStateChecked(q.useLogicalOrForState === 1 ? true : false);
        setLogicalOrSourceChecked(q.useLogicalOrForSource === 1 ? true : false);

        //Load selected output fields (numeric index) array for the react-list selections
        let myOutputFields = q.dataFields.split("|");
        for (var i = 0; i < outputFields.length; i++) {
            for (var j = 0; j < myOutputFields.length; j++) {
                if (myOutputFields[j] === outputFields[i]) {
                    mySelectedOutputFieldsArray.push(i);
                }
            }
        }

        //Now load the selected output field (string values) to be used on export
        let mySelectedOutputFields = "";

        for (var i = 0; i < outputFields.length; i++) {
            for (var j = 0; j < myOutputFields.length; j++) {
                if (outputFields[i] === myOutputFields[j]) {
                    if (mySelectedOutputFields === "") {
                        mySelectedOutputFields = outputFields[i];
                    }
                    else {
                        mySelectedOutputFields += "|" + outputFields[i];
                    }
                }
            }
        }
        setValue('formFields.selectedOutputFields', mySelectedOutputFields);

        setValue('savedFormFields', getValues().formFields);

        setState({
            ...state,
            showSavedQueriesSlider: false,
            selectedOutputFields: mySelectedOutputFieldsArray
        });
    }

    function SaveQuery() {
        let myQueryType = "";

        let postdata = {};

        postdata.UserId = userInfo.userId;
        if (getValues().formFields.searchTarget === 'Exhibitors') {
            myQueryType = "E";
        }
        else {
            myQueryType = "P";
        }
        postdata.type = myQueryType;

        //Either load the searchCriteria into a text box (when field is not 'Show') or antd Select (when field is 'Show') or other antd Select (when field is 'Sales Rep')
        if (getValues().formFields.searchCriteriaDisplayMode1 === 'Text') {
            postdata.txtSearchCriteria1 = getValues().formFields.searchCriteria1;
        }
        else if (getValues().formFields.searchCriteriaDisplayMode1 === 'ShowSelector') {
            let myShowCodeStr = '';
            for (var i = 0; i < getValues().formFields.selectedShows1.length; i++) {
                myShowCodeStr += getValues().formFields.selectedShows1[i] + ' || ';
            }
            myShowCodeStr = myShowCodeStr.slice(0, -4);
            postdata.txtSearchCriteria1 = myShowCodeStr;
        }
        else {
            let mySalesRepStr = '';
            for (var i = 0; i < getValues().formFields.selectedSalesReps1.length; i++) {
                mySalesRepStr += getValues().formFields.selectedSalesReps1[i] + ' || ';
            }
            mySalesRepStr = mySalesRepStr.slice(0, -4);
            postdata.txtSearchCriteria1 = mySalesRepStr;
        }

        if (getValues().formFields.searchCriteriaDisplayMode2 === 'Text') {
            postdata.txtSearchCriteria2 = getValues().formFields.searchCriteria2;
        }
        else if (getValues().formFields.searchCriteriaDisplayMode2 === 'ShowSelector') {
            let myShowCodeStr = '';
            for (var i = 0; i < getValues().formFields.selectedShows2.length; i++) {
                myShowCodeStr += getValues().formFields.selectedShows2[i] + ' || ';
            }
            myShowCodeStr = myShowCodeStr.slice(0, -4);
            postdata.txtSearchCriteria2 = myShowCodeStr;
        }
        else {
            let mySalesRepStr = '';
            for (var i = 0; i < getValues().formFields.selectedSalesReps2.length; i++) {
                mySalesRepStr += getValues().formFields.selectedSalesReps2[i] + ' || ';
            }
            mySalesRepStr = mySalesRepStr.slice(0, -4);
            postdata.txtSearchCriteria2 = mySalesRepStr;
        }

        if (getValues().formFields.searchCriteriaDisplayMode3 === 'Text') {
            postdata.txtSearchCriteria3 = getValues().formFields.searchCriteria3;
        }
        else if (getValues().formFields.searchCriteriaDisplayMode3 === 'ShowSelector') {
            let myShowCodeStr = '';
            for (var i = 0; i < getValues().formFields.selectedShows3.length; i++) {
                myShowCodeStr += getValues().formFields.selectedShows3[i] + ' || ';
            }
            myShowCodeStr = myShowCodeStr.slice(0, -4);
            postdata.txtSearchCriteria3 = myShowCodeStr;
        }
        else {
            let mySalesRepStr = '';
            for (var i = 0; i < getValues().formFields.selectedSalesReps3.length; i++) {
                mySalesRepStr += getValues().formFields.selectedSalesReps3[i] + ' || ';
            }
            mySalesRepStr = mySalesRepStr.slice(0, -4);
            postdata.txtSearchCriteria3 = mySalesRepStr;
        }

        if (getValues().formFields.searchCriteriaDisplayMode4 === 'Text') {
            postdata.txtSearchCriteria4 = getValues().formFields.searchCriteria4;
        }
        else if (getValues().formFields.searchCriteriaDisplayMode4 === 'ShowSelector') {
            let myShowCodeStr = '';
            for (var i = 0; i < getValues().formFields.selectedShows4.length; i++) {
                myShowCodeStr += getValues().formFields.selectedShows4[i] + ' || ';
            }
            myShowCodeStr = myShowCodeStr.slice(0, -4);
            postdata.txtSearchCriteria4 = myShowCodeStr;
        }
        else {
            let mySalesRepStr = '';
            for (var i = 0; i < getValues().formFields.selectedSalesReps4.length; i++) {
                mySalesRepStr += getValues().formFields.selectedSalesReps4[i] + ' || ';
            }
            mySalesRepStr = mySalesRepStr.slice(0, -4);
            postdata.txtSearchCriteria4 = mySalesRepStr;
        }

        if (getValues().formFields.searchCriteriaDisplayMode5 === 'Text') {
            postdata.txtSearchCriteria5 = getValues().formFields.searchCriteria5;
        }
        else if (getValues().formFields.searchCriteriaDisplayMode5 === 'ShowSelector') {
            let myShowCodeStr = '';
            for (var i = 0; i < getValues().formFields.selectedShows5.length; i++) {
                myShowCodeStr += getValues().formFields.selectedShows5[i] + ' || ';
            }
            myShowCodeStr = myShowCodeStr.slice(0, -4);
            postdata.txtSearchCriteria5 = myShowCodeStr;
        }
        else {
            let mySalesRepStr = '';
            for (var i = 0; i < getValues().formFields.selectedSalesReps5.length; i++) {
                mySalesRepStr += getValues().formFields.selectedSalesReps5[i] + ' || ';
            }
            mySalesRepStr = mySalesRepStr.slice(0, -4);
            postdata.txtSearchCriteria5 = mySalesRepStr;
        }

        if (getValues().formFields.searchCriteriaDisplayMode6 === 'Text') {
            postdata.txtSearchCriteria6 = getValues().formFields.searchCriteria6;
        }
        else if (getValues().formFields.searchCriteriaDisplayMode6 === 'ShowSelector') {
            let myShowCodeStr = '';
            for (var i = 0; i < getValues().formFields.selectedShows6.length; i++) {
                myShowCodeStr += getValues().formFields.selectedShows6[i] + ' || ';
            }
            myShowCodeStr = myShowCodeStr.slice(0, -4);
            postdata.txtSearchCriteria6 = myShowCodeStr;
        }
        else {
            let mySalesRepStr = '';
            for (var i = 0; i < getValues().formFields.selectedSalesReps6.length; i++) {
                mySalesRepStr += getValues().formFields.selectedSalesReps6[i] + ' || ';
            }
            mySalesRepStr = mySalesRepStr.slice(0, -4);
            postdata.txtSearchCriteria6 = mySalesRepStr;
        }

        postdata.cmbSearchField1 = getValues().formFields.searchField1.replace('Show', 'Show Code');
        postdata.cmbSearchField2 = getValues().formFields.searchField2.replace('Show', 'Show Code');
        postdata.cmbSearchField3 = getValues().formFields.searchField3.replace('Show', 'Show Code');
        postdata.cmbSearchField4 = getValues().formFields.searchField4.replace('Show', 'Show Code');
        postdata.cmbSearchField5 = getValues().formFields.searchField5.replace('Show', 'Show Code');
        postdata.cmbSearchField6 = getValues().formFields.searchField6.replace('Show', 'Show Code');

        postdata.cmbOperator1 = getValues().formFields.operator1;
        postdata.cmbOperator2 = getValues().formFields.operator2;
        postdata.cmbOperator3 = getValues().formFields.operator3;
        postdata.cmbOperator4 = getValues().formFields.operator4;
        postdata.cmbOperator5 = getValues().formFields.operator5;
        postdata.cmbOperator6 = getValues().formFields.operator6;

        postdata.dedupOnCompanyId = dedupChecked ? 1 : 0;
        postdata.useLogicalOrForState = logicalOrStateChecked ? 1 : 0;
        postdata.useLogicalOrForSource = logicalOrSourceChecked ? 1 : 0;

        postdata.contactType = getValues().formFields.contactType;

        postdata.dataFields = getValues().formFields.selectedOutputFields;

        if (getValues().formFields.loadedQueryId === null) {
            postdata.name = getValues().formFields.newQueryName;

            Axios.post('/api/InsertQueryBuilder', postdata
            ).then(response => {
                toast.success('New query successfully saved');

                setValue('formFields.loadedQueryName', 'Loaded Query: ' + getValues().formFields.newQueryName);
                setValue('formFields.loadedQueryId', response.data);

                setState({
                    ...state,
                    showSavedQueriesSlider: false
                });

                setQueryNameModal({
                    ...queryNameModal,
                    show: false
                });
            }).catch(error => {
                console.log(error);
            });

        }
        else {
            postdata.id = getValues().formFields.loadedQueryId;
            postdata.name = getValues().formFields.loadedQueryName.replace("Loaded Query: ", "");

            Axios.post('/api/UpdateQueryBuilder', postdata
            ).then(response => {
                toast.success('Changes to query successfully saved');

            }).catch(error => {
                console.log(error);
            });
        }
    }

    function handleDedupChecked() {
        setDedupChecked(!dedupChecked);
    }

    function handleLogicalOrStateChecked() {
        setLogicalOrStateChecked(!logicalOrStateChecked);
    }

    function handleLogicalOrSourceChecked() {
        setLogicalOrSourceChecked(!logicalOrSourceChecked);
    }

    function exportToCSVFile() {
        var myFileName;

        let queryErrorsFound = isValidQuery();
        if (queryErrorsFound !== "") {
            var queryErrorContent = <div dangerouslySetInnerHTML={{ __html: queryErrorsFound }} />

            toast.error(queryErrorContent);
            return;
        }

        const key = "loading";

        message.loading({
            content: 'CSV Export in progress...',
            key,
            duration: 0,
            style: {
                position: "fixed",
                bottom: 0,
                left: 0
            },
        });

        //We don't know if query has been saved so use the values from screen fields to run lookup
        let postdata = getPostData();

        myFileName = "JSS_QueryBuilder_Search_Results";
        Axios.post(`/api/DownloadQueryBuilderSearchResults_CSV`, postdata, {
            responseType: 'blob'
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = myFileName + ".csv";
            a.click();
            window.URL.revokeObjectURL(url);

            message.destroy(key);

        }).catch(error => {
            console.log(error);
        });
    }

    function GenerateLookup() {
        let queryErrorsFound = isValidQuery();
        if (queryErrorsFound !== "") {
            var queryErrorContent = <div dangerouslySetInnerHTML={{ __html: queryErrorsFound }} />

            toast.error(queryErrorContent);
            return;
        }

        //We don't know if query has been saved so use the values from screen fields to run lookup
        let postdata = getPostData();

        Axios.post('/api/ExecuteQueryBuilderLookup', postdata
        ).then(response => {
            navigate("/queryresults", { state: { query: postdata, resultSet: response.data } });

        }).catch(error => {
            console.log(error);
        });
    }

    function getPostData() {
        let myQueryType = "";

        let postdata = {};

        postdata.userId = userInfo.userId;
        if (getValues().formFields.searchTarget === 'Exhibitors') {
            myQueryType = "E";
        }
        else {
            myQueryType = "P";
        }
        postdata.type = myQueryType;

        if (getValues().formFields.loadedQueryId === null) {
            postdata.id = 0;
        }
        else {
            postdata.id = getValues().formFields.loadedQueryId;
        }

        postdata.name = getValues().formFields?.loadedQueryName;

        //Either load the searchCriteria into a text box (when field is not 'Show') or antd Select (when field is 'Show')
        if (getValues().formFields.searchCriteriaDisplayMode1 === 'Text') {
            postdata.txtSearchCriteria1 = getValues().formFields.searchCriteria1;
        }
        else if (getValues().formFields.searchCriteriaDisplayMode1 === 'ShowSelector') {
            let myShowCodeStr = '';
            for (var i = 0; i < getValues().formFields.selectedShows1.length; i++) {
                myShowCodeStr += getValues().formFields.selectedShows1[i] + ' || ';
            }
            myShowCodeStr = myShowCodeStr.slice(0, -4);
            postdata.txtSearchCriteria1 = myShowCodeStr;
        }
        else {
            let mySalesRepsStr = '';
            for (var i = 0; i < getValues().formFields.selectedSalesReps1.length; i++) {
                mySalesRepsStr += getValues().formFields.selectedSalesReps1[i] + ' || ';
            }
            mySalesRepsStr = mySalesRepsStr.slice(0, -4);
            postdata.txtSearchCriteria1 = mySalesRepsStr;
        }

        if (getValues().formFields.searchCriteriaDisplayMode2 === 'Text') {
            postdata.txtSearchCriteria2 = getValues().formFields.searchCriteria2;
        }
        else if (getValues().formFields.searchCriteriaDisplayMode2 === 'ShowSelector') {
            let myShowCodeStr = '';
            for (var i = 0; i < getValues().formFields.selectedShows2.length; i++) {
                myShowCodeStr += getValues().formFields.selectedShows2[i] + ' || ';
            }
            myShowCodeStr = myShowCodeStr.slice(0, -4);
            postdata.txtSearchCriteria2 = myShowCodeStr;
        }
        else {
            let mySalesRepsStr = '';
            for (var i = 0; i < getValues().formFields.selectedSalesReps2.length; i++) {
                mySalesRepsStr += getValues().formFields.selectedSalesReps2[i] + ' || ';
            }
            mySalesRepsStr = mySalesRepsStr.slice(0, -4);
            postdata.txtSearchCriteria2 = mySalesRepsStr;
        }

        if (getValues().formFields.searchCriteriaDisplayMode3 === 'Text') {
            postdata.txtSearchCriteria3 = getValues().formFields.searchCriteria3;
        }
        else if (getValues().formFields.searchCriteriaDisplayMode3 === 'ShowSelector') {
            let myShowCodeStr = '';
            for (var i = 0; i < getValues().formFields.selectedShows3.length; i++) {
                myShowCodeStr += getValues().formFields.selectedShows3[i] + ' || ';
            }
            myShowCodeStr = myShowCodeStr.slice(0, -4);
            postdata.txtSearchCriteria3 = myShowCodeStr;
        }
        else {
            let mySalesRepsStr = '';
            for (var i = 0; i < getValues().formFields.selectedSalesReps3.length; i++) {
                mySalesRepsStr += getValues().formFields.selectedSalesReps3[i] + ' || ';
            }
            mySalesRepsStr = mySalesRepsStr.slice(0, -4);
            postdata.txtSearchCriteria3 = mySalesRepsStr;
        }

        if (getValues().formFields.searchCriteriaDisplayMode4 === 'Text') {
            postdata.txtSearchCriteria4 = getValues().formFields.searchCriteria4;
        }
        else if (getValues().formFields.searchCriteriaDisplayMode4 === 'ShowSelector') {
            let myShowCodeStr = '';
            for (var i = 0; i < getValues().formFields.selectedShows4.length; i++) {
                myShowCodeStr += getValues().formFields.selectedShows4[i] + ' || ';
            }
            myShowCodeStr = myShowCodeStr.slice(0, -4);
            postdata.txtSearchCriteria4 = myShowCodeStr;
        }
        else {
            let mySalesRepsStr = '';
            for (var i = 0; i < getValues().formFields.selectedSalesReps4.length; i++) {
                mySalesRepsStr += getValues().formFields.selectedSalesReps4[i] + ' || ';
            }
            mySalesRepsStr = mySalesRepsStr.slice(0, -4);
            postdata.txtSearchCriteria4 = mySalesRepsStr;
        }

        if (getValues().formFields.searchCriteriaDisplayMode5 === 'Text') {
            postdata.txtSearchCriteria5 = getValues().formFields.searchCriteria5;
        }
        else if (getValues().formFields.searchCriteriaDisplayMode5 === 'ShowSelector') {
            let myShowCodeStr = '';
            for (var i = 0; i < getValues().formFields.selectedShows5.length; i++) {
                myShowCodeStr += getValues().formFields.selectedShows5[i] + ' || ';
            }
            myShowCodeStr = myShowCodeStr.slice(0, -4);
            postdata.txtSearchCriteria5 = myShowCodeStr;
        }
        else {
            let mySalesRepsStr = '';
            for (var i = 0; i < getValues().formFields.selectedSalesReps5.length; i++) {
                mySalesRepsStr += getValues().formFields.selectedSalesReps5[i] + ' || ';
            }
            mySalesRepsStr = mySalesRepsStr.slice(0, -4);
            postdata.txtSearchCriteria5 = mySalesRepsStr;
        }

        if (getValues().formFields.searchCriteriaDisplayMode6 === 'Text') {
            postdata.txtSearchCriteria6 = getValues().formFields.searchCriteria6;
        }
        else if (getValues().formFields.searchCriteriaDisplayMode6 === 'ShowSelector') {
            let myShowCodeStr = '';
            for (var i = 0; i < getValues().formFields.selectedShows6.length; i++) {
                myShowCodeStr += getValues().formFields.selectedShows6[i] + ' || ';
            }
            myShowCodeStr = myShowCodeStr.slice(0, -4);
            postdata.txtSearchCriteria6 = myShowCodeStr;
        }
        else {
            let mySalesRepsStr = '';
            for (var i = 0; i < getValues().formFields.selectedSalesReps6.length; i++) {
                mySalesRepsStr += getValues().formFields.selectedSalesReps6[i] + ' || ';
            }
            mySalesRepsStr = mySalesRepsStr.slice(0, -4);
            postdata.txtSearchCriteria6 = mySalesRepsStr;
        }

        postdata.cmbSearchField1 = getValues().formFields.searchField1;
        postdata.cmbSearchField2 = getValues().formFields.searchField2;
        postdata.cmbSearchField3 = getValues().formFields.searchField3;
        postdata.cmbSearchField4 = getValues().formFields.searchField4;
        postdata.cmbSearchField5 = getValues().formFields.searchField5;
        postdata.cmbSearchField6 = getValues().formFields.searchField6;

        postdata.cmbOperator1 = getValues().formFields.operator1;
        postdata.cmbOperator2 = getValues().formFields.operator2;
        postdata.cmbOperator3 = getValues().formFields.operator3;
        postdata.cmbOperator4 = getValues().formFields.operator4;
        postdata.cmbOperator5 = getValues().formFields.operator5;
        postdata.cmbOperator6 = getValues().formFields.operator6;

        postdata.dedupOnCompanyId = dedupChecked ? 1 : 0;
        postdata.useLogicalOrForState = logicalOrStateChecked ? 1 : 0;
        postdata.useLogicalOrForSource = logicalOrSourceChecked ? 1 : 0;

        postdata.contactType = getValues().formFields.contactType;

        postdata.dataFields = getValues().formFields.selectedOutputFields;

        return postdata;
    }

    function isValidQuery() {
        let stateFieldSelected = false;
        let sourceFieldSelected = false;
        let queryErrorsFound = "";
        let searchFieldComboStatus1 = "";
        let searchFieldComboStatus2 = "";
        let searchFieldComboStatus3 = "";
        let searchFieldComboStatus4 = "";
        let searchFieldComboStatus5 = "";
        let searchFieldComboStatus6 = "";

        searchFieldComboStatus1 = getSearchFieldComboStatus("1");
        searchFieldComboStatus2 = getSearchFieldComboStatus("2");
        searchFieldComboStatus3 = getSearchFieldComboStatus("3");
        searchFieldComboStatus4 = getSearchFieldComboStatus("4");
        searchFieldComboStatus5 = getSearchFieldComboStatus("5");
        searchFieldComboStatus6 = getSearchFieldComboStatus("6");

        if (getValues().formFields.searchField1 === 'State' || getValues().formFields.searchField2 === 'State' || getValues().formFields.searchField3 === 'State' || getValues().formFields.searchField4 === 'State' || getValues().formFields.searchField5 === 'State' || getValues().formFields.searchField6 === 'State') {
            stateFieldSelected = true;
        }
        if (getValues().formFields.searchField1 === 'Source' || getValues().formFields.searchField2 === 'Source' || getValues().formFields.searchField3 === 'Source' || getValues().formFields.searchField4 === 'Source' || getValues().formFields.searchField5 === 'State' || getValues().formFields.searchField6 === 'Source') {
            sourceFieldSelected = true;
        }
        if (logicalOrStateChecked === true && stateFieldSelected === false) {
            queryErrorsFound += "Logical 'OR' was checked for State but State was not selected as one of the query fields";
        }
        if (logicalOrSourceChecked === true && sourceFieldSelected === false) {
            queryErrorsFound += "Logical 'OR' was checked for Source but Source was not selected as one of Tthe query fields";
        }

        if (searchFieldComboStatus1 === "Unused" && searchFieldComboStatus2 === "Unused" && searchFieldComboStatus3 === "Unused" && searchFieldComboStatus4 === "Unused" && searchFieldComboStatus5 === "Unused" && searchFieldComboStatus6 === "Unused") {
            queryErrorsFound += "You must select at least one field to query on<br />";
        }

        if (searchFieldComboStatus1 === "Invalid" || searchFieldComboStatus2 === "Invalid" || searchFieldComboStatus3 === "Invalid" || searchFieldComboStatus4 === "Invalid" || searchFieldComboStatus5 === "Invalid" || searchFieldComboStatus6 === "Invalid") {
            queryErrorsFound += "At least one set of query fields, operators, values are invalid<br />";
        }

        if (getValues().formFields.selectedOutputFields === "") {
            queryErrorsFound += "You must select at least one output field<br />";
        }

        return queryErrorsFound;
    }

    function getSearchFieldComboStatus(searchFieldIndex) {
        let searchFieldComboStatus = "OK";

        let mySearchField = 'formFields.searchField' + searchFieldIndex;
        let myOperatorField = 'formFields.operator' + searchFieldIndex;
        let mySearchCriteriaField = 'formFields.searchCriteria' + searchFieldIndex;
        let mySearchCriteriaDisplayMode = 'formFields.searchCriteriaDisplayMode' + searchFieldIndex;
        let mySelectedShows = 'formFields.selectedShows' + searchFieldIndex;

        let mySearchFieldValue = getValues(mySearchField);
        let myOperatorFieldValue = getValues(myOperatorField);
        let mySearchCriteriaFieldValue = getValues(mySearchCriteriaField);
        let mySearchCriteriaDisplayModeValue = getValues(mySearchCriteriaDisplayMode);
        let mySelectedShowsValue = getValues(mySelectedShows);

        if (mySearchFieldValue === 'Select Field' && myOperatorFieldValue === 'Select Operator' && (mySearchCriteriaFieldValue === "" || mySearchCriteriaFieldValue === null)) {
            searchFieldComboStatus = "Unused";
        }
        else if (mySearchCriteriaDisplayModeValue === 'Text' && (mySearchFieldValue === 'Select Field' || myOperatorFieldValue === 'Select Operator' || mySearchCriteriaFieldValue === "" || mySearchCriteriaFieldValue === null)) {
            searchFieldComboStatus = "Invalid";
        }
        else if (mySearchCriteriaDisplayModeValue === 'ShowSelector' && (mySearchFieldValue === 'Select Field' || myOperatorFieldValue === 'Select Operator' || mySelectedShowsValue.length === 0)) {
            searchFieldComboStatus = "Invalid";
        }

        return searchFieldComboStatus;
    }

    function outputFieldsChanged(value) {
        let mySelectedOutputFields = "";

        for (var i = 0; i < outputFields.length; i++) {
            for (var j = 0; j < value.length; j++) {
                if (i === value[j]) {
                    if (mySelectedOutputFields === "") {
                        mySelectedOutputFields = outputFields[i];
                    }
                    else {
                        mySelectedOutputFields += "|" + outputFields[i];
                    }
                }
            }
        }
        setValue('formFields.selectedOutputFields', mySelectedOutputFields);

        setState({
            ...state,
            selectedOutputFields: value
        });
    }

    return (
        <div className="row">
            <div className="form-group col-lg-2 col-12">
                <h3 className="frame-heading">
                    JSS Query Builder
                </h3>
            </div>
            <div className="form-group col-lg-2 col-12"></div>
            <div className="form-group col-lg-8 col-12">
                <div className="rcorners p-2">
                    <label className="form-label">Query Type:</label>&nbsp;&nbsp;
                    <input type="radio" name="searchTarget" {...register("formFields.searchTarget")} value="Exhibitors" className="mt-1" onClick={event => handleSearchTargetClick(event, 'Exhibitors')} />&nbsp;<label className="form-label">Exhibitors</label>&nbsp;&nbsp;&nbsp;
                    <input type="radio" name="searchTarget" {...register("formFields.searchTarget")} value="Prospects" className="mt-1" onClick={event => handleSearchTargetClick(event, 'Prospects')} />&nbsp;<label className="form-label">Prospects</label>
    
                    <input className="ms-5" type="checkbox" name="DeDup" onClick={handleDedupChecked} checked={dedupChecked} />&nbsp;Dedup on Company ID
                    <input className="ms-5" type="checkbox" name="LogicalOrState" onClick={handleLogicalOrStateChecked} checked={logicalOrStateChecked} />&nbsp;Use a Logical 'OR' for State Field
                    <input className="ms-5" type="checkbox" name="LogicalOrSource" onClick={handleLogicalOrSourceChecked} checked={logicalOrSourceChecked} />&nbsp;Use a Logical 'OR' for Source Field
                </div>
            </div>

            {/*Search Fields 1*/}
            <div className="form-group col-lg-2 col-12">
                <label className="form-label">Search Field</label>
                <Select
                    {...register("formFields.searchField1")}
                    id="ddSearchField1"
                    onChange={(value) => handleSearchFieldChange(value, '1')}
                    className="form-control-custom w-100"
                    bordered={false}
                    value={getValues().formFields?.searchField1}
                >
                    {getValues().formFields?.Search1Fields.map(field => (
                        <Option key={field}>{field}</Option>
                    ))}
                </Select>
            </div>
            <div className="form-group col-lg-2 col-12">
                <label className="form-label">Operator</label>
                <Select
                    id="ddOperator1"
                    {...register("formFields.operator1")}
                    onChange={(value) => handleOperatorFieldChange(value, '1')}
                    className="form-control-custom w-100"
                    bordered={false}
                    value={getValues().formFields?.operator1}
                    disabled={getValues().formFields?.searchField1 == 'Select Field' ? true : false}
                >
                    {getValues().formFields?.Operator1Fields.map(field => (
                        <Option key={field}>{field}</Option>
                    ))}
                </Select>
            </div>
            <div className="form-group col-lg-8 col-12">
                <label className="form-label">Search Criteria</label>
                <input type="text" {...register("formFields.searchCriteria1")} className="form-control-custom" style={getValues().formFields?.searchCriteriaDisplayMode1 === 'Text' ? {} : { display: 'none' }} />
                <Select id="ddSShows1"
                    value={getValues().formFields?.selectedShows1}
                    className="form-control-custom"
                    mode="multiple"
                    placeholder="Select one or more Shows"
                    onChange={(value) => handleShowSelectorFieldChange(value, '1')}
                    className="form-control-custom w-100"
                    bordered={false}
                    style={getValues().formFields?.searchCriteriaDisplayMode1 === 'ShowSelector' ? {} : { display: 'none' }}
                >
                    {getValues().formFields?.shows.map((show) => <Option key={show.showcode} value={show.showcode}>{show.name}</Option>)}
                </Select>
                <Select id="ddSalesReps1"
                    value={getValues().formFields?.selectedSalesReps1}
                    className="form-control-custom"
                    mode="multiple"
                    placeholder="Select one or more Sales Reps"
                    onChange={(value) => handleSalesRepSelectorFieldChange(value, '1')}
                    className="form-control-custom w-100"
                    bordered={false}
                    style={getValues().formFields?.searchCriteriaDisplayMode1 === 'SalesRepSelector' ? {} : { display: 'none' }}
                >
                    {getValues().formFields?.salesReps.map((rep) => <Option key={rep.name} value={rep.name}>{rep.name}</Option>)}
                </Select>
            </div>

            {/*Search Fields 2*/}
            <div className="form-group col-lg-2 col-12">
                <Select
                    {...register("formFields.searchField2")}
                    id="ddSearchField2"
                    onChange={(value) => handleSearchFieldChange(value, '2')}
                    className="form-control-custom w-100"
                    bordered={false}
                    value={getValues().formFields?.searchField2}
                >
                    {getValues().formFields?.Search2Fields.map(field => (
                        <Option key={field}>{field}</Option>
                    ))}
                </Select>
            </div>
            <div className="form-group col-lg-2 col-12">
                <Select
                    id="ddOperator2"
                    {...register("formFields.operator2")}
                    onChange={(value) => handleOperatorFieldChange(value, '2')}
                    className="form-control-custom w-100"
                    bordered={false}
                    value={getValues().formFields?.operator2}
                    disabled={getValues().formFields?.searchField2 == 'Select Field' ? true : false}
                >
                    {getValues().formFields?.Operator2Fields.map(field => (
                        <Option key={field}>{field}</Option>
                    ))}
                </Select>
            </div>
            <div className="form-group col-lg-8 col-12">
                <input type="text" {...register("formFields.searchCriteria2")} className="form-control-custom" style={getValues().formFields?.searchCriteriaDisplayMode2 === 'Text' ? {} : { display: 'none' }} />
                <Select id="ddSShows2"
                    value={getValues().formFields?.selectedShows2}
                    className="form-control-custom"
                    mode="multiple"
                    placeholder="Select one or more Shows"
                    onChange={(value) => handleShowSelectorFieldChange(value, '2')}
                    className="form-control-custom w-100"
                    bordered={false}
                    style={getValues().formFields?.searchCriteriaDisplayMode2 === 'ShowSelector' ? {} : { display: 'none' }}
                >
                    {getValues().formFields?.shows.map((show) => <Option key={show.showcode} value={show.showcode}>{show.name}</Option>)}
                </Select>
                <Select id="ddSalesReps2"
                    value={getValues().formFields?.selectedSalesReps2}
                    className="form-control-custom"
                    mode="multiple"
                    placeholder="Select one or more Sales Reps"
                    onChange={(value) => handleSalesRepSelectorFieldChange(value, '2')}
                    className="form-control-custom w-100"
                    bordered={false}
                    style={getValues().formFields?.searchCriteriaDisplayMode2 === 'SalesRepSelector' ? {} : { display: 'none' }}
                >
                    {getValues().formFields?.salesReps.map((rep) => <Option key={rep.name} value={rep.name}>{rep.name}</Option>)}
                </Select>
            </div>
         
            {/*Search Fields 3*/}
            <div className="form-group col-lg-2 col-12">
                <Select
                    {...register("formFields.searchField3")}
                    id="ddSearchField3"
                    onChange={(value) => handleSearchFieldChange(value, '3')}
                    className="form-control-custom w-100"
                    bordered={false}
                    value={getValues().formFields?.searchField3}
                >
                    {getValues().formFields?.Search3Fields.map(field => (
                        <Option key={field}>{field}</Option>
                    ))}
                </Select>
            </div>
            <div className="form-group col-lg-2 col-12">
                <Select
                    id="ddOperator3"
                    {...register("formFields.operator3")}
                    onChange={(value) => handleOperatorFieldChange(value, '3')}
                    className="form-control-custom w-100"
                    bordered={false}
                    value={getValues().formFields?.operator3}
                    disabled={getValues().formFields?.searchField3 == 'Select Field' ? true : false}
                >
                    {getValues().formFields?.Operator3Fields.map(field => (
                        <Option key={field}>{field}</Option>
                    ))}
                </Select>
            </div>
            <div className="form-group col-lg-8 col-12">
                <input type="text" {...register("formFields.searchCriteria3")} className="form-control-custom" style={getValues().formFields?.searchCriteriaDisplayMode3 === 'Text' ? {} : { display: 'none' }} />
                <Select id="ddSShows3"
                    value={getValues().formFields?.selectedShows3}
                    className="form-control-custom"
                    mode="multiple"
                    placeholder="Select one or more Shows"
                    onChange={(value) => handleShowSelectorFieldChange(value, '3')}
                    className="form-control-custom w-100"
                    bordered={false}
                    style={getValues().formFields?.searchCriteriaDisplayMode3 === 'ShowSelector' ? {} : { display: 'none' }}
                >
                    {getValues().formFields?.shows.map((show) => <Option key={show.showcode} value={show.showcode}>{show.name}</Option>)}
                </Select>
                <Select id="ddSalesReps3"
                    value={getValues().formFields?.selectedSalesReps3}
                    className="form-control-custom"
                    mode="multiple"
                    placeholder="Select one or more Sales Reps"
                    onChange={(value) => handleSalesRepSelectorFieldChange(value, '3')}
                    className="form-control-custom w-100"
                    bordered={false}
                    style={getValues().formFields?.searchCriteriaDisplayMode3 === 'SalesRepSelector' ? {} : { display: 'none' }}
                >
                    {getValues().formFields?.salesReps.map((rep) => <Option key={rep.name} value={rep.name}>{rep.name}</Option>)}
                </Select>
            </div>

            {/*Search Fields 4*/}
            <div className="form-group col-lg-2 col-12">
                <Select
                    {...register("formFields.searchField4")}
                    id="ddSearchField4"
                    onChange={(value) => handleSearchFieldChange(value, '4')}
                    className="form-control-custom w-100"
                    bordered={false}
                    value={getValues().formFields?.searchField4}
                >
                    {getValues().formFields?.Search4Fields.map(field => (
                        <Option key={field}>{field}</Option>
                    ))}
                </Select>
            </div>
            <div className="form-group col-lg-2 col-12">
                <Select
                    id="ddOperator4"
                    {...register("formFields.operator4")}
                    onChange={(value) => handleOperatorFieldChange(value, '4')}
                    className="form-control-custom w-100"
                    bordered={false}
                    value={getValues().formFields?.operator4}
                    disabled={getValues().formFields?.searchField4 == 'Select Field' ? true : false}
                >
                    {getValues().formFields?.Operator4Fields.map(field => (
                        <Option key={field}>{field}</Option>
                    ))}
                </Select>
            </div>
            <div className="form-group col-lg-8 col-12">
                <input type="text" {...register("formFields.searchCriteria4")} className="form-control-custom" style={getValues().formFields?.searchCriteriaDisplayMode4 === 'Text' ? {} : { display: 'none' }} />
                <Select id="ddSShows4"
                    value={getValues().formFields?.selectedShows4}
                    className="form-control-custom"
                    mode="multiple"
                    placeholder="Select one or more Shows"
                    onChange={(value) => handleShowSelectorFieldChange(value, '4')}
                    className="form-control-custom w-100"
                    bordered={false}
                    style={getValues().formFields?.searchCriteriaDisplayMode4 === 'ShowSelector' ? {} : { display: 'none' }}
                >
                    {getValues().formFields?.shows.map((show) => <Option key={show.showcode} value={show.showcode}>{show.name}</Option>)}
                </Select>
                <Select id="ddSalesReps4"
                    value={getValues().formFields?.selectedSalesReps4}
                    className="form-control-custom"
                    mode="multiple"
                    placeholder="Select one or more Sales Reps"
                    onChange={(value) => handleSalesRepSelectorFieldChange(value, '4')}
                    className="form-control-custom w-100"
                    bordered={false}
                    style={getValues().formFields?.searchCriteriaDisplayMode4 === 'SalesRepSelector' ? {} : { display: 'none' }}
                >
                    {getValues().formFields?.salesReps.map((rep) => <Option key={rep.name} value={rep.name}>{rep.name}</Option>)}
                </Select>
            </div>

            {/*Search Fields 5*/}
            <div className="form-group col-lg-2 col-12">
                <Select
                    {...register("formFields.searchField5")}
                    id="ddSearchField5"
                    onChange={(value) => handleSearchFieldChange(value, '5')}
                    className="form-control-custom w-100"
                    bordered={false}
                    value={getValues().formFields?.searchField5}
                >
                    {getValues().formFields?.Search5Fields.map(field => (
                        <Option key={field}>{field}</Option>
                    ))}
                </Select>
            </div>
            <div className="form-group col-lg-2 col-12">
                <Select
                    id="ddOperator5"
                    {...register("formFields.operator5")}
                    onChange={(value) => handleOperatorFieldChange(value, '5')}
                    className="form-control-custom w-100"
                    bordered={false}
                    value={getValues().formFields?.operator5}
                    disabled={getValues().formFields?.searchField5 == 'Select Field' ? true : false}
                >
                    {getValues().formFields?.Operator5Fields.map(field => (
                        <Option key={field}>{field}</Option>
                    ))}
                </Select>
            </div>
            <div className="form-group col-lg-8 col-12">
                <input type="text" {...register("formFields.searchCriteria5")} className="form-control-custom" style={getValues().formFields?.searchCriteriaDisplayMode5 === 'Text' ? {} : { display: 'none' }} />
                <Select id="ddSShows5"
                    value={getValues().formFields?.selectedShows5}
                    className="form-control-custom"
                    mode="multiple"
                    placeholder="Select one or more Shows"
                    onChange={(value) => handleShowSelectorFieldChange(value, '5')}
                    className="form-control-custom w-100"
                    bordered={false}
                    style={getValues().formFields?.searchCriteriaDisplayMode5 === 'ShowSelector' ? {} : { display: 'none' }}
                >
                    {getValues().formFields?.shows.map((show) => <Option key={show.showcode} value={show.showcode}>{show.name}</Option>)}
                </Select>
                <Select id="ddSalesReps5"
                    value={getValues().formFields?.selectedSalesReps5}
                    className="form-control-custom"
                    mode="multiple"
                    placeholder="Select one or more Sales Reps"
                    onChange={(value) => handleSalesRepSelectorFieldChange(value, '5')}
                    className="form-control-custom w-100"
                    bordered={false}
                    style={getValues().formFields?.searchCriteriaDisplayMode5 === 'SalesRepSelector' ? {} : { display: 'none' }}
                >
                    {getValues().formFields?.salesReps.map((rep) => <Option key={rep.name} value={rep.name}>{rep.name}</Option>)}
                </Select>
            </div>

            {/*Search Fields 6*/}
            <div className="form-group col-lg-2 col-12">
                <Select
                    {...register("formFields.searchField6")}
                    id="ddSearchField6"
                    onChange={(value) => handleSearchFieldChange(value, '6')}
                    className="form-control-custom w-100"
                    bordered={false}
                    value={getValues().formFields?.searchField6}
                >
                    {getValues().formFields?.Search6Fields.map(field => (
                        <Option key={field}>{field}</Option>
                    ))}
                </Select>
            </div>
            <div className="form-group col-lg-2 col-12">
                <Select
                    id="ddOperator6"
                    {...register("formFields.operator6")}
                    onChange={(value) => handleOperatorFieldChange(value, '6')}
                    className="form-control-custom w-100"
                    bordered={false}
                    value={getValues().formFields?.operator6}
                    disabled={getValues().formFields?.searchField6 == 'Select Field' ? true : false}
                >
                    {getValues().formFields?.Operator6Fields.map(field => (
                        <Option key={field}>{field}</Option>
                    ))}
                </Select>
            </div>
            <div className="form-group col-lg-8 col-12">
                <input type="text" {...register("formFields.searchCriteria6")} className="form-control-custom" style={getValues().formFields?.searchCriteriaDisplayMode6 === 'Text' ? {} : { display: 'none' }} />
                <Select id="ddSShows6"
                    value={getValues().formFields?.selectedShows6}
                    className="form-control-custom"
                    mode="multiple"
                    placeholder="Select one or more Shows"
                    onChange={(value) => handleShowSelectorFieldChange(value, '6')}
                    className="form-control-custom w-100"
                    bordered={false}
                    style={getValues().formFields?.searchCriteriaDisplayMode6 === 'ShowSelector' ? {} : { display: 'none' }}
                >
                    {getValues().formFields?.shows.map((show) => <Option key={show.showcode} value={show.showcode}>{show.name}</Option>)}
                </Select>
                <Select id="ddSalesReps6"
                    value={getValues().formFields?.selectedSalesReps6}
                    className="form-control-custom"
                    mode="multiple"
                    placeholder="Select one or more Sales Reps"
                    onChange={(value) => handleSalesRepSelectorFieldChange(value, '6')}
                    className="form-control-custom w-100"
                    bordered={false}
                    style={getValues().formFields?.searchCriteriaDisplayMode6 === 'SalesRepSelector' ? {} : { display: 'none' }}
                >
                    {getValues().formFields?.salesReps.map((rep) => <Option key={rep.name} value={rep.name}>{rep.name}</Option>)}
                </Select>
            </div>

            <div className="form-group col-12">
                <hr />
            </div>

            <div className="form-group col-1">
                <label className="form-label">Select Contact Type:</label>
            </div>
            <div className="form-group col-1">
                <Select id="ddContactType"
                    value={getValues().formFields?.contactType}
                    className="form-control-custom"
                    onChange={handleContactTypeChange}
                    className="form-control-custom w-100"
                    bordered={false}
                >
                    <Option value='Main'>Main</Option>
                    <Option value='Logistics'>Logistics</Option>
                    <Option value='Main & Logistics'>Main & Logistics</Option>
                    <Option value='Total Package'>Total Package</Option>
                    <Option value='Alt Sales'>Alt Sales</Option>
                    <Option value='Accounting'>Accounting</Option>
                    <Option value='RFQ'>RFQ</Option>
                </Select>
            </div>
            <div className="form-group col-2"></div>
            <div className="form-group col-8">
                <button className="btn btn-submit" onClick={ResetSearchCriteria}>Reset Selections</button>
                <Button className="btn btn-submit ms-3" onClick={handleSaveQueryClick}>Save Query</Button>
                <Button className="btn btn-submit ms-3" onClick={handleLoadSavedQuery}>Load Saved Query</Button>

                <label className="form-label ms-5">{getValues().formFields?.loadedQueryName}</label>
                <label className="form-label ms-5" hidden>{getValues().formFields?.loadedQueryId}</label>
            </div>

            <div className="form-group col-6">
                <h3 className="frame-heading">
                    Select Output Fields
                </h3>
                <List className="list-scroll"
                    items={outputFields}
                    selected={state.selectedOutputFields}
                    multiple={true}
                    onChange={(selected: number) => { outputFieldsChanged(selected) }}
                />
            </div>
            <div className="form-group col-6">
                
            </div>

            <div className="form-group col-4">
                <button className="btn btn-submit" onClick={exportToCSVFile}>Export To CSV File</button>
                <button className="btn btn-submit ms-3" onClick={GenerateLookup}>Generate Lookup</button>
            </div>

            {state.showSavedQueriesSlider &&
                <SavedQueriesSlider showModal={state.showSavedQueriesSlider} selectedQuery={state.selectedQuery} loadSelectedQuery={LoadSelectedQuery} />
            }

            <Modal
                visible={queryNameModal.show}
                title="Save New Query"
                width={500}
                footer={[
                    <button className="btn btn-submit" onClick={() => SaveQuery()} >
                        Save Query
                    </button>,
                    <button className="btn btn-outline-primary float-right" onClick={() => { setQueryNameModal({ ...queryNameModal, show: false }); }} >
                        Cancel
                    </button>
                ]}
            >
                <div className="row">
                    <label className="form-label">Please enter the name of the query to save</label>
                    <input type="text" {...register("formFields.newQueryName")} className="form-control-custom" />
                </div>
            </Modal>
        </div>
    );
}