import React, { Component } from 'react';
import TopNav from './Nav/TopNav';
import LeftNav from './Nav/LeftNav';
import PersistEmails from './PersistEmails';

export class Layout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showLeftNav: false
        }
    }

    render() {
        const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

        return (
            <div className="page-wrapper">
                <div className="page-inner">
                    {this.state.showLeftNav === true ?
                        <LeftNav />
                        :
                        <div></div>
                    }
                    <div className="page-content-wrapper">
                        {/* BEGIN Page Header */}
                        <TopNav showLeftNav={this.state.showLeftNav} />
                        {/* END Page Header */}
                        
                        {/* BEGIN Page Content */}
                        {/* the #js-page-content id is needed for some plugins to initialize */}
                        <main id="js-page-content" role="main" className="page-content">
                            <PersistEmails />
                            {this.props.children}
                        </main>
                        {/* this overlay is activated only when mobile menu is triggered */}
                        <div className="page-content-overlay" data-action="toggle" data-class="mobile-nav-on" /> {/* END Page Content */}
                        {/* BEGIN Page Footer */}
                        {/*<footer className="page-footer" role="contentinfo">*/}
                        {/*    <div className="d-flex align-items-center flex-1 text-muted">*/}
                        {/*        <span className="hidden-md-down fw-700">2022 � D2P by&nbsp;<a href="https://customapps.net" className="text-primary fw-500" title="gotbootstrap.com" target="_blank" rel="noopener noreferrer">Automated Concepts, LLC</a></span>*/}
                        {/*    </div>*/}
                        {/*</footer>*/}
                        {/* END Page Footer */}
                        {/* BEGIN Shortcuts */}
                    </div>
                </div>
            </div>
        );
    }
}
