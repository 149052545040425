import Axios from '../config/axios';

export function sendEmail(formData) {
    return Axios.post(`/api/SendEmail`, formData
    ).then(response => response.data).catch(error => error);
}

export function getEmailHistory() {
    return Axios.get(`/api/GetEmailHistory`).then(response => response.data).catch(error => error);
}

export function getRepEmailTemplates(ownedby, division) {
    return Axios.get('/api/GetRepEmailTemplates',
        {
            params: {
                ownedby,
                division
            }
        }).then(response => response.data).catch(error => error);
}

export function addRepEmailTemplate(postdata) {
    return Axios.post(`/api/AddRepEmailTemplate`, postdata
    ).then(response => response.data).catch(error => error);
}

export function updateRepEmailTemplate(postdata) {
    return Axios.post(`/api/UpdateRepEmailTemplate`, postdata
    ).then(response => response.data).catch(error => error);
}

export function deleteRepEmailTmplate(postdata) {
    return Axios.post(`/api/DeleteRepEmailTemplate`, postdata
    ).then(response => response.data).catch(error => error);
}