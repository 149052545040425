import React, { useState, useEffect, useRef } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Axios from '../../config/axios';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import { Fragment } from 'react';
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import { useForm, Controller } from "react-hook-form";
import { toast, confirm } from '@rickylandino/react-messages';
import { Select } from 'antd';
import ContentEditable from "react-contenteditable";
import NotesDialog from './NotesDialog';

export default function NoteDetailsSlider(props) {
    const [state, setState] = useState({
        showPane: false,
        dataLoaded: false,
        mode: 'Add',
        usersListData: [],
    });

    const [tableControl, setTableControl] = useState({
        page: 1,
        sizePerPage: 10
    });

    const [notes, setNotes] = useState(props.notes);

    const { register, getValues, setValue, control, watch } = useForm();

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    var isDirty = useRef(false);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === 'change') {
                isDirty.current = true;
            }
        });

        LoadFormData();

        return () => subscription.unsubscribe();

    }, [props]);

    function LoadFormData() {
        Axios.post(`/api/GetAllUsers`, {
            params: {

            }
        }).then(response => {
            let usersList = response.data;

            if (props.selectedItemId !== null) {
                let notes = props.notes;
                const selectedIdx = notes.findIndex(n => n.itemId === props.selectedItemId);

                setValue('formFields', notes[selectedIdx]);
            }
            else {
                var note_date = new Date();
                setValue('formFields.note_date', note_date);
                setValue('formFields.note', '');
                setValue('formFields.salesperson', userInfo.userId);
            }

            setState({
                ...state,
                showPane: props.showPane,
                mode: props.mode,
                usersListData: usersList,
                dataLoaded: true,
            });

        }).catch(error => {
            console.log(error);
        });
    }

    function isHTML(input) {
        return /<[a-z]+\d?(\s+[\w-]+=("[^"]*"|'[^']*'))*\s*\/?>|&#?\w+;/i.test(input);
    }

    //function isHTML(str) {
    //    var doc = new DOMParser().parseFromString(str, "text/html");
    //    return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
    //}

    function closeSlider() {
        if (isDirty.current) {
            confirm({
                title: "You are about to close this note and lose any changes you've made.",
                content: "Are you sure you would like continue?",
                buttons: ["Yes", "No"],
            }, (buttonPressed) => {
                if (buttonPressed === 'Yes') {
                    hidePane();

                    return 0;
                } else {
                    return 0;
                }
            });
        } else {
            hidePane();
        }
    }

    function hidePane() {
        //now update the parent list
        props.reloadNotesFromDB();

        //var localNotesList = newNotesList || [...notes];

        //if (props.mode === 'Edit') {
        //    const selectedIdx = localNotesList.findIndex(n => n.itemId === props.selectedItemId);
        //    localNotesList[selectedIdx] = getValues().formFields;

        //    //for graceful, animated close
        //    setTimeout(() => props.updateTableDisplay(localNotesList, selectedIdx), 1000);
        //}
        //else {
        //    setTimeout(() => props.reloadNotesFromDB(), 1000);
        //}

        setState({
            ...state,
            showPane: false
        });
    }

    function onUserChange(value) {
        setValue('formFields.salesperson', value)
        setState({ ...state });
    }

    function handleSave() {
        if (props.selectedItemId !== null) {
            UpdateCustomerNote();
        }
        else {
            InsertCustomerNote();
        }
    }

    function InsertCustomerNote() {
        let postdata = {};
        postdata.CustId = props.selectedCustomerId;
        postdata.note = getValues().formFields?.note;
        postdata.note_date = getValues().formFields?.note_date;
        postdata.division = userInfo.jobShopDivision;
        postdata.salesperson = userInfo.userId;
        postdata.pinned = getValues().formFields?.pinned;

        Axios.post('/api/InsertCustomerNote', postdata
        ).then(async response => {
            hidePane();
            toast.success('Note successfully added');

        }).catch(error => {
            console.log(error);
        });
    }

    function UpdateCustomerNote() {
        let postdata = {};

        postdata.ItemId = props.selectedItemId;
        postdata.note = getValues().formFields?.note;
        postdata.note_date = getValues().formFields?.note_date;
        postdata.salesperson = getValues().formFields?.salesperson;
        postdata.pinned = getValues().formFields?.pinned;

        Axios.post('/api/UpdateCustomerNote', postdata
        ).then(async response => {
            hidePane();
            toast.success('Note changes successfully saved');

        }).catch(error => {
            console.log(error);
        });
    }

    function handlePinnedChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        /*        const name = target.name;*/

        Axios.get(`/api/CheckForPinnedNote`, {
            params: {
                company_id: props.selectedCustomerId,
                itemId: props.selectedItemId,
            }
        }).then(response => {
            if (response.data === true) {
                if (userInfo.jobShopDivision === 'JSS') {
                    toast.error('Another note is already pinned for this exhibitor - only 1 is allowed');
                }
                else {
                    toast.error('Another note is already pinned for this advertiser - only 1 is allowed');
                }
                return;
            }
            else {
                setValue('formFields.pinned', value);

                setState({
                    ...state,
                });
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function handleNoteChange(e) {
        let target = e.target;
        let name = target.name;
        let value = target.value;

        setValue('formFields.note', value);

        setState({
            ...state,
        });
    }

    return (
        <SlidingPane
            className='some-custom-class w-65'
            overlayClassName='showCard'
            isOpen={state.showPane}
            title={state.mode === 'Add'? 'Add New Note' : 'Edit Note'}
            onRequestClose={closeSlider}
            width={props.noteQueue.length > 0 ? '85%' : '40%'}
        >
            <div style={{ height: 'calc(100% - 65px)'}}>
                <div className="h-100">
                    {state.dataLoaded &&
                        <NotesDialog noteQueue={props.noteQueue} selectedCustomerId={props.selectedCustomerId} hidePane={hidePane} />
                        //<Fragment>
                        //    <form className="">
                        //        <div className="row">
                        //            <div className="form-group col-lg-2">
                        //                <label className="form-label">Note Date</label>
                        //            </div>
                        //            <div className="form-group col-lg-2">
                        //                <Controller
                        //                    name="formFields.bidDate"
                        //                    control={control}
                        //                    setValue={setValue}
                        //                    defaultValue={getValues().formFields?.note_date ? Moment(getValues().formFields.note_date).toDate() : null}
                        //                    render={() =>
                        //                        <DatePicker
                        //                            selected={getValues().formFields?.note_date ? Moment(getValues().formFields.note_date).toDate() : null}
                        //                            onChange={date => setValue('formFields.note_date', date)}
                        //                            className="form-control-custom"
                        //                            showMonthDropdown
                        //                            showYearDropdown
                        //                            dropdownMode="select"
                        //                            customInput={
                        //                                <NumberFormat format="##/##/####" mask="_" />
                        //                            } />
                        //                    }
                        //                />
                        //            </div>
                        //            <div className="form-group col-lg-8"></div>

                        //            <div className="form-group col-lg-2">
                        //                <label className="form-label">Sales Rep</label>
                        //            </div>
                        //            <div className="form-group col-lg-10">
                        //                <Select
                        //                    {...register("formFields.salesperson")}
                        //                    size="large" placeholder="Select Rep"
                        //                    //style={{ width: 400 }}
                        //                    showSearch
                        //                    allowClear={true}
                        //                    optionFilterProp="children"
                        //                    //onSearch={onContractorSearch}
                        //                    onChange={onUserChange}
                        //                    filterOption={(input, option) =>
                        //                        option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || false
                        //                    }
                        //                    value={getValues().formFields?.salesperson}
                        //                    disabled={userInfo.userType == 'admin' ? false : true}
                        //                >
                        //                    <option value='Select Assign To' />
                        //                    {state.usersListData?.length > 0 && state.usersListData?.map((user, idx) => <option index={idx} key={user.id} value={user.id}>{user.username}</option>)}
                        //                </Select>
                        //            </div>

                        //            <div className="form-group col-lg-12">
                        //                <label className="form-label">Note</label>
                        //                {isHTML(getValues().formFields.note) ?
                        //                    <ContentEditable
                        //                        html={getValues().formFields.note} // innerHTML of the editable div
                        //                        disabled={false} // use true to disable edition
                        //                        onChange={handleNoteChange} // handle innerHTML change
                        //                        style={{ border: '2px solid rgba(0, 0, 0, 0.05)', padding: 10 }}
                        //                    />
                        //                    :
                        //                    <textarea name="description" rows="20" cols="50" {...register("formFields.note")} className="form-control-custom" />
                        //                }
                        //            </div>

                        //            <div className="form-group col-lg-4">
                        //                <label className="form-label">&nbsp;</label>
                        //                <div className="custom-control custom-checkbox">
                        //                    <input type="checkbox" className="custom-control-input" name="pinned" {...register("formFields.pinned")} checked={getValues().formFields.pinned} onChange={handlePinnedChange} />
                        //                    <label className="custom-control-label">Pin This Note</label>
                        //                </div>
                        //            </div>
                        //        </div>
                        //    </form>
                        //</Fragment>
                    }
                </div>
            </div>

            {/*<div className="modal-footer">*/}
            {/*    <div className="form-group col-12 padding-25-10">*/}
            {/*        <button id="btnSaveChanges" onClick={handleSave} className="btn btn-submit me-3" style={{ 'marginLeft': '5px' }}>Save Changes</button>*/}
            {/*        <button className="btn btn-outline-primary margin-left-15 float-right" onClick={closeSlider}>Close</button>*/}
            {/*    </div>*/}
            {/*</div>*/}

        </SlidingPane>
    );
}