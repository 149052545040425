import React, { useState, useEffect } from 'react';
import { Avatar, Button, Comment, Form, Input, List } from 'antd';
import moment from 'moment';
import { useForm, Controller } from "react-hook-form";
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import ContentEditable from "react-contenteditable";
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import { insertCustomerNoteBetter, processNotePinRequest } from '../../services/NotesService';
import parse from 'html-react-parser';
import {
    PushpinOutlined,
    PushpinFilled
} from '@ant-design/icons';


const { TextArea } = Input;
const CommentList = ({ comments, handlePinnedChangeFromQueue }) => (
    <List
        dataSource={comments}
        itemLayout="horizontal"
        renderItem={(props) =>
            <div className="d-flex">
                {props.pinned ? <PushpinFilled className="d-flex align-items-center me-3 hover" style={{ fontSize: '1.5rem', color: '#275282' }} /> : <PushpinOutlined onClick={() => handlePinnedChangeFromQueue(props.custid, props.itemid)} className="d-flex align-items-center me-3 hover" style={{ fontSize: '1.5rem', color: '#275282' }} />}
                <Comment {...props} />
            </div>
        }
    />
);
const Editor = ({ onChange, onSubmit, submitting, value }) => (
    <>
        <Form.Item>
            <TextArea rows={4} onChange={onChange} value={value} />
        </Form.Item>
        <Form.Item>
            <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
                Add Comment
            </Button>
        </Form.Item>
    </>
);

function isHTML(input) {
    return /<[a-z]+\d?(\s+[\w-]+=("[^"]*"|'[^']*'))*\s*\/?>|&#?\w+;/i.test(input);
}

export default function NotesDialog(props) {
    const [comments, setComments] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    //const [value, setValue] = useState('');
    const { register, getValues, setValue, control, watch } = useForm();

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    useEffect(() => {
        console.log(props.noteQueue);
        var modifiedQueue = [...props.noteQueue].map(item => ({
            author: item.salesperson,
            content: <div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: item.note }} />,
            datetime: moment(item.note_date).format("L"),
            groupid: item.groupId,
            pinned: item.pinned,
            itemid: item.itemId,
            custid: item.custId
        }));

        setComments(modifiedQueue);
    }, []);

    const handleSubmit = () => {
        let postdata = {
            custId: props.selectedCustomerId,
            note: getValues().formFields.note,
            note_date: getValues().formFields.note_date,
            division: userInfo.jobShopDivision,
            salesperson: userInfo.userId,
            pinned: getValues().formFields.pinned,
            groupId: comments.length > 0 ? comments[0].groupid : null,
            linkedNotes: props.noteQueue.map(n => n.itemId)
        }

        insertCustomerNoteBetter(postdata).then(data => {
            props.hidePane();
        });
        
        //setSubmitting(true);
        //setTimeout(() => {
        //    setSubmitting(false);
        //    setValue('');
        //    setComments([
        //        ...comments,
        //        {
        //            author: 'Han Solo',
        //            content: <p>{value}</p>,
        //            datetime: moment('2016-11-22').fromNow(),
        //        },
        //    ]);
        //}, 1000);
    };
    async function checkForPinnedNote(company_id, itemId) {
        const response = await Axios.get(`/api/CheckForPinnedNote`, {
            params: {
                company_id,
                itemId
            }
        });

        return response.data;
    }

    function handlePinnedChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        Axios.get(`/api/CheckForPinnedNote`, {
            params: {
                company_id: props.selectedCustomerId,
                itemId: props.selectedItemId,
            }
        }).then(response => {
            if (response.data === true) {
                if (userInfo.jobShopDivision === 'JSS') {
                    toast.error('Another note is already pinned for this exhibitor - only 1 is allowed');
                }
                else {
                    toast.error('Another note is already pinned for this advertiser - only 1 is allowed');
                }
                return;
            }
            else {
                setValue('formFields.pinned', value);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    async function handlePinnedChangeFromQueue(company_id, itemId) {
        const hasPinnedNote = await checkForPinnedNote(company_id, itemId);

        if (hasPinnedNote) {
            if (userInfo.jobShopDivision === 'JSS') {
                toast.error('Another note is already pinned for this exhibitor - only 1 is allowed');
            }
            else {
                toast.error('Another note is already pinned for this advertiser - only 1 is allowed');
            }
        } else {

            var postdata = {
                requesttype: 'PinNote',
                company_id,
                itemId
            }

            //set comment as pinned
            processNotePinRequest(postdata).then(data => {
                if (data) {
                    var newComments = [...comments];
                    var commentIdx = newComments.findIndex(c => c.itemid === itemId);
                    let comment = newComments[commentIdx];
                    comment.pinned = true;
                    newComments[commentIdx] = comment;
                    setComments(newComments);
                }
            });
        }
    }

    return (
        <div className="row h-100">
            {props.noteQueue.length > 0 &&
                <div className="col col-lg-6 h-100" style={{ overflow: 'auto' }}>
                    {comments.length > 0 && <CommentList comments={comments} handlePinnedChangeFromQueue={handlePinnedChangeFromQueue} />}
                </div>
            }
            <div className="col">
            <Comment
                content={
                    <div>
                        <div className="row">
                            {/*<div className="form-group col-lg-2">*/}
                            {/*    <label className="form-label">Note Date</label>*/}
                            {/*</div>*/}
                            {/*<div className="form-group col-lg-2">*/}
                            {/*    <Controller*/}
                            {/*        name="formFields.note_date"*/}
                            {/*        control={control}*/}
                            {/*        setValue={setValue}*/}
                            {/*        defaultValue={getValues().formFields?.note_date ? Moment(getValues().formFields.note_date).toDate() : null}*/}
                            {/*        render={() =>*/}
                            {/*            <DatePicker*/}
                            {/*                selected={getValues().formFields?.note_date ? Moment(getValues().formFields.note_date).toDate() : null}*/}
                            {/*                onChange={date => setValue('formFields.note_date', date)}*/}
                            {/*                className="form-control-custom"*/}
                            {/*                showMonthDropdown*/}
                            {/*                showYearDropdown*/}
                            {/*                dropdownMode="select"*/}
                            {/*                customInput={*/}
                            {/*                    <NumberFormat format="##/##/####" mask="_" />*/}
                            {/*                } />*/}
                            {/*        }*/}
                            {/*    />*/}
                            {/*</div>*/}
                            {/*<div className="form-group col-lg-8"></div>*/}

                            <div className="form-group col-lg-12">
                                    <label className="form-label">{props.noteQueue.length > 0 ? 'Add Follow Up Note' : 'Note'}</label>
                                {isHTML(getValues().formFields?.note) ?
                                    <ContentEditable
                                        html={getValues().formFields.note} // innerHTML of the editable div
                                        disabled={false} // use true to disable edition
                                        onChange={(e) => setValue('formFields.note', e.target.value)} // handle innerHTML change
                                        style={{ border: '2px solid rgba(0, 0, 0, 0.05)', padding: 10 }}
                                    />
                                    :
                                    <textarea name="description" cols="50" rows="20" {...register("formFields.note")} className="form-control-custom" />
                                }
                            </div>

                            <div className="form-group col-lg-12">
                                <div className="custom-control custom-checkbox ms-3">
                                    <input type="checkbox" className="custom-control-input" name="pinned" {...register("formFields.pinned")} checked={getValues().formFields?.pinned} onChange={handlePinnedChange} />
                                    <label className="custom-control-label">Pin This Note</label>
                                </div>
                            </div>
                        </div>

                        <button className="btn btn-submit" onClick={handleSubmit}>Save Note</button>
                    </div>
                }
                />
            </div>
        </div>
    );
}