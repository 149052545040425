import Axios from '../config/axios';

export function insertCustomerNote(custId, note, note_date, division, userId, pinned) {
    let postdata = {};
    postdata.custId = custId;
    postdata.note = note;
    postdata.note_date = note_date;
    postdata.division = division;
    postdata.salesperson = userId;
    postdata.pinned = pinned;

    return Axios.post(`/api/InsertCustomerNote`, postdata
    ).then(response =>
        response.data
    ).catch(error => error);
}

export function insertCustomerNoteBetter(postdata) {

    return Axios.post(`/api/InsertCustomerNote`, postdata
    ).then(response =>
        response.data
    ).catch(error => error);
}

export function processNotePinRequest(postdata) {
    return Axios.post(`/api/ProcessNotePinRequest`, postdata
    ).then(response =>
        response.data
    ).catch(error => error);
}