import React, { useEffect, useState, useRef } from "react";
import { withRouter } from 'react-router-dom';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import QRCode from 'qrcode.react';
import { useForm, Controller } from 'react-hook-form';
import Moment from 'moment';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import { Select, Empty, Spin, Alert, message, Tabs, Drawer, Table } from 'antd';
import { Fragment } from 'react';
import DocumentPane from './DocumentPane';

export default function WidgetRepDocumentLibrary(props) {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const [state, setState] = useState({
        searchRunning: true,
        documentEditMode: 'Add',
        selectedIdx: null,
        selectedDocument: {},
        ownedBy: userInfo.userId,
        docType: 'Private'
    });

    const { register, getValues, setValue, control, watch } = useForm();

    const [showDocumentPane, setShowDocumentPane] = useState(false);

    const [documents, setDocuments] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);

    const { Option } = Select;

    useEffect(() => {
        console.log(userInfo);

        LoadRepDocuments(state.ownedBy, userInfo.jobShopDivision, false);

    }, [props]);

    const columns = [
        {
            dataIndex: 'repDocumentLibrary_ID',
            title: 'repDocumentLibrary_ID',
            key: 'repDocumentLibrary_ID',
            hidden: true
        }, {
            dataIndex: 'documentName',
            title: 'Document Name',
            key: 'documentName',
            sorter: (a, b) => a.documentName.localeCompare(b.documentName)
        }, {
            dataIndex: 'lastUpdate',
            title: 'Upload Date',
            key: 'lastUpdate',
            width: 150,
            sorter: (a, b) => new Date(a.lastUpdate) - new Date(b.lastUpdate),
            render: (lastUpdate) => {
                return (
                    <div>
                        {lastUpdate === null ? '' : Moment(dateWithNoTimezone(lastUpdate)).format("L")}
                    </div>
                );
            }
        //}, {
        //    dataIndex: 'ownedBy',
        //    title: 'Owner',
        //    key: 'ownedBy',
        //    sorter: (a, b) => a.ownedBy.localeCompare(b.ownedBy)
        }, {
            dataIndex: 'shared',
            title: 'Document Type',
            key: 'shared',
            sorter: (a, b) => a.shared - b.shared,
            render: (shared) => {
                return (
                    <div>
                        {shared === true ? 'Shared' : 'Private'}
                    </div>
                );
            }
        }, {
            dataIndex: 'notes',
            title: 'Notes',
            key: 'notes',
            sorter: (a, b) => a.notes.localeCompare(b.notes),
        }, {
            title: 'Download Document',
            key: 'action',
            width: 120,
            render: (record) => (
                <span className="hover" onClick={(e) => DownloadDocument(e, record)}><i id="downloadDocument" className="fa fa-download fa-1x ml-3 text-center hover"></i></span>
            ),
            align: 'center'
        }, {
            title: 'Update Document',
            key: 'action',
            width: 120,
            render: (record) => (
                <span className="hover" onClick={(e) => LoadDocumentDetails(e, record)}><i id="templateDocument" className="fas fa-edit fa-1x ml-3 text-center hover"></i></span>
            ),
            align: 'center'
        }, {
            title: 'Delete Document',
            key: 'action',
            width: 120,
            render: (record) => (
                <i id="deleteDocument" className="far fa-trash-alt fa-1x ml-3 text-center hover text-danger" onClick={(e) => handleDeleteDocument(e, record)}></i>
            ),
            align: 'center'
        }
    ].filter(item => !item.hidden);

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    function handleDeleteDocument(e, document) {
        confirm({
            title: "You are about to permanently delete this Document",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"]
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                //let postdata = {
                //    emailTemplates_ID: template.emailTemplates_ID,
                //    app: 'WEBCM'
                //}

                let formdata = new FormData();
                formdata.append('documentID', document.repDocumentLibrary_ID);
                formdata.append('ownedBy', state.ownedBy);
                formdata.append('divisionFilter', userInfo.jobShopDivision);
                //if (state.docType === "Private") {
                //    formdata.append('sharedFilter', false);
                //}
                //else {
                //    formdata.append('sharedFilter', true);
                //}

                Axios.post(`/api/DeleteRepDocument`, formdata
                ).then(response => {
                    if (response.data) {
                        toast.success("Document has been deleted");

                        setDocuments(response.data);
                    }
                }).catch(error => {
                    console.log(error);
                });

                return 0;
            } else {
                return 0;
            }
        });
    }

    function LoadRepDocuments(userId, jobShopDivision, shared) {
        var docType;

        if (shared === true) {
            docType = 'Shared';
        }
        else {
            docType = 'Private';
        }

        Axios.get(`/api/GetRepDocuments`, {
            params: {
                OwnedBy: userId,
                Division: jobShopDivision,
                /*Shared: shared*/
            }
        }).then(response => {
            let documentsList = response.data;

            console.log(response.data);

            setDocuments(documentsList);

            setState({
                ...state,
                searchRunning: false,
                ownedBy: userId,
                docType: docType
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function LoadDocumentDetails(e, record) {
        setState({
            ...state,
            selectedDocument: record,
            documentEditMode: 'Edit'
        });

        setShowDocumentPane(true);
    }

    function setDocumentInfo(documents) {
        setDocuments(documents);
    }

    function DownloadDocument(e, doc) {
        let postdata = {
            itemId: doc.repDocumentLibrary_ID
        }

        const key = "downloadDoc";
        message.loading({
            content: 'Downloading Rep Document...',
            key,
            duration: 0,
            style: {
                position: "fixed",
                bottom: 0,
                left: 0
            },
        });

        Axios.post(`/api/DownloadRepDocument`, postdata, {
            responseType: 'blob'
        }).then(response => {
            message.destroy(key);

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = doc.documentName;
            a.click();
            window.URL.revokeObjectURL(url);
        }).catch(error => {
            console.log(error);
        });
    }

    function handleAddNewDocument() {
        setState({
            ...state,
            documentEditMode: 'Add',
        });

        setShowDocumentPane(true);
    }

    function handleSelectedDocTypeChange(e) {
        var shared;

        if (e.target.value == 'Private') {
            shared = false;
        }
        else {
            shared = true;
        }

        LoadRepDocuments(userInfo.userId, userInfo.jobShopDivision, shared);
    }

    return (
        <div className="code-box-demo">
            <p></p>
            {/*<div onChange={handleSelectedDocTypeChange}>*/}
            {/*    <label className="form-label">Document Type:</label>&nbsp;&nbsp;*/}
            {/*    <input type="radio" name="documentType" value="Private" className="mt-1" checked={state.docType === "Private"} />&nbsp;<label className="form-label">My Private Documents</label>&nbsp;&nbsp;&nbsp;*/}
            {/*    <input type="radio" name="documentType" value="Shared" className="mt-1" checked={state.docType === "Shared"} />&nbsp;<label className="form-label">My Shared Documents</label>&nbsp;&nbsp;&nbsp;*/}
            {/*</div>*/}

            <div className="row">
                <div className="col-lg-12">
                    <button className="btn btn-submit float-end" onClick={handleAddNewDocument}>Add New Document</button>
                </div>
                <div className="col-lg-12">&nbsp;</div>
                <div className="col-lg-12">
                    <Spin spinning={state.searchRunning}>
                        <div style={state.searchRunning ? {} : { display: 'none' }}>
                            <Alert
                                message="Loading Documents..."
                                description="Please stand by while we load your documents"
                                type="info"
                            />
                        </div>
                        {!state.searchRunning &&
                            <div className="row">
                                {documents.length === 0 ?
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                        <span>
                                            No documents found
                                        </span>
                                    } />
                                    :
                                    <Table className="custom-ant-selection"
                                        rowKey={item => item.repDocumentLibrary_ID}
                                        rowSelection={rowSelection}
                                        hideSelectionColumn={true}
                                        bordered
                                        dataSource={documents}
                                        columns={columns}
                                        pagination={{
                                            defaultPageSize: 10,
                                            showSizeChanger: true,
                                            pageSizeOptions: ['10', '25', '50', '100'],
                                            showTotal: (total, range) => (
                                                <span className="color-dark-blue" style={{ left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" }}>
                                                    Showing {range[0]}-{range[1]} of {total}
                                                </span>
                                            )
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: () => {
                                                    let selRows = [record.repDocumentLibrary_ID];
                                                    setSelectedRowKeys([...selRows]);
                                                },
                                                onDoubleClick: (e) => {
                                                    LoadDocumentDetails(e, record);
                                                }
                                            }
                                        }}
                                    />
                                }
                            </div>
                        }
                    </Spin>
                </div>

                {showDocumentPane &&
                    <DocumentPane {...props} hidePane={() => setShowDocumentPane(false)} selectedDocument={state.selectedDocument} updateDocumentInfo={(documents) => setDocumentInfo(documents)}
                        documentEditMode={state.documentEditMode} paneTitle='Update Document' ownedByFilter={state.ownedBy} />}
            </div>
        </div>
    );
}