import React, { useEffect, useState } from 'react';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import { useNavigate, useLocation } from "react-router-dom";
import Globals from '../../config/globals';
import SearchJSS from './SearchJSS';
import SearchJST from './SearchJST';

export default function CustomerSearch(props) {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const [state, setState] = useState({
        currentPage: 1,
        currentPageSize: 10
    });

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        let myPage = 1;
        let mySizePerPage = 10;
        let mySelectedCustomerId = null;

        console.log('here');

        if (location.state?.page !== undefined) { myPage = location.state.page; }
        if (location.state?.sizePerPage !== undefined) { mySizePerPage = location.state.sizePerPage; }
        if (location.state?.selectedCustomerId !== undefined) { mySelectedCustomerId = location.state.selectedCustomerId; }

        setState({
            ...state,
            selectedCustomerId: mySelectedCustomerId,
            currentPage: myPage,
            currentPageSize: mySizePerPage
        });

    }, [props]);

    return (
        <div className="row">
            <div className="col-lg-12">
                {userInfo.jobShopDivision === 'JSS' ? <SearchJSS selectedCustomerId={state.selectedCustomerId} page={state.currentPage} sizePerPage={state.currentPageSize} fromTopNav={location.state?.fromTopNav} /> : <SearchJST selectedCustomerId={state.selectedCustomerId} page={state.currentPage} sizePerPage={state.currentPageSize} />}
            </div>
        </div>
    );
}