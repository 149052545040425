import Axios from '../config/axios';

export function sendRepEmail(repId, sendTo, sendCc, emailSubject, content, selectedRepDocuments, fileList, saveToImap) {
    let formdata = new FormData();
    formdata.append('repId', repId);
    formdata.append('sendTo', sendTo);
    formdata.append('sendCc', sendCc);
    formdata.append('emailSubject', emailSubject);
    formdata.append('emailBody', content);
    formdata.append('selectedRepDocuments', selectedRepDocuments);
    formdata.append('saveToImap', saveToImap);

    fileList.forEach((file) => {
        formdata.append('files', file);
    });
    
    return Axios.post(`/api/SendRepEmail`, formdata
    ).then(response =>
        response.data
    ).catch(error => error);
}