import React, { useState, useEffect } from 'react';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import Moment from 'moment';
import { dateWithNoTimezone } from './DateFormat';
import { useForm, Controller } from "react-hook-form";
import { Modal, Empty, Spin, Select, Alert, Table, Space } from 'antd';
import ReactLoading from 'react-loading';
import { Fragment } from 'react';

export default function SelectShowModal(props) {
    const { register, getValues, setValue, control, watch } = useForm();

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const { Option } = Select;

    const [state, setState] = useState({
        showModal: props.showModal,
        shows: [],
        selectMode: 'single',
        selectedShow: [],
        loading: true
    });

    const [selectedShow, setSelectedShow] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);

    useEffect(() => {
        console.log(props.selectedReport);

        setState({
            ...state,
            loading: true
        });

        let postdata = {};
        Axios.post(`/api/GetActiveShowList`, postdata
        ).then(response => {
            let shows = response.data;

            setState({
                ...state,
                shows,
                showModal: true,
                loading: false
            });
        }).catch(error => {
            console.log(error);
        });
    }, [props]);

    function closeModal() {
        //This prevents the modal from opening in the parent unless called upon again
        props.hideSelectShowModal();

        setState({
            ...state,
            showModal: false
        });
    }

    function openModal() {
        setState({
            ...state,
            showModal: true
        });
    }

    const columns = [
        {
            dataIndex: 'showcode',
            key: 'showcode',
            width: 50,
        },
        {
            title: 'Show',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: "Show Dates",
            dataIndex: "showdates",
            render: (text, record) => (
                <span>{Moment(dateWithNoTimezone(record.startdate)).format("L")} - {Moment(dateWithNoTimezone(record.finishdate)).format("L")}</span>
            )
        }
    ];

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    function onRunReport() {
        props.setSelectedShowCode(state.selectedShow);
    }

    function handleSelectdShowChange(value) {
        //var myShowName = '';

        //console.log(value);

        //for (var j = 0; j < state.shows.length; j++) {
        //    if (state.shows[j].showcode === value) {
        //        myShowName = state.shows[j].name;
        //    }
        //}

        setState({
            ...state,
            selectedShow: value
        });
    }

    return (
        <>
            <Modal
                visible={state.showModal}
                title={state.modalTitle}
                onCancel={closeModal}
                footer={[
                    <button className="btn btn-submit" onClick={onRunReport}>
                        Run Report
                    </button>,
                    <span>&nbsp;&nbsp;</span>,
                    <button className="btn btn-outline-primary" onClick={closeModal}>
                        Close
                    </button>
                ]}
            >
                <h5>{props.selectedReport} Report</h5>
                Select D2P Show
                {state.loading ?
                    <ReactLoading className='tableLoading' type={"spokes"} color={'Navy'} width={30}
                        style={{ display: 'flex', alignItems: 'center', height: '100%', margin: '0 auto', width: '30px', fill: '#6FB246' }}
                    />
                    :
                    <Fragment>
                        {
                            state.shows.length > 0 ?
                                //<Select mode={state.selectMode} className="form-control-custom d-inline-block" onChange={handlePastShowsChange} style={{ width: '100%' }} placeholder="Please select one or more past shows" >
                                //    {state.shows.map((show, idx) => <option key={idx} value={show.showcode}>{show.name} <span>{Moment(dateWithNoTimezone(show.startdate)).format("L")} - {Moment(dateWithNoTimezone(show.finishdate)).format("L")}</span></option>)}
                                //</Select>

                                <Select mode={state.selectMode} className="form-control-custom d-inline-block" onChange={handleSelectdShowChange} style={{ width: '100%' }} placeholder="Please select one or more past shows" >
                                    {state.shows.map((show, idx) => <option key={idx} value={show.showcode}><div style={{ width: '50%', float: 'left' }}>{show.name}</div><div style={{ width: '50%', float: 'right' }}>{Moment(dateWithNoTimezone(show.startdate)).format("L")} - {Moment(dateWithNoTimezone(show.finishdate)).format("L")}</div></option>)}
                                </Select>

                                //<>
                                //    <Table
                                //        width={'50vw'}
                                //        rowKey={item => item.showcode}
                                //        rowSelection={rowSelection}
                                //        dataSource={state.shows}
                                //        columns={columns}
                                //        size='small'
                                //        pagination={false}
                                //        rowClassName={(record, index) => record.showcode === selectedShow?.showcode ? 'row-selected' : ''}
                                //        scroll={{
                                //            y: '30vh',
                                //        }}
                                //        onRow={(record, index) => {
                                //            return {
                                //                onClick: () => {
                                //                    let selRows = [record.showcode];
                                //                    setSelectedRowKeys([...selRows]);
                                //                },
                                //                onDoubleClick: (e) => {
                                //                    setSelectedShow(record);
                                //                    onSelectShow();
                                //                }
                                //            }
                                //        }}
                                //    />
                                //</>
                                :
                                <Empty description={<p>No Shows Found</p>} />
                        }
                    </Fragment>
                }
                
            </Modal>
        </>
    );
}