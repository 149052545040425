import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import Globals from '../../config/globals';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import Moment from 'moment';
import ribbonLogo from '../../img/login_logo.png';
import { Button, Modal } from 'react-bootstrap';
import $ from 'jquery';

export default class LeftNav extends Component {

    constructor(props) {
        super(props);

        if (props.enrolledShows?.length > 0) {
            Globals.defaultShowCode = props.enrolledShows[0].showDetail.showcode.toString();
        }

        this.state = {
            showOnMobile: false,
            enrolledShows: props.enrolledShows,
            formFields: {
                company_id: Globals.userInfo.companyId
            },
            showContractModal: false,
            msgType: 'initial',
            declineButtonText: 'DECLINE',
            selectedShow: '',
            showMenuId: '',
            lastGoodMenuId: '',
            exhibitorHasLeadsToManage: false
        }
    }

    componentDidMount() {
        this.handleNavigationChange();
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : parseInt(target.value);
        const name = target.name;

        Globals.userInfo.companyId = value;
        window.sessionStorage.setItem("userInfo", JSON.stringify(Globals.userInfo));

        //Axios.get(`/api/GetEnrolledShowsByCompanyId`, {
        //    params: {
        //        id: value
        //    }
        //}).then(response => {
        //    let enrolledShows = Globals.enrolledShows;

        //    enrolledShows = response.data;

        //    Globals.enrolledShows = enrolledShows;
        //    window.sessionStorage.setItem("enrolledShows", JSON.stringify(enrolledShows));
        //    this.setState({
        //        enrolledShows
        //    });

        //    if (enrolledShows.length > 0) {
        //        let path = {
        //            pathname: '/show-dashboard', state: { showcode: response.data[0].showDetail.showcode }
        //        };
        //        this.props.history.replace(path);

        //        $('.nav li').removeClass("active-nav-item");
        //        $("#showDashboard0").addClass("active-nav-item");
        //    } else {
        //        let path = '/exhibitor-home';
        //        this.props.history.replace(path);

        //        $('.nav li').removeClass("active-nav-item");
        //        $("#companyProfile").addClass("active-nav-item");
        //    }
            

        //}).catch(error => {
        //    console.log(error);
        //});

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    handleNavigationChange = () => {
        //$('.nav li').removeClass("active-nav-item");
        ////console.log(this.props.location.pathname);
        //switch (this.props.location.pathname) {
        //    case '/exhibitor-home':
        //        $("#companyProfile").addClass("active-nav-item");
        //        break;
        //    case '/program-listing':
        //        $("#programListing").addClass("active-nav-item");
        //        break;
        //    case '/exhibitor-scam-warning':
        //        $("#exhibitorScamWarning").addClass("active-nav-item");
        //        break;
        //    case '/show-dashboard':
        //        $("#showDashboard"+0).addClass("active-nav-item");
        //        break;
        //    case '/manage-my-leads':
        //        $("#manageMyLeads").addClass("active-nav-item");
        //        break;
        //    default:
        //        break;
        //}
    }

    handleNavigationClick = (event, id) => {
        console.log(event.target.id);
        console.log(id);

        event.preventDefault();
        let path = '';
        $('.nav li').removeClass("active-nav-item");

        this.setState({
            showMenuId: id
        });

        switch (event.target.id) {
            case 'companyProfile':
                console.log('here');
                //path = '/exhibitor-home';
                //this.props.history.replace(path);
                $("#companyProfile").addClass("active-nav-item");
                break;
            case 'programListing':
                //path = '/program-listing';
                //this.props.history.replace(path);
                $("#programListing").addClass("active-nav-item");
                break;
            case 'exhibitorScamWarning':
                //path = '/exhibitor-scam-warning';
                //this.props.history.replace(path);
                $("#exhibitorScamWarning").addClass("active-nav-item");
                break;
            case 'showDashboard':
                this.setState({
                    selectedShow: event.target.getAttribute('name')
                });

                break;
            case 'admin':
                //path = '/admin-home';
                //this.props.history.replace(path);
                break;
            case 'worklist':
                //path = '/worklist';
                //this.props.history.replace(path);
                break;
            case 'tab4a':
                $(".listHeader").removeClass("active open");
                $("#tab3List").toggleClass("active open");
                //path = '/providers';
                //this.props.history.replace(path);
                break;
            case 'manageMyLeads':
                //path = '/manage-my-leads';
                //this.props.history.replace(path);
                $("#manageMyLeads").addClass("active-nav-item");
                break;
            default:
                break;
        }
    }

    render() {
        return (
            <div className="page-sidebar">
                <ul className="nav nav-tabs nav-clients nav-stacked flex-column" role="tablist">
                    <li className="text-center pt-3 pb-3">
                        <img alt="Design-2-Part Company" id="dashboardBtn" src={ribbonLogo} style={{ height: '50px' }} />
                    </li>
                    <li className="nav-item" id="companyProfile" onClick={this.handleNavigationClick}>
                        <a className="nav-link fs-lg px-4" id="companyProfile" onClick={this.handleNavigationClick}>
                            <span className="hidden-sm-down ml-1" id="companyProfile" onClick={this.handleNavigationClick}>
                                Company Profile
                            </span>
                        </a>
                    </li>
                    <li className="nav-item" id="programListing" onClick={this.handleNavigationClick}>
                        <a className="nav-link fs-lg px-4" id="programListing" onClick={this.handleNavigationClick}>
                            <span className="hidden-sm-down ml-1" id="programListing" onClick={this.handleNavigationClick}>
                                Show Program Listing
                            </span>
                        </a>
                    </li>
                    <li className="nav-item" id="exhibitorScamWarning" onClick={this.handleNavigationClick}>
                        <a className="nav-link fs-lg px-4" id="exhibitorScamWarning" onClick={this.handleNavigationClick}>
                            <span className="hidden-sm-down ml-1" id="exhibitorScamWarning" onClick={this.handleNavigationClick}>
                                Exhibitor Email Scam Warning
                            </span>
                        </a>
                    </li>
                    <li>&nbsp;</li>
                    
                    <div>
                        <span className="font-weight-bold header-icon mimicA top-nav-item font-size-large" title={'Logout, ' + Globals.userInfo.fullName}>
                            <span className="text-icon">LOGOUT</span>&nbsp;
                            <i className="fas fa-sign-out-alt logoutIcon text-icon"></i>
                        </span>
                    </div>
                </ul>
            </div>
        )
    }
}