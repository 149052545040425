import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Select, Empty, Spin, Alert, Table } from 'antd';
import Globals from '../../config/globals';
import DatePicker from 'react-datepicker';
import { TbUserCheck } from 'react-icons/tb';
import { GiJumpAcross } from 'react-icons/gi';


export default function SearchJSS(props) {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const { register, getValues, setValue, control, watch } = useForm();

    const [state, setState] = useState({
        shows: [],
        qccerts: [],
        prospectsources: [],
        servicecategories: [],
        states: [],
        customers: [],
        dataLoaded: false,
        searchRunning: false,
        hasSearchBeenRunYet: false,
        currentPage: 1,
        currentPageSize: 10
    });

    const [loadingDropdowns, setLoadingDropdowns] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);

    useEffect(() => {
        setLoadingDropdowns(true);

        LoadSearchFilters();

        var mySearchRunning;
        if (location.state.selectedCustomerId) {
            mySearchRunning = true;
        }
        else {
            mySearchRunning = false;
        }

        setState({
            ...state,
            searchRunning: mySearchRunning
        });

        //This option clears all the last search filter selections
        //ResetSearchCriteria();

        //This option would re-load the last search filter selections
        //Note: Still needs work when switching between JSS/JST profiles - probably need to clear out session storage since objects are different
        /*SetSearchCriteria();*/

    }, [props]);

    useEffect(() => {
        let myPage = 1;
        let mySizePerPage = 10;
        if (props.page !== undefined) { myPage = props.page; }
        if (props.sizePerPage !== undefined) { mySizePerPage = props.sizePerPage; }
        if (props.selectedCustomerId !== undefined) {
            setValue('selectedCustomerId', props.selectedCustomerId);

            //this highlights the previously selected row in the table 
            let selRows = [props.selectedCustomerId];
            setSelectedRowKeys([...selRows]);
        }

        if (loadingDropdowns === false) {
            //console.log(state.currentPage);

            if (props.fromTopNav === true) {
                ResetSearchCriteria();
            }
            else {
                setState({
                    ...state,
                    searchRunning: true
                });

                SetSearchCriteria(myPage, mySizePerPage); // This is be executed when `loadingDropdowns` state changes
            }
        }
    }, [loadingDropdowns])

    function LoadSearchFilters() {
        let postdata = {};
        postdata.jobshopdivision = userInfo.jobShopDivision

        Axios.post(`/api/GetJSSSearchFilters`, postdata
        ).then(response => {
            let shows = response.data.shows;
            let qccerts = response.data.qccerts;
            let prospectsources = response.data.prospectsources;
            let states = response.data.states;
            let servicecategories = response.data.servicecategories;

            setState({
                ...state,
                shows,
                qccerts,
                prospectsources,
                servicecategories,
                states
            });

            setLoadingDropdowns(false);

        }).catch(error => {
            console.log(error);
        });
    }

    function handleJumpTo(e, record, index) {
        navigate("/customerview", { state: { selectedCustomerId: record.id, callingComponent: 'SearchJSS', page: state.currentPage, sizePerPage: state.currentPageSize } });
    }

    const columns = [
        {
            dataIndex: 'id',
            title: 'ID',
            key: 'id',
            width: 100
        }, {
            dataIndex: 'name',
            title: 'Name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name)
        }, {
            dataIndex: 'street1',
            title: 'Address',
            key: 'street1',
            sorter: (a, b) => a.street1.localeCompare(b.street1)
        }, {
            dataIndex: 'state',
            title: 'State',
            key: 'state',
            width: 100,
            sorter: (a, b) => a.state.localeCompare(b.state)
        }, {
            dataIndex: 'exhibitor',
            title: 'Type',
            key: 'exhibitor',
            width: 130,
            align: 'center',
            sorter: (a, b) => a.exhibitor - b.exhibitor,
            render: (exhibitor) => {
                return (
                    <div>
                        {exhibitor ? 'Exhibitor' : 'Prospect'}
                    </div>
                );
            }
        }, {
            title: 'Jump To',
            key: 'action',
            width: 120,
            render: (text, record, index) => 
                <div id="jumpTo" className="hover"><TbUserCheck id="jumpTo" className="fa-1x text-icon" onClick={(e) => handleJumpTo(e, record, index)} /></div>
            ,
            align: 'center'
        }
    ].filter(item => !item.hidden);

    const onSelectChange = (newSelectedRowKeys) => {
        /*console.log('selectedRowKeys changed: ', newSelectedRowKeys);*/
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    function SetSearchCriteria(myPage, mySizePerPage) {
        setState({
            ...state,
            searchRunning: true,
            dataLoaded: false
        });

        let lastSearchCriteria = JSON.parse(window.sessionStorage.getItem("lastSearchCriteria"));

        if (lastSearchCriteria === null) {
            ResetSearchCriteria();
            return;
        }

        setValue('formFields.searchTarget', lastSearchCriteria.searchtarget);
        setValue('formFields.searchType', lastSearchCriteria.searchtype);
        setValue('formFields.custid', lastSearchCriteria.custid);
        setValue('formFields.company', lastSearchCriteria.company);
        setValue('formFields.contact', lastSearchCriteria.contact);
        setValue('formFields.phone', lastSearchCriteria.phone);
        setValue('formFields.fax', lastSearchCriteria.fax);
        setValue('formFields.city', lastSearchCriteria.city);
        setValue('formFields.email', lastSearchCriteria.email);
        
        //sales status
        if (lastSearchCriteria.salesstatuses !== '') {
            setValue('formFields.salesstatuses', lastSearchCriteria.salesstatuses.split(","));
        }
        else {
            setValue('formFields.salesstatuses', []);
        }

        //shows
        if (lastSearchCriteria.shows !== '') {
            setValue('formFields.shows', lastSearchCriteria.shows.split(",").map(Number));
        }
        else {
            setValue('formFields.shows', []);
        }

        //states
        if (lastSearchCriteria.states !== '') {
            setValue('formFields.states', lastSearchCriteria.states.split(","));
        }
        else {
            setValue('formFields.states', []);
        }

        //source
        if (lastSearchCriteria.prospectsources !== '') {
            setValue('formFields.prospectsources', lastSearchCriteria.prospectsources.split(","));
        }
        else {
            setValue('formFields.prospectsources', []);
        }

        //service categories
        if (lastSearchCriteria.servicecategories !== '') {
            setValue('formFields.servicecategories', lastSearchCriteria.servicecategories.split(","));
        }
        else {
            setValue('formFields.servicecategories', []);
        }

        //qccerts
        if (lastSearchCriteria.qccerts !== '') {
            setValue('formFields.qccerts', lastSearchCriteria.qccerts.split(","));
        }
        else {
            setValue('formFields.qccerts', []);
        }

        setState({
            ...state
        });

        if (lastSearchCriteria.searchtarget !== 'Both' || lastSearchCriteria.searchtype !== 'StartsWith' || lastSearchCriteria.custid !== '' || lastSearchCriteria.company !== '' || lastSearchCriteria.contact !== '' ||
            lastSearchCriteria.phone !== '' || lastSearchCriteria.fax !== '' || lastSearchCriteria.city !== '' || lastSearchCriteria.email !== '' || lastSearchCriteria.salesstatuses !== '' ||
            lastSearchCriteria.shows !== '' || lastSearchCriteria.states !== '' || lastSearchCriteria.prospectsources !== '' || lastSearchCriteria.qccerts !== '')
        {
            toast.info("Your last search criteria filters were re-loaded");

            handleSearchClick(myPage, mySizePerPage);
        }
    }

    function ResetSearchCriteria() {
        setValue('formFields.searchTarget', 'Both');
        setValue('formFields.searchType', 'StartsWith');
        setValue('formFields.custid', '');
        setValue('formFields.company', '');
        setValue('formFields.contact', '');
        setValue('formFields.phone', '');
        setValue('formFields.fax', '');
        setValue('formFields.city', '');
        setValue('formFields.email', '');
        setValue('formFields.shows', []);
        setValue('formFields.salesstatuses', []);
        setValue('formFields.servicecategories', []);
        setValue('formFields.qccerts', []);
        setValue('formFields.prospectsources', []);
        setValue('formFields.states', []);
        setValue('selectedCustomerId', null);

        setState({
            ...state,
            customers: [],
            hasSearchBeenRunYet: false,
            currentPage: 1,
            currentPageSize: 10
        });

        window.sessionStorage.removeItem("lastSearchCriteria");
    }

    function handleSearchClick(myPage, mySizePerPage) {
        if (getValues().formFields.searchTarget === 'Both' && getValues().formFields.searchType === 'StartsWith' && getValues().formFields.custid === '' && getValues().formFields.company === '' && getValues().formFields.contact === '' &&
            getValues().formFields.phone === '' && getValues().formFields.fax === '' && getValues().formFields.city === '' && getValues().formFields.email === '' && String(getValues().formFields.salesstatuses) === "" &&
            String(getValues().formFields.shows) === "" && String(getValues().formFields.states) === "" && String(getValues().formFields.servicecategories) === "" && String(getValues().formFields.prospectsources) === "" && String(getValues().formFields.qccerts) === "") {
            toast.error("You must select at least one field to search on");

            return;
        }

        setState({
            ...state,
            searchRunning: true,
            dataLoaded: false
        });

        let postdata = {
            searchtarget: getValues().formFields.searchTarget,
            searchtype: getValues().formFields.searchType,
            custid: getValues().formFields.custid,
            company: getValues().formFields.company,
            contact: getValues().formFields.contact,
            phone: getValues().formFields.phone,
            fax: getValues().formFields.fax,
            city: getValues().formFields.city,
            states: String(getValues().formFields.states),
            email: getValues().formFields.email.replace('@', '@@'),
            prospectsources: String(getValues().formFields.prospectsources),
            servicecategories: String(getValues().formFields.servicecategories),
            salesstatuses: String(getValues().formFields.salesstatuses),
            shows: String(getValues().formFields.shows),
            qccerts: String(getValues().formFields.qccerts)
        };
        window.sessionStorage.setItem("lastSearchCriteria", JSON.stringify(postdata));

        Axios.post(`/api/GetJSSCompaniesByDetailedSearchValues`, postdata
        ).then(response => {
            let customers = response.data;

            setState({
                ...state,
                customers,
                hasSearchBeenRunYet: true,
                currentPage: myPage,
                currentPageSize: mySizePerPage,
                dataLoaded: true,
                searchRunning: false
            });

        }).catch(error => {
            console.log(error);
        });
    }

    function handleShowsChange(value) {
        setValue('formFields.shows', value);
        setState({ ...state });
    }

    function handleSalesStatusesChange(value) {
        setValue('formFields.salesstatuses', value);
        setState({ ...state });
    }

    function handleQCCertsChange(value) {
        setValue('formFields.qccerts', value);
        setState({ ...state });
    }

    function handleProspectSourcesChange(value) {
        setValue('formFields.prospectsources', value);
        setState({ ...state });
    }

    function handleServiceCategoryChange(value) {
        setValue('formFields.servicecategories', value);
        setState({ ...state });
    }

    function handleStatesChange(value) {
        setValue('formFields.states', value);
        setState({ ...state });
    }

    const { Option } = Select;

    function updatePageState(page, pageSize) {
        setState({
            ...state,
            currentPage: page,
            currentPageSize: pageSize
        });
    }

    return (
        <div className="row">
            <div className="form-group col-lg-3 col-12">
                <h3 className="frame-heading">
                    D2P Shows Customer Search
                </h3>
            </div>
            <div className="form-group col-lg-3 col-12">
                <div className="rcorners p-2">
                    <label className="form-label">Search For:</label>&nbsp;&nbsp;
                    <input type="radio" name="searchTarget" {...register("formFields.searchTarget")} value="Customers" className="mt-1" />&nbsp;<label className="form-label">Exhibitors</label>&nbsp;&nbsp;&nbsp;
                    <input type="radio" name="searchTarget" {...register("formFields.searchTarget")}value="Prospects" className="mt-1" />&nbsp;<label className="form-label">Prospects</label>&nbsp;&nbsp;&nbsp;
                    <input type="radio" name="searchTarget" {...register("formFields.searchTarget")}value="Both" className="mt-1" />&nbsp;<label className="form-label">Both</label>
                </div>
            </div>
            <div className="form-group col-lg-1 col-12"></div>
            <div className="form-group col-lg-3 col-12">
                <div className="rcorners p-2">
                    <label className="form-label">Search Type:</label>&nbsp;&nbsp;
                    <input type="radio" name="searchType" {...register("formFields.searchType")} value="StartsWith" className="mt-1" />&nbsp;<label className="form-label">Starts With</label>&nbsp;&nbsp;&nbsp;
                    <input type="radio" name="searchType" {...register("formFields.searchType")} value="Includes" className="mt-1" />&nbsp;<label className="form-label">Includes</label>&nbsp;&nbsp;&nbsp;
                </div>
            </div>
            <div className="form-group col-lg-2 col-12"></div>

            <div className="form-group col-lg-1 col-12">
                <label className="form-label">Cust ID</label>
                <input type="text" {...register("formFields.custid")} className="form-control-custom" />
            </div>

            <div className="form-group col-lg-2 col-12">
                <label className="form-label">Company</label>
                <input type="text" {...register("formFields.company")} className="form-control-custom" />
            </div>

            <div className="form-group col-lg-2 col-12">
                <label className="form-label">Contact</label>
                <input type="text" {...register("formFields.contact")} className="form-control-custom" />
            </div>

            <div className="form-group col-lg-2 col-12">
                <label className="form-label">Phone</label>
                <input type="text" {...register("formFields.phone")} className="form-control-custom" />
            </div>
            <div className="form-group col-lg-2 col-12">
                <label className="form-label">Fax</label>
                <input type="text" {...register("formFields.fax")} className="form-control-custom" />
            </div>
            <div className="form-group col-lg-2 col-12">
                <label className="form-label">City</label>
                <input type="text" {...register("formFields.city")} className="form-control-custom" />
            </div>
            <div className="form-group col-lg-1 col-12">
                <label className="form-label">State</label>
                <Select id="ddProspectStates"
                    value={getValues().formFields?.states}
                    className="form-control-custom"
                    mode="multiple"
                    placeholder="Select one or more States"
                    onChange={handleStatesChange}
                    className="form-control-custom w-100"
                    bordered={false}
                >
                    {state.states.map((s) => <Option key={s.fullName} value={s.state}>{s.state}</Option>)}
                </Select>
            </div>

            <div className="form-group col-lg-3 col-12">
                <label className="form-label">Email</label>
                <input type="text" {...register("formFields.email")} className="form-control-custom" />
            </div>
            <div className="form-group col-lg-2 col-12">
                <label className="form-label">Prospect Source</label>
                <Select id="ddProspectSources"
                    value={getValues().formFields?.prospectsources}
                    className="form-control-custom"
                    mode="multiple"
                    placeholder="Select one or more Sources"
                    onChange={handleProspectSourcesChange}
                    className="form-control-custom w-100"
                    bordered={false}
                >
                    {state.prospectsources.map((src) => <Option key={src.source} value={src.source}>{src.source}</Option>)}
                </Select>
            </div>
            <div className="form-group col-lg-1 col-12">
                <label className="form-label">Sales Status</label>
                <Select id="ddStatus"
                    value={getValues().formFields?.salesstatuses}
                    className="form-control-custom"
                    mode="multiple"
                    placeholder="Select one or more Status"
                    onChange={handleSalesStatusesChange}
                    className="form-control-custom w-100"
                    bordered={false}
                >
                    <Option value='Cancel'>Cancel</Option>
                    <Option value='Interested'>Interested</Option>
                    <Option value='No'>No</Option>
                    <Option value='Till'>Till</Option>
                    <Option value='Waitlist'>Waitlist</Option>
                    <Option value='Yes'>Yes</Option>
                </Select>
            </div>
            <div className="form-group col-lg-2 col-12">
                <label className="form-label">Show</label>
                <Select id="ddSShows"
                    value={getValues().formFields?.shows}
                    className="form-control-custom"
                    mode="multiple"
                    placeholder="Select one or more Shows"
                    onChange={handleShowsChange}
                    className="form-control-custom w-100"
                    bordered={false}
                >
                    {state.shows.map((show) => <Option key={show.showcode} value={show.showcode}>{show.name}</Option>)}
                </Select>
            </div>
            <div className="form-group col-lg-2 col-12">
                <label className="form-label">Service Category</label>
                <Select id="ddProspectSources"
                    value={getValues().formFields?.servicecategories}
                    className="form-control-custom"
                    mode="multiple"
                    placeholder="Select one or more service category"
                    onChange={handleServiceCategoryChange}
                    className="form-control-custom w-100"
                    bordered={false}
                >
                    {state.servicecategories.map((scat) => <Option key={scat.category} value={scat.category}>{scat.categoryName}</Option>)}
                </Select>
            </div>
            <div className="form-group col-lg-2 col-12">
                <label className="form-label">QC Certs</label>
                <Select id="ddQCCerts"
                    value={getValues().formFields?.qccerts}
                    className="form-control-custom"
                    mode="multiple"
                    placeholder="Select one or more QC Certs"
                    onChange={handleQCCertsChange}
                    className="form-control-custom w-100"
                    bordered={false}
                >
                    {state.qccerts.map((cert) => <Option key={cert.qcname} value={cert.qcname}>{cert.qcname}</Option>)}
                </Select>
            </div>

            <div className="form-group col-lg-12 col-12">
                <Button className="btn btn-submit" onClick={() => handleSearchClick(state.currentPage, state.currentPageSize)} >Search</Button><button className="btn btn-outline-primary ms-3" onClick={ResetSearchCriteria}>Reset Selections</button>
            </div>

            <div className="form-group col-lg-12 col-12">
                <hr />
                <Spin spinning={state.searchRunning}>
                    <div style={state.searchRunning ? {} : { display: 'none' }}>
                        <Alert
                            message="Loading Companies..."
                            description="Please stand by while we retrieve companies that match your selected criteria"
                            type="info"
                        />
                    </div>
                    {!state.searchRunning &&
                        <div className="row">
                            {state.customers.length === 0 ?
                                <div style={state.dataLoaded === true ? {} : { display: 'none' }}>
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                        <span>
                                            {state.hasSearchBeenRunYet === false ? "Search for Exhibitors and Exhibitor Prospects using the search criteria above" : "No companies found matching your search criteria"}
                                        </span>
                                    } />
                                </div>
                                :
                                <Table className="custom-ant-selection"
                                    rowKey={item => item.id}
                                    rowSelection={rowSelection}
                                    hideSelectionColumn={true}
                                    bordered
                                    dataSource={state.customers}
                                    columns={columns}
                                    pagination={{
                                        defaultPageSize: 10,
                                        showSizeChanger: true,
                                        pageSizeOptions: ['10', '25', '50', '100'],
                                        showTotal: (total, range) => (
                                            <span className="color-dark-blue" style={{ left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" }}>
                                                Showing {range[0]}-{range[1]} of {total}
                                            </span>
                                        ),
                                        onChange: (page, pageSize) => {
                                            updatePageState(page, pageSize);
                                        },
                                        current: state.currentPage,
                                        pageSize: state.currentPageSize
                                    }}
                                    onRow={(record, index) => {
                                        return {
                                            onClick: () => {
                                                let selRows = [record.id];
                                                setSelectedRowKeys([...selRows]);
                                            },
                                            onDoubleClick: (e) => {
                                                handleJumpTo(e, record, index);
                                            }
                                        }
                                    }}
                                />
                            }
                        </div>
                    }
                </Spin>
            </div>
        </div>
    );
}