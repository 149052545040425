import React, { useEffect, useState } from 'react';
import Axios from '../../config/axios';
import logo from '../../img/login_logo.png';
import './navigation.css';
import { Popover, Select, Drawer, Modal } from 'antd';
import Helmet from 'react-helmet';
import CustomSearch from './CustomSearch';
import { toast, confirm } from '@rickylandino/react-messages';
import { FaUserCheck } from 'react-icons/fa';
import { FaUserPlus } from 'react-icons/fa';
import { FaPersonBooth } from 'react-icons/fa';
import { TbReportMoney } from 'react-icons/tb';
import { TbUserCheck } from 'react-icons/tb';
import { TbUserPlus } from 'react-icons/tb';
import { TbReport } from 'react-icons/tb';
import { TbBook } from 'react-icons/tb';
import { GrShop } from 'react-icons/gr';
import { BsMegaphone } from 'react-icons/bs';
import { MdOutlineWidgets } from 'react-icons/md';
import { BsGear } from 'react-icons/bs';
import SlidingPane from 'react-sliding-pane';
import $ from 'jquery';
import { useNavigate } from "react-router-dom";
import { activeRoute } from '../../services/SpaService';
import { takeWhile } from 'rxjs/operators';
import Globals from '../../config/globals';
import { useForm, Controller } from "react-hook-form";
import ReportsSlider from '../ReportingOutput/ReportsSlider';
import SelectWidgetSlider from './SelectWidgetSlider';
import WidgetMailCodeCounts from '../Widgets/WidgetMailCodeCounts';
import WidgetRegistrationCounts from '../Widgets/WidgetRegistrationCounts';
import WidgetSalesByShow from '../Widgets/WidgetSalesByShow';
import WidgetCallSheets from '../Widgets/WidgetCallSheets';
import WidgetRepDocumentLibrary from '../Widgets/WidgetRepDocumentLibrary';
import WidgetRepEmailTemplateLibrary from '../Widgets/WidgetRepEmailTemplateLibrary';

export default function TopNav(props) {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
    const navigate = useNavigate();

    const { register, getValues, setValue, control, watch } = useForm();

    const [state, setState] = useState({
        showReportsSlider: false,
        showJSTReportsSlider: false,
        showSelectWidgetSlider: false,
        myWidgets: [],
        allWidgets: [],
        showWidgetPane: false,
        showWidgetModal: false,
        selectedWidget: {},
        selectedWidgetName: '',
        widgetIconVisible: false
    });

    const componentMap = [
        { "WidgetName": "RegistrationCounts", "WidgetComponent": <WidgetRegistrationCounts /> },
        { "WidgetName": "SalesByShow", "WidgetComponent": <WidgetSalesByShow /> },
        { "WidgetName": "MailCodeCounts", "WidgetComponent": <WidgetMailCodeCounts /> },
        { "WidgetName": "CallSheets", "WidgetComponent": <WidgetCallSheets /> },
        { "WidgetName": "RepDocumentLibrary", "WidgetComponent": <WidgetRepDocumentLibrary /> },
        { "WidgetName": "RepEmailTemplateLibrary", "WidgetComponent": <WidgetRepEmailTemplateLibrary /> }
    ];

    useEffect(() => {
        var alive = true;

        //let userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
        setValue('currentUser', userInfo);
        
        //setState({ ...state });

        //console.log(getValues().currentUser);

        activeRoute.pipe(takeWhile(() => alive)).subscribe((route) => {
            switch (route) {
                case '/dashboard':
                    $(".header-icon").removeClass("active");
                    $("#dashboardBtn").addClass("active");
                    break;
                case '/accounts':
                    $(".header-icon").removeClass("active");
                    $("#accountsBtn").addClass("active");
                    break;
                //case '/sub-item-1':
                //case '/sub-item-2':
                //case '/sub-item-3':
                //    $(".header-icon").removeClass("active");
                //    $("#option2").addClass("active");
                //    break;
                case '/campaigns':
                    $(".header-icon").removeClass("active");
                    $("#campaignsBtn").addClass("active");
                    break;
                case '/opportunities':
                    $(".header-icon").removeClass("active");
                    $("#opportunitiesBtn").addClass("active");
                    break;
                default:
                    $(".header-icon").removeClass("active");
                    break;
            }
        });

        LoadDashboardWidgets();
    }, []);

    useEffect(() => {        
        setState({
            ...state,
            showSelectWidgetSlider: false
        });

    }, [props]);

    async function LoadDashboardWidgets() {
        let postdata = {};
        postdata.webcmRoles = userInfo.webcmRoles;
        postdata.jobShopDivision = userInfo.jobShopDivision;

        await Axios.post('/api/GetDashboardWidgets', postdata
        ).then(async response => {
            const myWidgets = await response.data.myDashboardWidgets;
            const allWidgets = await response.data.allDashboardWidgets;
            

            let widgetIconVisible = true;
            if (myWidgets === null || myWidgets.length === 0) {
                widgetIconVisible = false;
            }

            setState({
                ...state,
                myWidgets: myWidgets,
                allWidgets: allWidgets,
                widgetIconVisible,
                showSelectWidgetSlider: false
            });

        }).catch(error => {
            console.log(error);
        });
    }

    //function createComponent(componentName, props, children) {
    //    console.log(componentName);

    //    var Component = React.createElement(eval(componentName), props, children);
    //    return Component;
    //}

    //function createComponent(componentName, props, children) {
    //    console.log(componentName);

    //    var component = React.createClass({ componentName});

    //    //var component = React.createElement(eval(componentName), props, children);
    //    return component;
    //}

    //function createComponent(componentName, props, children) {
    //    //var component = Components[itemData['itemClass']]);

    //    //return React.createElement('WidgetMailCodeCounts', props, children);
    //    console.log('here in createComponent');

    //    //var MyComponent = <div dangerouslySetInnerHTML={{ __html: 'WidgetMailCodeCounts' }} />

    //    const MyComponent = 'WidgetMailCodeCounts';
    //    return <MyComponent />;

    //    //return React.createElement('WidgetMailCodeCounts', {
    //    //    //data: itemData,
    //    //    //key: itemData['id']
    //    //});
    //}

    async function loadSelectedWidget(widgetId, widgetName) {
        var selectedWidget;

        componentMap.forEach((c, idx) => {
            if (c.WidgetName === widgetId) {
                selectedWidget = c.WidgetComponent;
            }
        });

        setState({
            ...state,
            showSelectWidgetSlider: false,
            showWidgetPane: true,
            showWidgetModal: false,
            selectedWidget: selectedWidget,
            selectedWidgetName: widgetName.toString()
        });
    }

    function handleNavigation(event) {
        event.preventDefault();

        switch (event.target.id) {
            case 'dashboardBtn':
                $(".listHeader").removeClass("active open");
                navigate("/dashboard", { replace: true });
                break;
            case 'accountsBtn':
                $(".listHeader").removeClass("active open");
                navigate("/accounts", { replace: true, state: { pageNumber: 1, selectedAccountId: null } });
                /*navigate("/accounts", { replace: true });*/
                break;
            case 'importAttachmentsBtn':
                toast.success("Import SalesForce.com Attachments Clicked");
                break;
            case 'companysearch':
                $(".listHeader").removeClass("active open");
                navigate("/customersearch", { replace: true, state: { fromTopNav: true } });
                break;
            case 'queryBuilder':
                $(".listHeader").removeClass("active open");
                navigate("/querybuilder", { replace: true, state: { querytopreload: null } });
                break;
            case 'reportingBtn':
                setState({
                    ...state,
                    showReportsSlider: true,
                    showJSTReportsSlider: false,
                    showSelectWidgetSlider: false
                });
                break;
            case 'managementPanelBtn':
                $(".listHeader").removeClass("active open");
                navigate("/management-dashboard", { replace: true });
                break;
            case 'addJSSProspect':
                $(".listHeader").removeClass("active open");
                navigate("/addprospect", { replace: true, state: { fromTopNav: true } });
                break;
            default:
                break;
        }

        //$('.subTabs').parent('li').removeClass('active');
        //$('#' + event.target.id).parent('li').addClass('active');
    }

    function handleLogout(event) {
        confirm({
            title: "You are about to logout",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {

                event.preventDefault();

                navigate("/", { replace: true });

                return 0;
            } else {
                return 0;
            }
        });
    }

    function handleDivisonChange(event) {
        const target = event.target;

        userInfo.jobShopDivision = target.value;
        window.sessionStorage.setItem("userInfo", JSON.stringify(userInfo));

        setValue('currentUser', userInfo);
        setState({ ...state });

        LoadDashboardWidgets();

        navigate("/dashboard");
    }

    function handleWidgetsClick() {
        setState({
            ...state,
            showSelectWidgetSlider: true,
            showReportsSlider: false,
            showJSTReportsSlider: false
        });
    }

    function hideWidgetPane() {
        setState({
            ...state,
            showWidgetPane: false,
            showWidgetModal: false
        });
    }

    function hideWidgetModal() {
        setState({
            ...state,
            showWidgetPane: false,
            showWidgetModal: false
        });
    }

    const { Option } = Select;

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-header">
            {props.showLeftNav == false ?
                <div className="navbar-brand hover">
                    <img alt="Design-2-Part Company" id="dashboardBtn" onClick={handleNavigation} src={logo} style={{ height: '75px' }} />
                </div>
                :
                <div></div>
            }

            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-toggle" aria-controls="navbar-toggle" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbar-toggle">
                <ul className="navbar-nav mt-2 mt-lg-0 me-auto">
                    <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                        <div>
                            {userInfo.jobShopDivision === 'JSS' &&
                                <span className="header-icon mimicA top-nav-item" id="showsBtn" onClick={handleNavigation}>
                                    <FaPersonBooth className="fa-1x text-icon" id="showsBtn" onClick={handleNavigation} /> <span className="top-nav-item-desc" id="showsBtn" onClick={handleNavigation}>Shows</span>
                                </span>
                            }
                            {userInfo.jobShopDivision === 'JST' &&
                                <span className="header-icon mimicA top-nav-item" id="magazinesBtn" onClick={handleNavigation}>
                                    <TbBook className="fa-1x text-icon" id="magazinesBtn" onClick={handleNavigation} /> <span className="top-nav-item-desc" id="magazinesBtn" onClick={handleNavigation}>Magazine</span>
                                </span>
                            }
                        </div>
                    </li>
                    <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                        {/*<div>*/}
                        {/*    <span className="header-icon mimicA top-nav-item" id="customersBtn" onClick={handleNavigation}>*/}
                        {/*        <TbUserCheck className="fa-1x text-icon" id="customersBtn" onClick={handleNavigation} /> <span className="top-nav-item-desc" id="customersBtn" onClick={handleNavigation}>Customers</span>*/}
                        {/*    </span>*/}
                        {/*</div>*/}
                        <div className="dropdown">
                            <span className="dropbtn header-icon mimicA top-nav-item">
                                <TbUserCheck className="fa-1x text-icon" id="customersBtn" /> <span className="top-nav-item-desc" id="customersBtn">Customers</span>
                            </span>
                            {userInfo.jobShopDivision === 'JSS' &&
                                <div className="dropdown-content">
                                    <li id="companysearch" onClick={handleNavigation}>
                                        <span id="companysearch" className="subTabs waves-effect waves-themed active" onClick={handleNavigation}>
                                            Customer Search
                                        </span>
                                    </li>
                                    <li id="addExhibitor" onClick={handleNavigation}>
                                        <span id="addExhibitor" className="subTabs waves-effect waves-themed active" onClick={handleNavigation}>
                                            Add A New Exhibitor
                                        </span>
                                    </li>
                                    <li id="addJSSProspect" onClick={handleNavigation}>
                                        <span id="addJSSProspect" className="subTabs waves-effect waves-themed active" onClick={handleNavigation}>
                                            Add A New Prospect
                                        </span>
                                    </li>
                                    <li id="queryBuilder" onClick={handleNavigation}>
                                        <span id="queryBuilder" className="subTabs waves-effect waves-themed active" onClick={handleNavigation}>
                                            Query Builder
                                        </span>
                                    </li>
                                </div>
                            }
                            {userInfo.jobShopDivision === 'JST' &&
                                <div className="dropdown-content">
                                    <li id="companysearch" onClick={handleNavigation}>
                                        <span id="companysearch" className="subTabs waves-effect waves-themed active" onClick={handleNavigation}>
                                            Customer Search
                                        </span>
                                    </li>
                                    <li id="addAdvertiser" onClick={handleNavigation}>
                                        <span id="addAdvertiser" className="subTabs waves-effect waves-themed active" onClick={handleNavigation}>
                                            Add A New Advertiser
                                        </span>
                                    </li>
                                    <li id="addJSTProspect" onClick={handleNavigation}>
                                        <span id="addJSTProspect" className="subTabs waves-effect waves-themed active" onClick={handleNavigation}>
                                            Add A New Prospect
                                        </span>
                                    </li>
                                    <li id="queryBuilder" onClick={handleNavigation}>
                                        <span id="queryBuilder" className="subTabs waves-effect waves-themed active" onClick={handleNavigation}>
                                            Query Builder
                                        </span>
                                    </li>
                                </div>
                            }
                        </div>
                    </li>

                    {/*<li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">*/}
                    {/*    <div>*/}
                    {/*        <span className="header-icon mimicA top-nav-item" id="prospectsBtn" onClick={handleNavigation}>*/}
                    {/*            <TbUserPlus className="fa-1x text-icon" id="prospectsBtn" onClick={handleNavigation} /> <span className="top-nav-item-desc" id="prospectsBtn" onClick={handleNavigation}>Prospects</span>*/}
                    {/*        </span>*/}
                    {/*    </div>*/}
                    {/*</li>*/}

                    <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                        <div>
                            <span className="header-icon mimicA top-nav-item" id="arBtn" onClick={handleNavigation}>
                                <TbReportMoney className="fa-1x text-icon" id="arBtn" onClick={handleNavigation} /> <span className="top-nav-item-desc" id="arBtn" onClick={handleNavigation}>A/R</span>
                            </span>
                        </div>
                    </li>

                    {userInfo.jobShopDivision === 'JST' &&
                        <li className="nav-item">
                            <div className="dropdown">
                                <span className="dropbtn header-icon mimicA top-nav-item" id="marketingBtn" onClick={handleNavigation}>
                                    <BsMegaphone className="fa-1x text-icon" id="marketingBtn" /> <span className="top-nav-item-desc" id="marketingBtn" onClick={handleNavigation}>Marketing</span>
                                </span>
                            </div>
                        </li>
                    }

                    <li className="nav-item">
                        <div className="dropdown">
                            <span className="dropbtn header-icon mimicA top-nav-item" id="reportingBtn" onClick={handleNavigation}>
                                <TbReport className="fa-1x text-icon" id="reportingBtn" /> <span className="top-nav-item-desc" id="reportingBtn" onClick={handleNavigation}>Reporting</span>
                            </span>
                        </div>
                    </li>

                    {userInfo.jobShopDivision === 'JST' &&
                        <li className="nav-item">
                            <div className="dropdown">
                                <span className="dropbtn header-icon mimicA top-nav-item" id="buyersguideBtn" onClick={handleNavigation}>
                                    <GrShop className="fa-1x text-icon" id="buyersguideBtn" /> <span className="top-nav-item-desc" id="buyersguideBtn" onClick={handleNavigation}>Buyers Guide</span>
                                </span>
                            </div>
                        </li>
                    }

                    <div className="search-div full-size ms-auto">
                        <CustomSearch />
                    </div>

                    <li className="nav-item" style={state.widgetIconVisible === true ? {} : { display: 'none' }}>
                        <div className="dropdown">
                            <span className="header-icon mimicA top-nav-item" id="widgets">
                                <MdOutlineWidgets className="fa-2x text-icon" id="widgetsBtn" onClick={handleWidgetsClick} />
                            </span>
                        </div>
                    </li>
                    
                    <div className="seperator"></div>

                    {(userInfo.userId === 'dlandino' || userInfo.userId === 'blebo') &&
                        <div onClick={handleNavigation}>
                            <span className="header-icon mimicA top-nav-item" id="managementPanelBtn">
                                <BsGear className="fa-2x text-icon" id="managementPanelBtn" />
                            </span>
                        </div>
                    }

                    <div className="current-user">Welcome: {userInfo.userName}</div>
                    {userInfo.userType === 'admin' &&
                        <div className="header-search pull-left pt-4">
                            <span><input type="radio" name="jobshopdivision" value="JSS" checked={userInfo.jobShopDivision === 'JSS'} onChange={handleDivisonChange} /></span>&nbsp;JSS
                            <span style={{ "paddingLeft": "10px" }}><input type="radio" name="jobshopdivision" value="JST" checked={userInfo.jobShopDivision === 'JST'} onChange={handleDivisonChange} /></span>&nbsp;JST
                        </div>
                    }
                    <div>
                        <span className="header-icon mimicA top-nav-item" onClick={handleLogout}>
                            <i className="fas fa-sign-out-alt logoutIcon" onClick={handleLogout} />
                        </span>
                    </div>
                </ul>
            </div>

            {state.showReportsSlider && <ReportsSlider showModal={state.showReportsSlider} hideModal={() => setState({ ...state, showReportsSlider: false })} />}

            {state.showSelectWidgetSlider && <SelectWidgetSlider showDrawer={state.showSelectWidgetSlider} myWidgets={state.myWidgets} loadSelectedWidget={loadSelectedWidget} />}

            <Drawer
                closeIcon={<i className="fas fa-chevron-left float-left"></i>}
                title={state.selectedWidgetName}
                placement={'right'}
                onClose={hideWidgetPane}
                visible={state.showWidgetPane}
                key='editEmployee'
                width={'75%'}

                footer={
                    <div className="d-flex justify-content-between">
                        <div>
                            <button className="btn btn-outline-primary margin-left-15 float-right" onClick={hideWidgetPane}>Close</button>
                        </div>
                    </div>
                }
            >
                <div className="row">
                    <div className="col-12">
                        {state.selectedWidget}
                    </div>
                </div>
            </Drawer>

            <Modal
                visible={state.showWidgetModal}
                title={state.modalTitle}
                onCancel={hideWidgetModal}
                footer={null}
            >
                {state.selectedWidget}

            </Modal>
        </nav>
    );
}