import React, { useEffect, useState } from 'react';
import MyActiveTasks from '../Tasks/MyActiveTasks';
import SendEmailTest from '../Tasks/SendEmailTest';

export default function Dashboard(props) {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const [state, setState] = useState({
        
    });

    useEffect(() => {
        
    }, [props]);

    return (
        //<>
        //    <MyActiveTasks />
        //    {(userInfo.userId === 'dlandino' || userInfo.userId === 'blebo') &&
        //        <SendEmailTest />
        //    }
            
        //</>
        <div className="row">
            <div className="col-lg-12">
                <MyActiveTasks />

            </div>
        </div>
    );
}