import React, { useEffect, useState } from 'react';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import { useNavigate, useLocation } from "react-router-dom";
import { Drawer, List } from 'antd';

export default function SelectWidgetSlider(props) {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const navigate = useNavigate();

    const [state, setState] = useState({
        showDrawer: false,
        myWidgets: [],
        loading: true,
        myLibrariesWidgets: [],
        myShowAnalyticsWidgets: [],
        myLeadGenerationWidgets: []
    });

    useEffect(() => {
        var myLibrariesWidgets = [];
        var myShowAnalyticsWidgets = [];
        var myLeadGenerationWidgets = [];
        var myDocumentLibraryWidgets = [];

        for (var j = 0; j < props.myWidgets.length; j++) {
            switch (props.myWidgets[j].widgetType) {
                case 'Libraries':
                    myLibrariesWidgets.push(props.myWidgets[j]);
                    break;
                case 'Show Analytics':
                    myShowAnalyticsWidgets.push(props.myWidgets[j]);
                    break;
                case 'Lead Generation':
                    myLeadGenerationWidgets.push(props.myWidgets[j]);
                    break;
                default:
                // code block
            }
        }

        setState({
            ...state,
            showDrawer: props.showDrawer,
            myWidgets: props.myWidgets,
            myLibrariesWidgets,
            myShowAnalyticsWidgets,
            myLeadGenerationWidgets,
            loading: false
        });
    }, [props]);

    function hideDrawer() {
        setState({
            ...state,
            showDrawer: false
        });
    }

    function LoadSelectedWidget(widgetId, widgetName) {
        console.log(widgetName);

        if (widgetName === 'Call Sheet Generator') {
            navigate("/call-sheet-generator", { replace: true });
        }
        else {
            //for graceful, animated close
            setTimeout(() => props.loadSelectedWidget(widgetId, widgetName), 500);
        }
        
    }

    return (
        <Drawer
            title='My D2P Workbench'
            placement={'right'}
            onClose={hideDrawer}
            visible={state.showDrawer}
            key='valAttachment'
            width={'25%'}
            closeIcon={<i className="fas fa-chevron-left"></i>}
        >
            {state.loading ? <span>Loading...</span>
                :
                <>
                    {state.myLibrariesWidgets.length > 0 &&
                        <div style={{ borderBottom: '1px solid lightgrey' }}>
                            <h6>My Libraries</h6>
                            <List
                                itemLayout="vertical"
                                size="large"
                                dataSource={state.myLibrariesWidgets}
                                style={{ cursor: 'pointer' }}
                                renderItem={item => (
                                    <List.Item
                                        key={item.id}
                                        className='ant-list-item-flex'
                                    >
                                        <div onClick={() => LoadSelectedWidget(item.id, item.title)}>{item.title}</div>
                                    </List.Item>
                                )}
                            />
                        </div>
                    }
                    <div style={{ paddingTop: 15, borderBottom: '1px solid lightgrey' }}>
                        <h6>Show Analytics</h6>
                        <List
                            itemLayout="vertical"
                            size="large"
                            dataSource={state.myShowAnalyticsWidgets}
                            style={{ cursor: 'pointer' }}
                            renderItem={item => (
                                <List.Item
                                    key={item.id}
                                    className='ant-list-item-flex'
                                >
                                    <div onClick={() => LoadSelectedWidget(item.id, item.title)}>{item.title}</div>
                                </List.Item>
                            )}
                        />
                    </div>
                    <div style={{ paddingTop: 15, borderBottom: '1px solid lightgrey' }}>
                        <h6>Lead Generation</h6>
                        <List
                            itemLayout="vertical"
                            size="large"
                            dataSource={state.myLeadGenerationWidgets}
                            style={{ cursor: 'pointer' }}
                            renderItem={item => (
                                <List.Item
                                    key={item.id}
                                    className='ant-list-item-flex'
                                >
                                    <div onClick={() => LoadSelectedWidget(item.id, item.title)}>{item.title}</div>
                                </List.Item>
                            )}
                        />
                    </div>
                </>
            }
        </Drawer>
    );
}