import React, { useState, useEffect, Fragment } from 'react';
import { Button as AntButton, Badge, Divider } from 'antd';
import { UpOutlined, RightOutlined, MailOutlined, DeleteOutlined } from '@ant-design/icons';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { toast, confirm } from '@rickylandino/react-messages';
import { motion, Variants } from "framer-motion";
import { useRecoilState } from 'recoil';
import { deletePersistentEmailAndAttachments, getPersistentEmailsByUser, persistentEmails } from '../services/PersistEmailService';
import SendEmailSlider from './Email/SendEmailSlider';
import './persist-emails.css';

const DEFAULT_SLIDER_INFORMATION = {
    show: false,
    emailInformation: null
}
export default function PersistEmails() {

    const [localPersistentEmails, setPersistentEmails] = useRecoilState(persistentEmails);
    const [emailSlider, setEmailSlider] = useState(DEFAULT_SLIDER_INFORMATION);
    const [collapsed, setCollapsed] = useState(true);

    const itemVariants: Variants = {
        open: {
            opacity: 1,
            y: 0,
            transition: { type: "spring", stiffness: 300, damping: 24 }
        },
        closed: { opacity: 0, y: 20, transition: { duration: 0.2 } }
    };

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    useEffect(() => {
        getPersistentEmailsByUser(userInfo.userId).then(data => {
            setPersistentEmails(data);
        });
    }, [userInfo.userId]);

    function openEmailSlider(idx) {
        const emailInformation = { ...localPersistentEmails[idx] };
        setEmailSlider({
            show: true,
            emailInformation
        });
    }

    function removePersistentEmail(idx) {
        confirm({
            title: "You are about to permanently delete this staged email",
            content: "Are you sure you would like continue?",
            buttons: ["Yes", "No"],
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                let persistentEmailCopy = [...localPersistentEmails];

                let emailToRemove = persistentEmailCopy[idx];
                deletePersistentEmailAndAttachments(emailToRemove);

                persistentEmailCopy.splice(idx, 1);
                setPersistentEmails(persistentEmailCopy);

                return 0;
            } else {
                return 0;
            }
        });
    }

    return (
        <>
            {localPersistentEmails.length > 0 ?
                <div style={{
                    position: 'fixed',
                    top: '100px',
                    right: '0',
                    zIndex: '10',
                }}>
                    <div style={{ display: 'flex', height: '100vh', minHeight: '400px', direction: 'rtl' }}>
                        <Sidebar collapsed={collapsed} collapsedWidth="0px" rootStyles={{
                            background:
                                'linear-gradient(180deg, rgba(166,240,255,1) 0%, rgba(220,250,255,1) 49%, rgba(230,252,255,1) 100%)',
                        }}>
                            <Menu>
                                {localPersistentEmails.map((item, idx) => (
                                    <Fragment key={idx}>
                                        <MenuItem style={{ direction: 'ltr', padding: '10px' }}>
                                        <div className="d-flex">
                                            <div className="col-9" onClick={() => openEmailSlider(idx)}>
                                                {item.selectedCustomer?.name} <br /><small>{item.sendTo}</small>
                                            </div>&nbsp;&nbsp;&nbsp;
                                            <div><i id="deleteNote" className="far fa-trash-alt fa-1x text-center hover text-danger" onClick={() => removePersistentEmail(idx)}></i></div>
                                        </div>
                                        <hr className="m-0" />
                                    </MenuItem>
                                    </Fragment>
                                ))}
                            </Menu>
                        </Sidebar>
                        <AntButton className="" style={{ height: '64px' }} onClick={() => setCollapsed(!collapsed)}>
                            <Badge size="small" count={localPersistentEmails.length} color="#275282">
                                <MailOutlined className="" />
                            </Badge>
                            <br />
                            <motion.span
                                animate={collapsed ? "open" : "closed"}
                                variants={{
                                    open: { rotate: 180 },
                                    closed: { rotate: 0 }
                                }}
                                transition={{ duration: 0.2 }}
                                style={{ originY: 0.55 }}
                            >
                                <RightOutlined />
                            </motion.span>
                        </AntButton>
                    </div>
                </div>
                :
                <div></div>
            }

            {emailSlider.show &&
                <SendEmailSlider showPane={emailSlider.show} emailInformation={emailSlider.emailInformation} hidePane={() => setEmailSlider(DEFAULT_SLIDER_INFORMATION)} />
            }
        </>
    );
};