import React, { useEffect, useState, Fragment, useRef } from 'react';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import { Button } from 'react-bootstrap';
import Globals from '../../config/globals';
import { FaCheck } from 'react-icons/fa';
import { TbUserCheck } from 'react-icons/tb';
import TaskManagerSlider from './TaskManagerSlider';
import TaskDetailsSlider from './TaskDetailsSlider';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import Moment from 'moment';
import { useNavigate, useLocation } from "react-router-dom";
import { Empty, Spin, Alert, AutoComplete, Select, Table, Input } from 'antd';
import { useForm, Controller } from "react-hook-form";
import { LoadingOutlined } from '@ant-design/icons';

export default function MyActiveTasks(props) {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const [state, setState] = useState({
        dataLoaded: false,
        tasks: [],
        shows: [],
        showTaskManagerSlider: false,
        showTaskDetailsSlider: false,
        customersListData: []
    });

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);

    const [tasks, setTasks] = useState([]);

    const navigate = useNavigate();

    const { register, getValues, setValue, control, watch } = useForm();

    const timer = useRef(null);              // Timer identifier
    const waitTime = 1000; //in ms
    const [showDropdown, setShowDropdown] = useState(false);
    const { Option } = Select;

    const [customersListData, setCustomersListData] = useState([]);
    const [searchResultsLoaded, setSearchResultsLoaded] = useState([]);
    const antIcon = <LoadingOutlined spin />;
    const [loadingSearchFilters, setLoadingSearchFilters] = useState(true);

    const [autoCompleteValue, setAutoCompleteValue] = useState('');

    useEffect(() => {
        ResetSearchCriteria();

        setLoadingSearchFilters(true);

        LoadSearchFilters();

    }, [props]);

    useEffect(() => {
        if (loadingSearchFilters === false) {
            GetUserActiveTasks(); // This is be executed when `loadingDropdowns` state changes
        }
    }, [loadingSearchFilters])

    function GetUserActiveTasks() {
        let myCompanyId = 0;

        if (getValues().formFields.companyid !== '') {
            myCompanyId = parseInt(getValues().formFields.companyid);
        }

        let postdata = {
            userid: userInfo.userId,
            jobshopdivision: userInfo.jobShopDivision,
            company_id: myCompanyId,
            showslist: String(getValues().formFields.shows)
        };

        Axios.post(`/api/GetUserActiveTasks`, postdata
        ).then(response => {
            const tasks = response.data;

            setTasks(tasks);

            setState({
                ...state,
                tasks,
                dataLoaded: true,
                showTaskDetailsSlider: false,
                showTaskManagerSlider: false
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function LoadSearchFilters() {
        setCustomersListData([]);

        let postdata = {};
        Axios.post(`/api/GetTaskUsersAndShows`, postdata
        ).then(response => {
            let shows = response.data.shows;

            setState({
                ...state,
                shows
            });
            setSearchResultsLoaded(true);
            setLoadingSearchFilters(false);
        }).catch(error => {
            console.log(error);
        });
    }

    // load options using API call
    function loadOptions(inputValue) {
        if (inputValue.length > 0) {
            setShowDropdown(true);
            setSearchResultsLoaded(false);

            let postdata = {};
            postdata.searchvalue = inputValue;

            Axios.post(`/api/GetCustomersBySearchValue`, postdata
            ).then(response => {

                let cListData = response.data;

                cListData = cListData.map(item => ({
                    ...item,
                    value: item.name
                }));

                setCustomersListData(cListData);

                setSearchResultsLoaded(true);

            }).catch(error => {
                console.log(error);
            });
        } else {
            setCustomersListData([]);
        }
    };

    //function jumptoFormatter(cell, row) {
    //    return (
    //        //<i id="jumpTo" className="fa-solid fa-people fa-1x ml-3 text-center hover"></i>
    //        <div id="jumpTo"><TbUserCheck id="jumpTo" className="fa-1x text-icon" /></div>
    //    );
    //}

    //function prospectFormatter(cell, row) {
    //    if (row.Prospect) {
    //        return (
    //            <div><FaCheck className="fa-1x text-icon" /></div>
    //        );
    //    }
    //    else {
    //        return (
    //            <div></div>
    //        );
    //    }
    //}

    //function editFormatter(cell, row) {
    //    return (
    //        <i id="editTask" className="fas fa-edit fa-1x ml-3 text-center hover"></i>
    //    );
    //}

    function dateFormatter(cell, row) {
        let date = cell;
        if (typeof cell !== 'object') {
            date = new Date(cell);
        }

        var month = date.getMonth() + 1;
        var day = date.getDate();
        var year = date.getFullYear();
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';

        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;

        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        hours = hours < 10 ? '0' + hours : hours;

        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;

        return month + "/" + day + "/" + year + "  " + strTime;
    }

    const columns = [
        {
            dataIndex: 'Task_ID',
            title: 'Task_ID',
            key: 'Task_ID',
            hidden: true
        }, {
            dataIndex: 'company_id',
            title: 'company_id',
            key: 'company_id',
            hidden: true
        }, {
            dataIndex: 'Type',
            title: 'Task Type',
            key: 'Type',
            width: 120,
            sorter: (a, b) => a.Type.localeCompare(b.Type)
        }, {
            dataIndex: 'DateTime',
            title: 'Date / Time',
            key: 'DateTime',
            width: 200,
            sorter: (a, b) => new Date(a.DateTime) - new Date(b.DateTime),
            render: (DateTime) => {
                return (
                    <div>
                        {DateTime === null ? '' : dateFormatter(DateTime)}
                    </div>
                );
            }
        }, {
            dataIndex: 'ContactCompany',
            title: 'Contact/Company',
            key: 'ContactCompany',
            sorter: (a, b) => a.ContactCompany.localeCompare(b.ContactCompany)
        }, {
            dataIndex: 'Priority',
            title: 'Priority',
            key: 'Priority',
            width: 120,
            sorter: (a, b) => a.Priority.localeCompare(b.Priority)
        }, {
            dataIndex: 'Prospect',
            title: 'Prospect',
            key: 'Prospect',
            width: 120,
            align: 'center',
            sorter: (a, b) => a.Prospect - b.Prospect,
            render: (Prospect) => {
                return (
                    <div>
                        {Prospect ? <div><FaCheck className="fa-1x text-icon" /></div> : <div></div>}
                    </div>
                );
            }
        }, {
            title: 'Jump to Customer',
            key: 'action',
            width: 120,
            render: (record) => (
                <div id="jumpTo" className="hover"><TbUserCheck id="jumpTo" className="fa-1x text-icon" onClick={(e) => handleJumpTo(e, record)} /></div>
            ),
            align: 'center'
        }, {
            title: 'Task Details',
            key: 'action',
            width: 120,
            render: (record) => (
                <span className="hover" onClick={(e) => handleTaskDetails(e, record)}><i id="taskDetails" className="fas fa-edit fa-1x ml-3 text-center hover"></i></span>
            ),
            align: 'center'
        }
    ].filter(item => !item.hidden);

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    function showTaskManager() {
        setState({
            ...state,
            showTaskManagerSlider: true,
            showTaskDetailsSlider: false
        })
    }

    function handleAddNewTask() {
        setState({
            ...state,
            Task_ID: 0,
            showTaskDetailsSlider: true,
            showTaskManagerSlider: false
        })
    }

    function handleTaskDetails(e, record) {
        setState({
            ...state,
            Task_ID: record.Task_ID,
            //selectedIdx: rowIndex,
            selectedTaskId: record.Task_ID,
            showTaskDetailsSlider: true,
            showTaskManagerSlider: false
        })
    }

    function handleJumpTo(e, record) {
        navigate("/customerview", { state: { selectedCustomerId: record.company_id, callingComponent: 'MyActiveTasks' } });
    }

    async function updateTableDisplay(tasks, taskAction, idxToDirectTo = null) {
        await setState({ ...state, dataLoaded: false });

        console.log(taskAction);

        if (taskAction !== 'add') {
            let refreshedtasks = [...tasks];

            setTasks(refreshedtasks);

            setState({
                ...state,
                tasks,
                selectedTask: tasks[idxToDirectTo],
                dataLoaded: true,
                showTaskDetailsSlider: false,
                showTaskManagerSlider: false
            });
        }
        else {
            //Reload tasks from DB
            GetUserActiveTasks();
        }
    }

    function handleShowsChange(value) {
        setValue('formFields.shows', value);
        setState({ ...state });
    }

    function setSearchTimer(inputValue) {

        setAutoCompleteValue(inputValue);

        clearTimeout(timer.current);

        // Wait for X ms and then process the request
        timer.current = setTimeout(() => {
            loadOptions(inputValue);
        }, waitTime);
    }

    function onCompanySelect(value, option) {
        setValue('formFields.company', value)
        setValue('formFields.companyid', option.id)

        setAutoCompleteValue(value);

        console.log(value);
        console.log(option.id);

        //if (typeof (option) != "undefined") {
        //    getCompanyContacts(option.id, userInfo.jobShopDivision);
        //}
        //else {
        //    setValue('formFields.company', null);
        //    setValue('formFields.contact', 'Select Contact');
        //    setContactsListData([]);
        //}

        setShowDropdown(false);
    }

    function handleSearchClick() {
        GetUserActiveTasks();
    }

    function ResetSearchCriteria() {
        setValue('formFields.company', '')
        setValue('formFields.companyid', '')
        setValue('formFields.shows', []);
        
        setAutoCompleteValue('');
        setCustomersListData([]);

        GetUserActiveTasks();
    }

    var tempVar = '';
    if (customersListData?.length > 0) {
        tempVar = customersListData[0].name;
    }

    return (
        <div className="row">
            <div className="col-lg-12">
                <Spin spinning={!state.dataLoaded}>
                    <div style={!state.dataLoaded ? {} : { display: 'none' }}>
                        <Alert
                            message="Loading Tasks"
                            description={"Please stand by while we retrieve active tasks for " + userInfo.userName}
                            type="info"
                        />
                    </div>
                    {state.dataLoaded &&
                        <div className="row">
                            <h5 className="frame-heading mb-4">
                                {/*My Active {userInfo.jobShopDivision} Tasks*/}
                                <button className="btn btn-submit float-end me-3" onClick={showTaskManager}>Task Manager</button>
                                <button className="btn btn-submit float-end me-3" onClick={handleAddNewTask}>Add New Task</button>
                            </h5>

                            <div className="form-group col-4">
                                <label className="form-label">Select Company To Search For Any Associated Tasks</label><br />

                                <Fragment>
                                    <AutoComplete dropdownClassName="certain-category-search-dropdown"
                                        onSelect={onCompanySelect}
                                        dropdownMatchSelectWidth={500}
                                        style={{
                                            width: '100%',
                                    }}
                                        value={autoCompleteValue}
                                        onSearch={setSearchTimer}
                                        placeholder="Search Customers"
                                        options={customersListData}
                                        notFoundContent={searchResultsLoaded ? "No Results Found" : <div><Spin indicator={antIcon} /> Searching...</div>}
                                        open={showDropdown}
                                        onFocus={() => setShowDropdown(true)}
                                        onBlur={() => setShowDropdown(false)}
                                    />
                                </Fragment>
                            </div>
                            <div className="form-group col-8"></div>

                            {userInfo.jobShopDivision === 'JSS' &&
                                <Fragment>
                                    <div className="form-group col-6">
                                        <label className="form-label">Select Show Specific Tasks</label>
                                        <Select id="ddSShows"
                                            value={getValues().formFields?.shows}
                                            className="form-control-custom"
                                            mode="multiple"
                                            placeholder="Select one or more Shows"
                                            onChange={handleShowsChange}
                                            className="form-control-custom w-100"
                                            bordered={false}
                                        >
                                            {state.shows.map((show) => <Option key={show.showcode} value={show.showcode}>{show.name}</Option>)}
                                        </Select>
                                    </div>
                                    <div className="form-group col-6"></div>
                                </Fragment>
                            }
                            
                            <div className="form-group col-lg-12 col-12">
                                <Button className="btn btn-submit" onClick={handleSearchClick}>Search</Button><button className="btn btn-outline-primary ms-3" onClick={ResetSearchCriteria}>Reset Selections</button>
                            </div>

                            <p></p>
                            <hr />

                            <div>
                                <Spin spinning={!state.dataLoaded}>
                                    <div style={!state.dataLoaded ? {} : { display: 'none' }}>
                                        <Alert
                                            message="Loading Tasks"
                                            description="Please stand by while we retrieve tasks for this customer"
                                            type="info"
                                        />
                                    </div>
                                    {state.dataLoaded &&
                                        <Fragment>
                                            {tasks.length === 0 ?
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                                    <span>
                                                        No Active {userInfo.jobShopDivision} Tasks Found
                                                    </span>
                                                } />
                                                :
                                                <Table className="custom-ant-selection"
                                                    rowKey={item => item.Task_ID}
                                                    rowSelection={rowSelection}
                                                    hideSelectionColumn={true}
                                                    bordered
                                                    dataSource={tasks}
                                                    columns={columns}
                                                    rowClassName={(record, index) => {
                                                        if (Moment(record.DateTime) < Moment(new Date())) {
                                                            return "text-danger";
                                                        }
                                                        else {
                                                            return "";
                                                        }
                                                    }}
                                                    pagination={{
                                                        defaultPageSize: 10,
                                                        showSizeChanger: true,
                                                        pageSizeOptions: ['10', '25', '50', '100'],
                                                        showTotal: (total, range) => (
                                                            <span className="color-dark-blue" style={{ left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" }}>
                                                                Showing {range[0]}-{range[1]} of {total}
                                                            </span>
                                                        )
                                                    }}
                                                    onRow={(record) => {
                                                        return {
                                                            onClick: () => {
                                                                let selRows = [record.Task_ID];
                                                                setSelectedRowKeys([...selRows]);
                                                            },
                                                            onDoubleClick: (e) => {
                                                                handleTaskDetails(e, record);
                                                            }
                                                        }
                                                    }}
                                                />
                                            }
                                        </Fragment>
                                    }
                                </Spin>
                            </div>
                        </div>
                    }
                </Spin>
                {state.showTaskManagerSlider &&
                    <TaskManagerSlider showPane={state.showTaskManagerSlider} hidePane={() => setState({ ...state, showTaskManagerSlider: false })} updateTableDisplay={updateTableDisplay} />
                }
                {state.showTaskDetailsSlider &&
                    <TaskDetailsSlider showPane={state.showTaskDetailsSlider} Task_ID={state.Task_ID} updateTableDisplay={updateTableDisplay}
                        selectedTaskId={state.selectedTaskId} tasks={state.tasks} selectedIdx={state.selectedIdx} hidePane={() => setState({ ...state, showTaskDetailsSlider: false })}
                        parent='MyActiveTasks' selectedCustomerId={null} selectedCustomerName={null} />
                }
            </div>
        </div>
    );
}