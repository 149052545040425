import React, { useState, useEffect, useRef } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Axios from '../../config/axios';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import { Fragment } from 'react';
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import { useForm, Controller } from "react-hook-form";
import Card from 'react-bootstrap/Card';
import { Tooltip, Popover } from 'antd';

export default function ShowDetailSliderVO(props) {
    const [state, setState] = useState({
        showPane: false,
        dataLoaded: false,
        mode: 'Add',
        usersListData: [],
        selectedCustomerId: props.selectedCustomerId,
        selectedShowCode: props.selectedShowCode,
        selectedShowName: props.selectedShowName,
        showdetail: {},
        boothpricing: {},
        boothStr: '',
        boothCostsStr: '',
        boothMoveStr: '',
        serviceCodesStr: '',
        serviceCodesCnt: 0
    });

    const { register, getValues, setValue, control, watch } = useForm();

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    useEffect(() => {
        LoadShowDetails();

    }, [props]);

    function LoadShowDetails() {
        let postdata = {};
        postdata.exhibitorid = props.selectedCustomerId;
        postdata.showcode = props.selectedShowCode;

        Axios.post(`/api/GetShowDetails`, postdata
        ).then(async response => {
            //console.log(response.data);

            let showdetail = response.data.showdetail;
            let boothpricing = response.data.boothpricing;
            let servicecodes = response.data.servicecodes;

            //build booths string
            let myBooths = '';
            if (showdetail.booth1 !== null && showdetail.booth1 !== '') { myBooths += showdetail.booth1 + ', '; }
            if (showdetail.booth2 !== null && showdetail.booth2 !== '') { myBooths += showdetail.booth2 + ', '; }
            if (showdetail.booth3 !== null && showdetail.booth3 !== '') { myBooths += showdetail.booth3 + ', '; }
            if (myBooths !== '') {
                myBooths = myBooths.slice(0, -2);
            }
            else {
                myBooths = 'Unassigned';
            }

            //build booth costs string
            let myBoothCost = '';
            if (boothpricing == undefined) {
                myBoothCost = "Booth Costs: ?????"
            }

            if (showdetail.booth1 === null && showdetail.booth2 === null && showdetail.booth3 === null) {
                myBoothCost = "$0.00";
            }
            else if (showdetail.booth1 !== null && showdetail.booth2 !== null && showdetail.booth3 !== null) {
                myBoothCost = currencyFormatter.format(boothpricing.priceTriple);
            }
            else if (showdetail.booth1 !== null && showdetail.booth2 !== null) {
                myBoothCost = currencyFormatter.format(boothpricing.priceDouble);
            }
            else if (showdetail.booth1 !== null) {
                myBoothCost = currencyFormatter.format(boothpricing.priceSingle);
            }

            //build pending booth moves string
            let myBoothMoveStr = '';
            if (showdetail.moveto_booth1 === null || showdetail.moveto_booth1 === '') {
                myBoothMoveStr = "No pending booth moves";
            }
            else {
                myBoothMoveStr = "booth " + showdetail.moveto_booth1;
                if (showdetail.moveto_booth2 !== null) { myBoothMoveStr += ", " + showdetail.moveto_booth2; }
                if (showdetail.moveto_booth3 !== null) { myBoothMoveStr += ", " + showdetail.moveto_booth3; }
                myBoothMoveStr = "Move pending to " + myBoothMoveStr;
            }

            //build pre-reg list categories string
            var myServiceCodesStr = '';

            let myServiceCodesCnt = 0;
            if (showdetail.prereglistcategories !== null && showdetail.prereglistcategories !== '') {
                servicecodes.map((sc, i) => {
                    if (showdetail.prereglistcategories.includes("|" + sc.code + "|")) {
                        myServiceCodesStr += sc.description + '<br />';
                        myServiceCodesCnt += 1;
                    }
                });
            }
            else {
                myServiceCodesStr = 'No Categories Selected';
            }

            var popContent = <div dangerouslySetInnerHTML={{ __html: myServiceCodesStr }} />

            setState({
                ...state,
                selectedCustomerId: props.selectedCustomerId,
                selectedShowCode: props.selectedShowCode,
                selectedShowName: props.selectedShowName,
                showdetail: showdetail,
                boothpricing: boothpricing,
                boothStr: myBooths,
                boothCostsStr: myBoothCost,
                boothMoveStr: myBoothMoveStr,
                serviceCodesStr: popContent,
                serviceCodesCnt: myServiceCodesCnt,
                showPane: props.showPane,
                dataLoaded: true,
            });

        }).catch(error => {
            console.log(error);
        });

    }

    var currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    function hidePane() {
        setState({
            ...state,
            showPane: false
        });

        setTimeout(() => {
            props.hidePane();
        }, 1000)
    }

    const content = (
        state.serviceCodesStr
    );

    return (
        <SlidingPane
            className='some-custom-class w-65'
            overlayClassName='showCard'
            isOpen={state.showPane}
            title={state.selectedShowName}
            onRequestClose={hidePane}
            width={'90%'}
        >
            <div className="slide-pane-body scrollableDiv">
                <div>
                    {state.dataLoaded &&
                        <div className="row mb-3">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <Card className="d2pCard mb-3">
                                    <Card.Header><span>General Show Information</span></Card.Header>
                                    <Card.Body>
                                        <div className="row">
                                            <div className="form-group col-lg-1">
                                                <label className="form-label">Sales Status</label>
                                                <label className="form-data-label">{state.showdetail.salesstatus === null ? 'N/A' : state.showdetail.salesstatus}</label>
                                            </div>
                                            <div className="form-group col-lg-5">
                                                <label className="form-label">Online Contract</label>
                                                <label className="form-data-label">{state.showdetail.onlinecontractstatus === null ? 'Not Signed Yet' : state.showdetail.onlinecontractstatus}</label>
                                            </div>
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">Slip Sheet</label>
                                                <label className="form-data-label">{state.showdetail.slipsheert === 1 ? 'Yes' : 'No'}</label>
                                            </div>
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">Badges Received</label>
                                                <label className="form-data-label">{state.showdetail.badgesreceived === '' ? 'No' : state.showdetail.badgesreceived}</label>
                                            </div>
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">Show Video</label>
                                                <label className="form-data-label">{state.showdetail.showvideo === '' || state.showdetail.showvideo === null ? 'No' : 'Yes'}</label>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <Card className="d2pCard mb-3">
                                    <Card.Header><span>Booth Information</span></Card.Header>
                                    <Card.Body>
                                        <div className="row">
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">Booth(s)</label>
                                                <label className="form-data-label">{state.boothStr}</label>
                                            </div>
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">Corner Booth</label>
                                                <label className="form-data-label">{state.showdetail.corner === 1 ? 'Yes' : 'No'}</label>
                                            </div>
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">Booth Costs</label>
                                                <label className="form-data-label">{state.boothCostsStr}</label>
                                            </div>
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">Booth Moves</label>
                                                <label className="form-data-label">{state.boothMoveStr}</label>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <Card className="d2pCard mb-3">
                                    <Card.Header><span>Package Selections</span></Card.Header>
                                    <Card.Body>
                                        <form>

                                        <div className="row">
                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Package Type</label>
                                                    <label className="form-data-label">{state.showdetail.packagetype}</label>
                                                </div>
                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Web Link</label>
                                                    <label className="form-data-label">{state.showdetail.weblink === 'Yes' ? 'Yes (' + currencyFormatter.format(state.showdetail.weblinkamount) + ')' : 'No'}</label>
                                                </div>
                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Pre Reg Link</label>
                                                    <label className="form-data-label">{state.showdetail.weblink === 'Yes' ? 'Yes (' + currencyFormatter.format(state.showdetail.weblinkamount) + ')' : 'No'}</label>
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <label className="form-label">Pre Reg Categories</label>
                                                    <Popover content={state.serviceCodesStr} title="Pre Reg Categories" trigger="hover" placement="topLeft">
                                                        <label className="form-data-label"><u>Click To See {state.serviceCodesCnt} Categories Selected</u></label>
                                                    </Popover>
                                                </div>

                                            <div className="form-group col-lg-2">
                                                <label className="form-label">Lead Retrieval</label>
                                                <label className="form-data-label">{state.showdetail.leadretrievalstatus === 'Yes' ? 'Yes' : 'No'}</label>
                                            </div>
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">Lead Retrieval Amount</label>
                                                <label className="form-data-label">{state.showdetail.leadretrievalstatus === 'Yes' && state.showdetail.amount !== null ? currencyFormatter.format(state.showdetail.leadretrievalamount) : 'N/A'}</label>
                                            </div>
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">Lead Retrieval Note</label>
                                                <label className="form-data-label">{state.showdetail.leadretrievalnote}</label>
                                            </div>
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">Lead Retrieval Contact</label>
                                                <label className="form-data-label">{state.showdetail.leadretrievalcontact}</label>
                                            </div>
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">Lead Retrieval Email</label>
                                                <label className="form-data-label">{state.showdetail.leadretrievalemail}</label>
                                            </div>
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">Lead Retrieval Phone</label>
                                                <label className="form-data-label">{state.showdetail.leadretrievalphone}</label>
                                            </div>

                                            <div className="form-group col-lg-2">
                                                <label className="form-label">Caravan Freight</label>
                                                <label className="form-data-label">{state.showdetail.caravanfreight === 1 ? 'Yes' : 'No'}</label>
                                            </div>
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">Caravan Freight Amount</label>
                                                <label className="form-data-label">{state.showdetail.caravanfreight === 1 && state.showdetail.caravanfreightamount !== null ? currencyFormatter.format(state.showdetail.caravanfreightamount) : 'N/A'}</label>
                                            </div>
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">Caravan Freight Note</label>
                                                <label className="form-data-label">{state.showdetail.caravanfreightnote}</label>
                                            </div>
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">Sponsorship</label>
                                                <label className="form-data-label">{state.showdetail.sponsorship === 1 ? 'Yes' : 'No'}</label>
                                            </div>
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">Sponsorship Amount</label>
                                                <label className="form-data-label">{state.showdetail.sponsorship === 1 && state.showdetail.sponsorshipamount !== null ? currencyFormatter.format(state.showdetail.sponsorshipamount) : 'N/A'}</label>
                                            </div>
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">Sponsorship Note</label>
                                                <label className="form-data-label">{state.showdetail.sponsorshipnote}</label>
                                            </div>

                                            <div className="form-group col-lg-12">
                                                <label className="form-label">Invoice Note</label>
                                                <label className="form-data-label">{state.showdetail.invoicenote}</label>
                                            </div>
                                        </div>
                                    </form>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    }
                </div>
            </div>

            <div className="modal-footer">
                <div className="form-group col-12 padding-25-10">
                    <button className="btn btn-outline-primary margin-left-15 float-right" onClick={hidePane}>Close</button>
                </div>
            </div>

        </SlidingPane>
    );
}